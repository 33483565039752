import { Extract } from './extract';
import { Control } from './control';
import { GridConfig } from './gridConfig';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Field extends Control {
  constructor(
    public title?: string,
    public name?: string,
    public controlType?: string | number | any,
    public designCategory?: string | number,
    public iconClass?: string,
    public label?: string,
    public value?: string,
    public description?: string,
    public referenceName?: string,
    public size?: any,
    public options?: any,
    public readOnly?: boolean,
    public required?: boolean,
    public dbType?: string,
    public extract?: Extract,
    public maxValue?: any,
    public minValue?: any,
    public format?: any,
    public watermark?: string,
    public autoLoad?: boolean,
    public bindingSet?: any,
    public editableGrid?: any,
    public gridConfig?: GridConfig,
  ) {
    super();
  }
}
