// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Turkish [tr]
//! authors : Erhan Gundogan : https://github.com/erhangundogan,
//!           Burak YiÄit Kaya: https://github.com/BYK

let suffixes: { [key: string]: string } = {
  1: '\'inci',
  5: '\'inci',
  8: '\'inci',
  70: '\'inci',
  80: '\'inci',
  2: '\'nci',
  7: '\'nci',
  20: '\'nci',
  50: '\'nci',
  3: '\'Ã¼ncÃ¼',
  4: '\'Ã¼ncÃ¼',
  100: '\'Ã¼ncÃ¼',
  6: '\'ncÄ±',
  9: '\'uncu',
  10: '\'uncu',
  30: '\'uncu',
  60: '\'Ä±ncÄ±',
  90: '\'Ä±ncÄ±'
};

export const trLocale: LocaleData = {
  abbr: 'tr',
  months: 'Ocak_Åubat_Mart_Nisan_MayÄ±s_Haziran_Temmuz_AÄustos_EylÃ¼l_Ekim_KasÄ±m_AralÄ±k'.split('_'),
  monthsShort: 'Oca_Åub_Mar_Nis_May_Haz_Tem_AÄu_Eyl_Eki_Kas_Ara'.split('_'),
  weekdays: 'Pazar_Pazartesi_SalÄ±_ÃarÅamba_PerÅembe_Cuma_Cumartesi'.split('_'),
  weekdaysShort: 'Paz_Pts_Sal_Ãar_Per_Cum_Cts'.split('_'),
  weekdaysMin: 'Pz_Pt_Sa_Ãa_Pe_Cu_Ct'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd, D MMMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[bugÃ¼n saat] LT',
    nextDay: '[yarÄ±n saat] LT',
    nextWeek: '[gelecek] dddd [saat] LT',
    lastDay: '[dÃ¼n] LT',
    lastWeek: '[geÃ§en] dddd [saat] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: '%s sonra',
    past: '%s Ã¶nce',
    s: 'birkaÃ§ saniye',
    ss: '%d saniye',
    m: 'bir dakika',
    mm: '%d dakika',
    h: 'bir saat',
    hh: '%d saat',
    d: 'bir gÃ¼n',
    dd: '%d gÃ¼n',
    M: 'bir ay',
    MM: '%d ay',
    y: 'bir yÄ±l',
    yy: '%d yÄ±l'
  },
  dayOfMonthOrdinalParse: /\d{1,2}'(inci|nci|Ã¼ncÃ¼|ncÄ±|uncu|Ä±ncÄ±)/,
  ordinal(_num: number): string {
    const num = Number(_num);
    if (num === 0) {  // special case for zero
      return num + '\'Ä±ncÄ±';
    }
    let a = num % 10,
      b = num % 100 - a,
      c = num >= 100 ? 100 : null;
    return num + (suffixes[a] || suffixes[b] || suffixes[c]);
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7  // The week that contains Jan 1st is the first week of the year.
  }
};
