import { ObjectLiteral } from './../../../models/objectLiteral';
import { Component, Input, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FVAbstractControl } from '../../shared/abstract-control.component';
import { ApiService, ApiResponse, DGSNotificationService, Lang } from '@dotgov/core';
import { LanguageService } from '../../../services/language.service';
import { ProcessService } from '../../../services/process.service';
import { Folder, DockItem, FVDoneResponse, Task } from '../../../models';
import { Subject } from 'rxjs/index';
import { debounceTime, distinctUntilChanged, throttleTime, filter } from 'rxjs/operators';

declare function clearFrmGlobals();

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-menu-grid',
  templateUrl: './menu-grid.component.html',
  styleUrls: ['./../controls/controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsMenuGridComponent extends FVAbstractControl implements AfterViewInit, OnDestroy {
  isDblClick: boolean;
  reload: boolean;
  showModal: boolean;
  showAddModal: boolean;
  loading: boolean;
  taskData: any;
  lang: Lang;
  designerData: any;
  showDeleteModal: boolean;
  nodeTitle: string;

  private folderClickEvent = new Subject<any>();

  protected _task: any;
  addConfig: any;
  @Input() set task(task: any) {
    this._task = task;
  }

  get task(): any {
    return this._task;
  }

  protected _data: any;
  get data(): any {
    return this._data;
  }

  get node(): string {
    return this._node;
  }

  @Output() titleUpdated: EventEmitter<string> = new EventEmitter();
  onDone: EventEmitter<any> = new EventEmitter();

  private _title: string;
  private _node: string;
  private _lastModel: any;
  private _toDelete: any;

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private language: LanguageService,
    private notification: DGSNotificationService,
    private router: Router,
  ) {
    super(process);
    this.lang = this.language.language;
    this.subscriptions.push(this.language.languageChanged.subscribe((lang: Lang) => {
      this.lang = lang;
    }));
    this.subscriptions.push(this.onDone.pipe(throttleTime(150), filter((res) => !res.manual)).subscribe(() => {
      this.modalClosed();
      this.fetchData();
    }));
  }

  ngAfterViewInit() {
    this.fetchData();

    this.subscriptions.push(this.folderClickEvent.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.ref.detectChanges();
      this.fetchData();
    }));
  }

  onTaskDone(response: FVDoneResponse) {
    this.onDone.emit(response);
  }

  folderClicked(folder: Folder) {
    this.log('active folder updated: ', folder);
    this.node = folder.name;
    this.nodeTitle = folder.title;
    this.folderClickEvent.next(folder);
  }

  modalClosed() {
    this.showModal = false;
    this.showDeleteModal = false;
    this.taskData = null;
    this._title = '';
    clearFrmGlobals();
    this.ref.detectChanges();
  }

  onMenuItemClicked(itemData: { actionName: string, dockItem: DockItem, activeItem: any }) {
    const dialogData = itemData.dockItem.dialogData;
    const pseudoJson = this.readPseudoJson(dialogData && dialogData.componentConfig && dialogData.componentConfig.attributes || '{}');
    const hasIds = Object.keys(pseudoJson).find((key) => this._isId(pseudoJson[key]));
    if (!itemData.activeItem && hasIds) {
      this.notification.info('Please select row then proceed.');
      return;
    }
    this.modalClosed();
    this.loading = true;
    // switch(itemData.actionName) {
    //   case 
    // }
    if (itemData.actionName === 'process') {
      this.handleProcess(itemData);
    } else if (itemData.actionName === 'profile') {
      const childDialogData = itemData.dockItem.dialogData.componentConfig;
      this.process.set(`profile-${childDialogData.profileId}`, itemData.activeItem);
      this.handleProfile(itemData);
    } else if (itemData.actionName === 'delete') {
      this.handleDelete(itemData);
    } else if (itemData.actionName === 'edit') {
      this.handleEdit(itemData);
    }
    else {
      this.notification.warning('This method is restricted for now.');
      this.loading = false;
    }
    this.ref.detectChanges();
  }
  handleEdit(itemData: { actionName: string; dockItem: DockItem; activeItem: any; }) {
    this.addConfig = itemData.dockItem.dialogData;
    this.showAddModal = true;
    this.loading = false;
  }

  onDblClick(itemData: { keyfield: string, target: Task }) {
    this.taskData = itemData.target;
    this.showModal = true;
    this.ref.detectChanges();
  }

  updateTitle(title: string) {
    this._title = title;
  }

  handleDelete(itemData) {
    this.showDeleteModal = true;
    this._toDelete = itemData;
    this.loading = false;
  }

  onDeleteConfirm() {
    const itemData = this._toDelete;
    if (!itemData) {
      return;
    }
    return this.deleteSelected(itemData).then(() => {
      this._toDelete = undefined;
      this.showDeleteModal = false;
      this.ref.detectChanges();
    });
  }

  onDeleteCancel() {
    this._toDelete = undefined;
    this.showDeleteModal = false;
    this.ref.detectChanges();
  }


  async onAddDone(event: any) {
    switch(event.action) {
      case 'close': {
        this.showAddModal = false;
        break;
      }
      case 'save':
      case 'save-close': {
        this.showAddModal = false;
        const response = await this.api.post('data', [this.addConfig.id], {}, event.data)
        if (response.data == 200) {
         this.fetchData();
        } else if (response.error) {
          this.notification.error(response.error.message);
        }
      }
      default: 
        this.showAddModal = false;
    }
  }


  set node(name: string) {
    this._node = name;
    if (!this.parentTask) {
      const url = this.router.url.split('##')[0];
      // Find node and generate url path
      const path = this.findPath({ folders: this.folders }, name) || name;
      this.router.navigateByUrl(`${url}##${path.trim().replace(/ /, '#')}`);
    }
    this.loadDefaultNode();
  }

  get title(): string {
    if (this._title || !this.taskData || !this.taskData.componentConfig) {
      return this._title || '';
    }
    return this.taskData.componentConfig.title || '';
  }

  get render(): string {
    if (!this.data.componentClassName && !this.data.clientComponent) {
      return '';
    }
    return (this.data.clientComponent || this.data.componentClassName).toLowerCase();
  }

  get folders(): any[] {
    return this.task && this.task.folders && this.task.folders || [];
  }

  private findPath(folder, name, fullPath: string = ''): string {
    if (!folder || !folder.folders) {
      return;
    }
    for (let i = 0; i < folder.folders.length; i++) {
      if (folder.folders[i].name === name) {
        fullPath = `${fullPath}${folder.name ? ` ${folder.name}` : ''} ${name}`;
        return fullPath;
      }
      const found = this.findPath(folder.folders[i], name, fullPath);
      if (found) {
        fullPath = `${fullPath}${folder.name ? ` ${folder.name}` : ''}`;
        return found;
      }
    }
  }

  private loadDefaultNode() {
    const urlPredefined = this.router.url.split('##')[1];
    const _default = this.task && this.task.defaultNode || this.folders[0] && this.folders[0].name;
    if (!urlPredefined || this.parentTask) {
      return this._node = this._node || _default;
    }
    const paths = urlPredefined.split('#');
    let result: any = { folders: this.folders };
    paths.forEach((path) => {
      const index = (result && result.folders || []).findIndex((item) => item.name === path);
      return result = result.folders && result.folders[index] || {};
    });
    return this._node = result.name || _default;
  }

  private readPseudoJson(pseudoJson: string): ObjectLiteral {
    return JSON.parse(pseudoJson);
  }

  private _isId(_val: string): boolean {
    const val = _val.toLowerCase();
    return val === '[id]' || val === 'id' || val === '_id_';
  }

  private createRequestBody(params: string[], attrs: object, inputs: object, shouldResume: boolean): { body: object, routes: object } {
    const res = { body: {}, routes: {} };
    Object.keys(attrs).forEach((key) => {
      if (shouldResume) {
        const val = attrs[key];
        const sanitizedVal = this._isId(val) ? inputs['keyfield'] : inputs[val];
        res.routes[key] = sanitizedVal;
      }
      res.body[key] = this._isId(attrs[key]) ? inputs['keyfield'] : inputs[key] || attrs[key];
    });
    return res;
  }

  private fetchData() {
    const finalHandle = (response, recordData?: string) => {
      const done = (task) => {
        if (recordData && task && task.componentConfig) {
          const property = task.componentConfig.idFilterProperty;
          task.componentConfig.recordData = recordData;
          task.filters = [{ property, value: recordData[property] || recordData['Keyfield'] }];
        }
        this.reload = true;
        this.loading = false;
        this._data = task;
        this.ref.detectChanges();
      };
      if (response && response.componentClassName === 'process' && !response.processName) {
        return this.process.start([response.componentConfig.name]).then((startResponse: ApiResponse) => {
          this.errorMsg = this.process.errorFromResponse(startResponse);
          if (this.errorMsg) {
            this.error(this.errorMsg);
          }
          this.process.next(startResponse.data.processInstanceId).then((nextResponse: ApiResponse) => {
            this.errorMsg = this.process.errorFromResponse(nextResponse);
            if (this.errorMsg) {
              this.error(this.errorMsg);
            }
            done(nextResponse.data);
          });
        });
      }
      done(response);
    };
    const generateTitle = (response?: any, recordData?: any) => {
      const taskTitle = response && response.componentConfig && response.componentConfig.items
        && (response.componentConfig.items[0] || {}).title;
      // `` for preventing reference
      let finalTitle = `${recordData.title || taskTitle || this.nodeTitle || this.node || ''}`;
      if (this.control['windowMask']) {
        finalTitle = `${this.control['windowMask']}`;
        (this.control['windowMask'].match(/#(\w+)#+/ig) || []).forEach((match) => {
          const ref = match.replace(/#/g, '');
          finalTitle = finalTitle.replace(match, recordData[ref]);
        });
      }
      if (finalTitle) {
        this.titleUpdated.emit(finalTitle);
      }
    };
    this.reload = false;
    this.loading = true;
    this._data = undefined;
    this.loadDefaultNode();
    this.ref.detectChanges();

    this.api.get('profileview1', [`${this.task.id}-${this.node}`], {}, { skipCache: true }).then((_response: ApiResponse) => {
      this.errorMsg = this.process.errorFromResponse(_response);
      if (this.errorMsg) {
        this.error(this.errorMsg);
      }
      const response = _response.data;
      const model = response && response.componentConfig && response.componentConfig.model;
      if (model) {
        const keyfield = (this.process.get(`profile-${this.control['id']}`) || {}).Keyfield;
        return this.api.get(model.proxy.url, keyfield && [keyfield], {}, { skipCache: true }).then((modelResponse: ApiResponse) => {
          this.errorMsg = this.process.errorFromResponse(modelResponse);
          if (this.errorMsg) {
            this.error(this.errorMsg);
            this.loading = false;
            this.ref.detectChanges();
            return;
          }
          const newRecordData = this._lastModel = modelResponse.data;
          generateTitle(response, newRecordData);
          finalHandle(response, this._lastModel);
        });
      }
      generateTitle(response, this.recordData);
      const debug = this.process.get('environment').debug;
      if (debug) {
        return this.api.get('designer', ['profile', this.task.id], {}, { skipCache: true }).then((designerResponse: ApiResponse) => {
          this.designerData = designerResponse.data;
          finalHandle(response, this._lastModel);
        });
      }
      finalHandle(response, this._lastModel);
    });
  }

  private handleProfile(itemData) {
    const dialogData = itemData.dockItem.dialogData;
    const profileId = dialogData.componentConfig.profileId;
    this.api.get('profilemenu1', [profileId]).then((response: ApiResponse) => {
      this.errorMsg = this.process.errorFromResponse(response);
      if (this.errorMsg) {
        this.error(this.errorMsg);
        return;
      }
      this.handleResponse(response);
    });
  }

  private deleteSelected(itemData) {
    return new Promise((resolve, reject) => {
      if (!this.data || !this.data.componentConfig || !this.data.componentConfig.store.proxy) {
        return reject();
      }
      const url = this.data.componentConfig.store.proxy.url;
      const keyfield = (itemData.activeItem || {})['Keyfield'];
      if (!keyfield) {
        return reject();
      }
      return this.api.delete(url, [keyfield]).then(() => {
        this.showModal = false;
        this.fetchData();
        this.ref.detectChanges();
        return resolve();
      });
    });
  }

  private addSelected(itemData) {
    return new Promise((resolve, reject) => {
      if (!this.data || !this.data.componentConfig || !this.data.componentConfig.store.proxy) {
        return reject();
      }
      const url = this.data.componentConfig.store.proxy.url;
      const keyfield = (itemData.activeItem || {})['Keyfield'];
      if (!keyfield) {
        return reject();
      }
      return this.api.delete(url, [keyfield]).then(() => {
        this.showModal = false;
        this.fetchData();
        this.ref.detectChanges();
        return resolve();
      });
    });
  }

  private handleProcess(itemData) {
    const keyfield = (itemData.activeItem || {})['Keyfield'];
    const dialogData = itemData.dockItem.dialogData;
    const endpoint = dialogData.componentConfig.name;
    const shouldResume = itemData.activeItem && itemData.activeItem.hasOwnProperty('TaskId');
    const params = dialogData.componentConfig.params || [];
    const inputs = { keyfield };
    const pseudoJson = dialogData && dialogData.componentConfig && dialogData.componentConfig.attributes || '{}';
    const requestData = this.createRequestBody(params, this.readPseudoJson(pseudoJson), inputs, shouldResume);

    const target = [];
    if (shouldResume) {
      target.push('actions', 'task');
    } else {
      target.push(endpoint, 'start');
    }

    // Add routes from extracted paramters
    target.push(...Object.keys(requestData.routes).map((key) => {
      return requestData.routes[key];
    }));
    this.api.post(dialogData.componentClassName, target, {}, requestData.body).then((response: ApiResponse) => {
      this.errorMsg = this.process.errorFromResponse(response);
      if (this.errorMsg) {
        this.error(this.errorMsg);
        return;
      }

      if (shouldResume) {
        this.handleResponse(response);
        return;
      }
      // Handle /Task after /start happened
      this.process.next(response.data.processInstanceId).then((taskResponse: ApiResponse) => {
        this.errorMsg = this.process.errorFromResponse(response);
        if (this.errorMsg) {
          this.error(this.errorMsg);
          return;
        }
        this.handleResponse(taskResponse);
      });
    });
  }

  private handleResponse(response: ApiResponse) {
    if (!response) {
      this.showModal = true;
      this.loading = false;
      this.ref.detectChanges();
      this.error('Something went wrong.. I got no response.');
      return;
    }
    this.taskData = response.data;
    this.showModal = true;
    this.loading = false;
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    super.OnDestroy(this.ref);
  }
}
