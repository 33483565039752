import { FormGroup, AbstractControl } from '@angular/forms';
import { ProcessService } from '../../services/process.service';
import { Task, Control, ControlType } from '../../models';
import { Helper } from './helper';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DGSNotificationService } from '@dotgov/core';
import { EventEmitter } from '@angular/core';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class FormApi {
  constructor(
    private _form: FormGroup,
    private _process: ProcessService,
    private _task: Task,
    private _notificationService: DGSNotificationService,
  ) { }

  public init() { return this; }

  public get valid() {
    return this._valid;
  }
  public get invalid() {
    return this._invalid;
  }
  public get form() {
    return this._form;
  }
  public get task() {
    return this._task;
  }
  public get gridData() {
    return this._gridData.bind(this);
  }
  public get info() {
    this['_method'] = 'info';
    return this._notify.bind(this);
  }
  public get success() {
    this['_method'] = 'success';
    return this._notify.bind(this);
  }
  public get warning() {
    this['_method'] = 'warning';
    return this._notify.bind(this);
  }
  public get error() {
    this['_method'] = 'error';
    return this._notify.bind(this);
  }
  public get setReadOnly() {
    return this._setReadOnly.bind(this);
  }
  public get getValue() {
    return this._getValue.bind(this);
  }
  public get setValue() {
    return this._setValue.bind(this);
  }
  public get focus() {
    return this._focus.bind(this);
  }
  public get hideField() {
    return this._hideField.bind(this);
  }
  public get showField() {
    return this._showField.bind(this);
  }
  public get setRequired() {
    return this._setRequired.bind(this);
  }

  public get setMaxLength() {
    return this._setMaxLength.bind(this);
  }
  
  public get setMinDate() {
    return this._setMinDate.bind(this);
  }
  public get setMaxDate() {
    return this._setMaxDate.bind(this);
  }
  public get setPattern() {
    return this._setPattern.bind(this);
  }
  public get clearValue() {
    return this._clearValue.bind(this);
  }
  public get getControl() {
    return this._getControl.bind(this);
  }
  public get getTaskControl() {
    return this._getTaskControl.bind(this);
  }
  public get onChange() {
    return this._onChange.bind(this);
  }
  public get onLookupChange() {
    this['___advanced'] = true;
    return this._onChange.bind(this);
  }
  public get onGridChange() {
    this['___grid'] = true;
    return this._onChange.bind(this);
  }
  public get toggleSection() {
    return this._toggleSection.bind(this);
  }
  public get clickFormBtn() {
    return this._clickFormBtn.bind(this);
  }

  public getLookupValue(referenceName: string, fieldstoReturn?: string[]): Promise<any> {
    const control = this.getTaskControl(referenceName);
    if (!control) {
      return null;
    }
    const res = this._process.get(`${control.key}-value-advanced`);

    if (res && fieldstoReturn) {
      return res.then(value => {
        const output = {};
        fieldstoReturn.forEach((fieldName) => {
          output[fieldName] = value[fieldName];
        });
        return output;
      });
    }
    return res;
  }

  private get _valid() {
    return !this._form.invalid;
  }

  private get _invalid() {
    return this._form.invalid;
  }

  private _gridData(referenceName: string) {
    return this._process.get(`gridinfo-${referenceName}`) || {};
  }

  private _notify(message, title) {
    const method = this['_method'] || 'info';
    if (!this._notificationService || !this._notificationService[method]) {
      return;
    }
    this._notificationService[method](message, title);
  }

  private _getValue(referenceName: string) {
    const { formControl, valid } = this._controlData(referenceName);
    const formData = Helper.getFormData(this._form, this._process, this._task);
    if (!valid || !formData) {
      return;
    }
    const recordData = this._task && this._task.componentConfig && this._task.componentConfig.recordData;
    return formData[referenceName] || formControl.value || recordData[referenceName];
  }

  private _setValue(referenceName: string, value: any) {
    setTimeout(() => {
      const { taskControl, formControl, valid } = this._controlData(referenceName);
      if (!valid) {
        return;
      }
      if (taskControl) {
        if (ControlType.controlType(taskControl.controlType) === 'Lookup') {
          this._process.changes = { changeName: `lookup-${referenceName}`, changeVal: value };
          return;
        }
      }
      if (formControl) {
        formControl.setValue(value, { eventEmit: true, onlySelf: true });
      }
    }, 0);
  }

  private _focus(referenceName: string) {
    const htmlElement = this._getHtmlControl(referenceName);
    if (!htmlElement) {
      return;
    }
    htmlElement.focus();
  }

  private _hideField(referenceName: string, target: string = 'control') {
    const taskControl = this._getTaskControl(referenceName, target);
    if (!taskControl) {
      console.warn('No control found for', referenceName, 'on hidding.');
      return;
    }
    return taskControl.isHidden = true;
  }

  private _showField(referenceName: string, target: string = 'control') {
    const taskControl = this._getTaskControl(referenceName, target);
    if (!taskControl) {
      console.warn('No control found for', referenceName, 'on showing.');
      return;
    }
    return taskControl.isHidden = false;
  }

  private _toggleSection(referenceName: string, show: boolean) {
    this[show ? '_showField' : '_hideField'](referenceName, 'section');
    this._process.changes = { changeName: 'detectChanges', changeVal: referenceName };
  }

  private _setRequired(referenceName: string, state: boolean) {
    const { taskControl, formControl, valid } = this._controlData(referenceName);
    if (!valid) {
      return;
    }
    setTimeout(() => {
      if (taskControl) {
        taskControl.isRequired = state;
      }
      if (formControl) {
        formControl.markAsDirty({ onlySelf: false });
        formControl.updateValueAndValidity({ emitEvent: true });
      }
    }, 0);
  }

  private _setReadOnly(referenceName: string, state: boolean) {
    const { taskControl, formControl, valid } = this._controlData(referenceName);
    if (!valid) {
      return;
    }
    setTimeout(() => {
      if (taskControl) {
        taskControl.isReadOnly = state;
      }
      if (formControl) {
        formControl.markAsDirty({ onlySelf: false });
        formControl.updateValueAndValidity({ emitEvent: true });
      }
    }, 0);
  }

  private _setMinDate(referenceName: string, minDate: any) {
    const { taskControl, formControl, valid } = this._controlData(referenceName);
    if (!valid) {
      return;
    }
    setTimeout(() => {
      if (taskControl) {
        taskControl.minDate = new Date(minDate);
      }
      if (formControl) {
        formControl.markAsDirty({ onlySelf: false });
        formControl.updateValueAndValidity({ emitEvent: true });
      }
    }, 0);
  }

  private _setMaxDate(referenceName: string, maxDate: any) {
    const { taskControl, formControl, valid } = this._controlData(referenceName);
    if (!valid) {
      return;
    }
    setTimeout(() => {
      if (taskControl) {
        taskControl.maxDate = new Date(maxDate);
      }
      if (formControl) {
        formControl.markAsDirty({ onlySelf: false });
        formControl.updateValueAndValidity({ emitEvent: true });
      }
    }, 0);
  }

  private _setPattern(referenceName: string, pattern: string, errorMsg: string) {
    const { taskControl, formControl, valid } = this._controlData(referenceName);
    if (!valid) {
      return;
    }
    setTimeout(() => {
      if (taskControl) {
        taskControl.paternMessage = errorMsg;
        taskControl.pattern = pattern;
      }
      if (formControl) {
        formControl.markAsDirty({ onlySelf: false });
        formControl.updateValueAndValidity({ emitEvent: true });
      }
    }, 0);
  }

  private _setMaxLength(referenceName: string, size: number) {
    const { taskControl, formControl, valid } = this._controlData(referenceName);
    if (!valid) {
      return;
    }
    setTimeout(() => {
      if (taskControl) {
        taskControl.size = size;
      }
      if (formControl) {
        formControl.markAsDirty({ onlySelf: false });
        formControl.updateValueAndValidity({ emitEvent: true });
      }
    }, 0);
  }

  private _clearValue(referenceName: string, value: any) {
    this._setValue(referenceName, null);
  }

  private _getControl(referenceName: string, target: string = 'control'): AbstractControl {
    const taskControl = this._getTaskControl(referenceName, target);
    if (!taskControl) {
      return;
    }
    return this._form.controls[taskControl.key];
  }

  private _getTaskControl(referenceName: string, target: string = 'control'): Control {
    return this._process.get(`${target}-${referenceName}`);
  }

  private _onChange(referenceName: string, cb: (value) => any, advanced: boolean = false) {
    advanced = this['___advanced'];
    const isGrid = this['___grid'];
    if (isGrid) {
      const gridData: EventEmitter<any> = this._process.get(`gridchange-${referenceName}`);
      if (!gridData) {
        return;
      }
      gridData.subscribe((destroyed) => {
        if (destroyed) {
          return;
        }
        cb(this._gridData(referenceName));
      });
      return;
    }
    const { taskControl, formControl, valid } = this._controlData(referenceName);
    if (!valid || !formControl) {
      return;
    }
    return formControl.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe((_val) => {
      if (ControlType.controlType(taskControl.controlType) !== 'Lookup') {
        cb(_val);
        return;
      }
      cb(this._process.get(`${taskControl.key}-value${advanced ? '-advanced' : ''}`));
    });
  }

  private _getHtmlControl(referenceName: string, controlName: string = 'control'): HTMLElement {
    return document.getElementById(`${controlName}-${referenceName}`);
  }

  private _clickFormBtn(taskId: string, btnName: string) {
    const selector = `fv-navigator#${taskId} button[name="btn-${btnName.toLowerCase()}"]`;
    setTimeout(() => {
      (<HTMLElement>document.querySelector(selector)).click();
    }, 1);
  }

  private _controlData(referenceName) {
    const taskControl = this._getTaskControl(referenceName);
    if (!taskControl) {
      console.warn('No such control ', referenceName);
      return { taskControl: undefined, formControl: undefined, valid: false };
    }
    const formControl = this._form.controls[taskControl.key];
    return { taskControl, formControl, valid: !!(formControl || taskControl) };
  }

}
