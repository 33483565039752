
/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Extract {
  constructor(
    public table?: string,
    public view?: string,
  ) { }
}
