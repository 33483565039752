import { Component, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';
import { ApiService, DGSNotificationService } from '@dotgov/core';
import { Helper } from '../../../shared';
import { ValidationService } from '../../../../services/validation.service';

export type FileData = { finalSize: string, sizeName: string, sizeIndex: number, bytes: number };
/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./../controls.css', './upload-file.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsUploadFileComponent extends FVAbstractControl implements AfterViewInit {
  isDragOver: boolean;
  private _files: any[] = [];

  @ViewChild('fileInput', { read: ViewContainerRef }) fileInput;

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private notification: DGSNotificationService,
    private validator: ValidationService,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    super.setValue(this.files);
    super.saveKey();
    super.evalEventJs();
    this.loadCurrentValue();
  }

  /**
   * Triggerd on file dragged over div. Tell js not to touch the div.
   * @param event
   */
  dragover(event: any) {
    event.stopPropagation();
    event.preventDefault();
    // Explicitly show this is a copy.
    event.dataTransfer.dropEffect = 'copy';
  }

  addFile(event: any, files: any[]) {
    event.stopPropagation();
    event.preventDefault();

    const file = files[0];
    if (!file) {
      return;
    }
    const refFile = { name: file.name, size: file.size, type: file.type };
    // Allow only alphanumerics and special chars ([space], [underscore], [dot]);
    let sanitizedName = refFile.name.replace(/[^A-Za-z0-9 _.]+/ig, '');
    const [name, format] = sanitizedName.split('.').map((part) => part.trim());
    if (name.length < 3) {
      sanitizedName = `file.${format}`;
    }
    refFile.name = sanitizedName;
    if (this.fileExists(refFile)) {
      this.notification.warning('You cant upload twice same file.');
      return;
    }
    if (!this.isValidFile(refFile.name)) {
      this.notification.warning(`You can upload only ${this.allowedFormats.map((format) => format.toUpperCase()).join(', ')}.`);
      return;
    }
    const formData = new FormData();
    const onError = (err) => {
      this.files = [];
      this.notification.error(err);
      return;
    };

    const fileSize = this.bytesToSize(refFile.size);
    if (!fileSize) {
      return onError('Something went wrong.');
    }
    const allowedSize = this.allowedSize;
    const isValidSize = fileSize.sizeIndex <= allowedSize.sizeIndex
      && !(fileSize.sizeIndex === allowedSize.sizeIndex && Number(fileSize.finalSize) > Number(allowedSize.finalSize));
    if (!isValidSize) {
      return onError(`File must not exceed ${this.sanitizedAllowedSize}.`);
    }

    formData.append('file', file, refFile.name);
    const loading = (_loading: boolean) => {
      this.process.changes = {
        changeName: `loading-file${this.task.processInstanceId || this.task.id}`,
        changeVal: _loading,
      };
    };
    loading(true);
    this.api.post('file', [], {}, formData, {
      skipCache: true,
      headers: {
        'Accept': '*/*',
      },
    }).then((result) => {
      if (result.error) {
        loading(false);
        return onError((result.error.error || {}).message || result.error.message || 'Something went wrong.');
      }
      if (this.group['form']) {
        this.group['form'].markAsDirty();
      }
      result.data.size = `${fileSize.finalSize} ${fileSize.sizeName}`;
      this.files = [result.data];
      loading(false);
    });
  }

  deleteFile(hiddenId: string) {
    const newFiles = this.files.filter((file) => file.hiddenId !== hiddenId);
    this.files = newFiles;
  }

  dropZoneClicked(event: any) {
    this.fileInput.element.nativeElement.click();
  }

  fileUrl(file: any): string {
    return this.api.API + file.url;
  }

  sanitizedSize(bytes: number): string | number {
    if (isNaN(bytes)) {
      return bytes;
    }
    const final = this.bytesToSize(bytes);
    return `${final.finalSize} ${final.sizeName}`;
  }

  downloadFile(rowData: any) {
    Helper.downloadFile(this.api, rowData);
  }

  get fileControlRef(): any {
    return this.task.componentConfig.model
      && this.task.componentConfig.model.fields.find((field) => field.name === this._control.referenceName)
      || this.controlRef();
  }

  get files(): any[] {
    return this._files;
  }

  get allowedFormats(): string[] {
    return (this.fileControlRef || {}).allowedFileTypes || ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'docx', 'doc', 'xls', 'xlsx', 'rar', 'zip'];
  }

  /**
   * Displayed to end user.
   */
  get sanitizedAllowedFormats(): string {
    return this.allowedFormats.join(', ').toUpperCase();
  }

  get allowedSize(): FileData {
    const maxSize = (this.fileControlRef || {}).allowedFileSize || 10097152;
    return this.bytesToSize(maxSize);
  }

  get sanitizedAllowedSize(): string | number {
    return this.sanitizedSize(this.allowedSize.bytes);
  }

  set files(files: any[]) {
    this._files = files;
    this.process.set(`${this._control.key}-value`, files, this.task && this.task.taskId || this.task.id);
    const formControl = this.group.controls[this.control.key];
    if (formControl) {
      formControl.markAsDirty();
      formControl.setValue(files.map((file) => file.fileName || file.hiddenId).join(' ') || null, { onlySelf: true, EventEmit: false });
      this.validator.update(formControl.value);
    }
  }

  private loadCurrentValue() {
    const alwaysArray = (item) => {
      if (!Array.isArray(item)) {
        return item && item['fileName'] ? [item] : [];
      }
      return item;
    };
    const files = alwaysArray(this.task.componentConfig.recordData[this.control.referenceName])
      || alwaysArray(this.process.get(`${this.control.key}-value`));
    setTimeout(() => {
      this.files = files;
    }, 10);
  }

  private bytesToSize(bytes: number): FileData {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return;
    }
    const sizeIndex = Number(Math.floor(Math.log(bytes) / Math.log(1024)).toFixed(2));
    let finalSize = '';
    if (sizeIndex === 0) {
      finalSize = String(bytes);
    } else {
      finalSize = (bytes / Math.pow(1024, sizeIndex)).toFixed(1);
    }
    return { finalSize, sizeName: sizes[sizeIndex], sizeIndex, bytes };
  }

  /**
   * Returns if given file found inside active files, matching full name and size.
   */
  private fileExists(file: { name: string, size: number }) {
    return this.files && this.files.find((_file) => {
      return _file.fileName === file.name
        && this.sanitizedSize(file.size) === this.sanitizedSize(_file.size);
    });
  }

  private getExtension(filename: string): string {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }

  private isValidFile(filename: string): boolean {
    const ext = this.getExtension(filename);
    return this.allowedFormats.indexOf(ext.toLowerCase()) !== -1;
  }
}
