import { Component } from '@angular/core';
import { ApiResponse, ApiService } from '@dotgov/core';
import { ToastrService } from 'ngx-toastr';
import { Task } from '../../models/task';
import { ProcessService } from '../../services/process.service';

/**
 * Run only by `ng serve`.
 * Component bootstraped by dev.module.
 */
/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.sass'],
})
export class BootstrapComponent {
  currentTask: Task;
  errorMsg: string;
  title: string;

  constructor(
    private api: ApiService,
    private process: ProcessService,
    private notification: ToastrService,
  ) {
    this.loadProfile();
  }

  onTaskDone() {
    this.notification.success('Task Done.');
  }

  private loadProfile() {
    this.currentTask = null;
    this.api.get('profilemenu1', ['MyCertificates']).then((profileResponse: ApiResponse) => {
      this.errorMsg = this.process.errorFromResponse(profileResponse);
      if (this.errorMsg) {
        return;
      }
      this.currentTask = profileResponse.data;
    });
  }
}
