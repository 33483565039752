
/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Menu {
  constructor(
    public id?: string,
    public defaultNode?: string,
    public outlookView?: boolean,
    public title?: string,
    public windowMask?: any,
    public windowMaskTable?: any,
    public level?: string,
    public icon?: string,
    public folders?: Folder[],
  ) { }
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Folder {
  constructor(
    public type?: number | string,
    public name?: string,
    public title?: string,
    public icon?: string,
    public showRecordsCount?: boolean,
    public renderRootFolder?: boolean,
    public dynamicTreeOptions?: any,
    public count?: number | string,
    public window?: any,
    public folders?: Folder[],
    public model?: { form: string, inputFilterField: string, readonly: boolean, table: string },
  ) { }
}
