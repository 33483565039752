// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return
// tslint:disable:no-parameter-reassignment prefer-switch

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Chinese (China) [zh-cn]
//! author : suupic : https://github.com/suupic
//! author : Zeno Zeng : https://github.com/zenozeng

export const zhCnLocale: LocaleData = {
  abbr: 'zh-cn',
  months: 'ä¸æ_äºæ_ä¸æ_åæ_äºæ_å­æ_ä¸æ_å«æ_ä¹æ_åæ_åä¸æ_åäºæ'.split('_'),
  monthsShort: '1æ_2æ_3æ_4æ_5æ_6æ_7æ_8æ_9æ_10æ_11æ_12æ'.split('_'),
  weekdays: 'æææ¥_ææä¸_ææäº_ææä¸_ææå_ææäº_ææå­'.split('_'),
  weekdaysShort: 'å¨æ¥_å¨ä¸_å¨äº_å¨ä¸_å¨å_å¨äº_å¨å­'.split('_'),
  weekdaysMin: 'æ¥_ä¸_äº_ä¸_å_äº_å­'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY/MM/DD',
    LL: 'YYYYå¹´MæDæ¥',
    LLL: 'YYYYå¹´MæDæ¥Ahç¹mmå',
    LLLL: 'YYYYå¹´MæDæ¥ddddAhç¹mmå',
    l: 'YYYY/M/D',
    ll: 'YYYYå¹´MæDæ¥',
    lll: 'YYYYå¹´MæDæ¥ HH:mm',
    llll: 'YYYYå¹´MæDæ¥dddd HH:mm'
  },
  meridiemParse: /åæ¨|æ©ä¸|ä¸å|ä¸­å|ä¸å|æä¸/,
  meridiemHour(hour, meridiem) {
    if (hour === 12) {
      hour = 0;
    }
    if (meridiem === 'åæ¨' || meridiem === 'æ©ä¸' ||
      meridiem === 'ä¸å') {
      return hour;
    } else if (meridiem === 'ä¸å' || meridiem === 'æä¸') {
      return hour + 12;
    } else {
      // 'ä¸­å'
      return hour >= 11 ? hour : hour + 12;
    }
  },
  meridiem(hour, minute, isLower) {
    let hm = hour * 100 + minute;
    if (hm < 600) {
      return 'åæ¨';
    } else if (hm < 900) {
      return 'æ©ä¸';
    } else if (hm < 1130) {
      return 'ä¸å';
    } else if (hm < 1230) {
      return 'ä¸­å';
    } else if (hm < 1800) {
      return 'ä¸å';
    } else {
      return 'æä¸';
    }
  },
  calendar: {
    sameDay: '[ä»å¤©]LT',
    nextDay: '[æå¤©]LT',
    nextWeek: '[ä¸]ddddLT',
    lastDay: '[æ¨å¤©]LT',
    lastWeek: '[ä¸]ddddLT',
    sameElse: 'L'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(æ¥|æ|å¨)/,
  ordinal(_num: number, period) {
    const num = Number(_num);
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
        return num + 'æ¥';
      case 'M':
        return num + 'æ';
      case 'w':
      case 'W':
        return num + 'å¨';
      default:
        return num.toString();
    }
  },
  relativeTime: {
    future: '%så',
    past: '%så',
    s: 'å ç§',
    ss: '%d ç§',
    m: '1 åé',
    mm: '%d åé',
    h: '1 å°æ¶',
    hh: '%d å°æ¶',
    d: '1 å¤©',
    dd: '%d å¤©',
    M: '1 ä¸ªæ',
    MM: '%d ä¸ªæ',
    y: '1 å¹´',
    yy: '%d å¹´'
  },
  week: {
    // GB/T 7408-1994ãæ°æ®ååäº¤æ¢æ ¼å¼Â·ä¿¡æ¯äº¤æ¢Â·æ¥æåæ¶é´è¡¨ç¤ºæ³ãä¸ISO 8601:1988ç­æ
    dow: 1, // Monday is the first day of the week.
    doy: 4  // The week that contains Jan 4th is the first week of the year.
  }
};
