import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { IDGSEnvironment } from '../models/environment';

import { ApiService } from '../services/api.service';
import { StorageService } from '../services/storage.service';
import { FactoryService } from '../services/factory.service';
import { DGSNotificationService } from '../services/notification.service';
import { DGSLowerTranslatePipe } from '../pipes/translate.pipe';

@NgModule({
  declarations: [
    DGSLowerTranslatePipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [
    DGSLowerTranslatePipe,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DGSCoreModule {
  public static forRoot(environment: IDGSEnvironment, authenticator?: Function): ModuleWithProviders<DGSCoreModule> {
    return {
      ngModule: DGSCoreModule,
      providers: [
        ApiService,
        StorageService,
        FactoryService,
        DGSNotificationService,
        { provide: 'environment', useValue: environment },
        { provide: 'authenticator', useValue: authenticator },
      ],
    };
  }
}
