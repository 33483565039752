import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ProcessService } from '../../services/process.service';
import { Task, Dock, FVDoneResponse } from '../../models';
import { Subscription } from 'rxjs';
import { FormApi } from '../shared/form-api';
import { IDGSChangeSet, ApiService, DGSNotificationService } from '@dotgov/core';
import { Helper } from '../shared/helper';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ObjectLiteral } from '../../models/objectLiteral';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.css', './controls/controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVRendererComponent implements AfterViewInit, OnDestroy {
  error: string;
  showBuilder: boolean;
  builderTask;
  builderModels;

  showPreview: boolean;
  previewBuilderTask;
  previewBuilderModels;

  private subscriptions: Subscription[] = [];
  private _canRunBuilder: boolean;
  private _parentTask: Task;
  private _activeTask: Task;
  private _task: Task;
  private _localLoading: boolean;
  private _disabled: boolean;
  private _hideName: boolean;

  @Input() loading: boolean;
  @Input() firstLevel: boolean;
  @Input() set parentTask(parentTask: Task) {
    this._parentTask = parentTask;
  }
  @Input() set task(task: Task) {
    this._task = task;
  }
  @Input() set hideName(should: boolean) {
    this._hideName = should;
  }
  @Input() set disabled(disabled: boolean) {
    if (this._disabled !== disabled) {
      this._disabled = disabled;
    }
  }

  @Input() customSubmit = false;

  @Output() onDone = new EventEmitter<FVDoneResponse>();
  @Output() onLoaded = new EventEmitter<any>();
  @Output() titleUpdated = new EventEmitter<string>();
  @Output() taskUpdated = new EventEmitter<Task>();
  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter<any>();

  @ViewChild('multiTabsetTemplate') tabset: TabsetComponent;
  @ViewChild('formRenderer') formRenderer;
  @ViewChild('formContainer') formContainer;

  constructor(
    private process: ProcessService,
    private ref: ChangeDetectorRef,
    private api: ApiService,
    private notification: DGSNotificationService,
  ) { }

  ngAfterViewInit() {
    if (this.task) {
      this.process.activeTask = this.task;
    }
    const handleOnLoad = () => {
      // Eval on load scrips
      if (this.task && this.task.componentConfig && this.task.componentConfig.items) {
        const debug = this.process.get('environment').debug;
        const controls = this.task.componentConfig.controls || [];
        const wrongControls = controls
          .filter((_control, _index) => controls.findIndex((__control) => __control.referenceName === _control.referenceName) !== _index)
          .map((control) => control.referenceName);
        if (wrongControls && wrongControls.length && debug) {
          console.warn(`Please fix the dublicate controls: ${wrongControls.join(', ')}.`);
        }
        this.task.componentConfig.items.forEach((_item) => {
          const handler = (_item.handlers || {})['onloadscript'];
          if (!handler) {
            return;
          }
          this.evalOnLoad(this.task, handler);
        });
      }
    };
    handleOnLoad();
    this.onLoaded.emit(true);
    this._canRunBuilder = this.process.canRunBuilder;
    this.ref.detectChanges();
    this.subscriptions.push(this.process.onChange.subscribe((changeSet: IDGSChangeSet) => {
      if (changeSet.changeName === 'userData') {
        this._canRunBuilder = changeSet.changeVal;
      }
      if (changeSet.changeName === `loading-file${this.task.processInstanceId || this.task.id}`) {
        this._localLoading = !!changeSet.changeVal;
      }
    }));
    this.subscriptions.push(this.process.onAnyUpdate.subscribe((task: Task) => {
      if (!task || !this.task || !this.task.processInstanceId || !task.processInstanceId) {
        return;
      }
      let freshId = task.processInstanceId;
      const oldId = this.process.get(`oldId-${freshId}`);
      const currentId = this.task.processInstanceId;

      if (oldId && currentId !== freshId) {
        freshId = oldId;
        this.process.remove(`oldId-${oldId}`);
      }

      this._activeTask = task;
      if (task && task.componentConfig && task.componentConfig.title) {
        this.titleUpdated.emit(task.componentConfig.title);
      }

      handleOnLoad();
      this.scrollToTop();

      // Mark as pristine on 'next'/'prev'
      Object.keys(this.formRenderer.controls).forEach((key) => {
        this.formRenderer.controls[key].markAsPristine();
        this.formRenderer.controls[key].setErrors(null);
      });

      this.taskUpdated.emit(this.task);
    }));

    this.subscriptions.push(this.process.onAnyLoading.subscribe((response: { status: boolean, processId: string }) => {
      if (!response || !this.task || !this.task.processInstanceId || !response.processId) {
        return;
      }
      let freshId = response.processId;
      const oldId = this.process.get(`oldId-${freshId}-loading`);
      const currentId = this.task.processInstanceId;

      if (oldId && currentId !== freshId) {
        freshId = oldId;
        this.process.remove(`oldId-${oldId}-loading`);
      }
      this._localLoading = response.status;
    }));
    this.subscriptions.push(this.process.onAnyError.subscribe((response: { processId: string, error: string }) => {
      if (!response.processId || response.processId !== this.processId) {
        return;
      }
      this.error = response.error;
    }));
  }

  updateTitle(title) {
    this.titleUpdated.emit(title);
  }

  loadPreview(previewData) {
    if (!previewData) {
      return;
    }
    this.previewBuilderModels = previewData.models;
    this.previewBuilderTask = previewData.task;
    this.showPreview = true;
  }

  updateFormGroup(event) {
    this.form.then((form) => {
      const control = form.controls[event.key];
      if (!control) {
        // tslint:disable-next-line:no-console
        console.debug('Update control emitted by ' + this.processId + ' but no control found!', event, form);
        return;
      }
      control.setValue(event.value);
    });
  }

  _onDone(response: FVDoneResponse) {
    this.onDone.emit(response);
  }

  _onErrors(errors: ObjectLiteral) {
    const tabs: number[] = Helper.removeDublicates(Object.keys(errors || {}).map(key => errors[key]));
    const debug = this.process.get('environment').debug;
    if (!this.tabset) {
      if (debug) {
        console.warn('Something went wrong with tabset from form-renderer.');
      }
      return;
    }
    if (!tabs.length) {
      if (debug) {
        console.warn('Something went wrong with validation.');
      }
      return;
    }
    if (tabs.length === 1) {
      this.tabset.tabs[tabs[0]].active = true;
    }
    this.tabset.tabs[tabs.sort()[0]].active = true;
  }

  async openBuilder() {
    if (!this.canRunBuilder || !this.task || !this.task.componentConfig || !this.task.componentConfig.model) {
      return;
    }
    const url = this.task.componentConfig.model.entityName;
    if (!url) {
      return;
    }
    const [table, view] = url.split('-');
    const builderModels = await this.api.get('Designer', ['model', table]);
    const builderTask = await this.api.get('Designer', ['forms', table, view]);
    if (builderModels.error || builderTask.error) {
      return;
    }
    this.builderModels = builderModels.data;
    this.builderTask = builderTask.data;
    this.showBuilder = true;
    this.ref.detectChanges();
  }

  hideBuilder() {
    this.showBuilder = false;
    this.ref.detectChanges();
  }

  hidePreview() {
    this.showPreview = false;
    this.ref.detectChanges();
  }

  onBuilderSave({ models, task }) {
    if (!this.canRunBuilder || !this.task || !this.task.componentConfig || !this.task.componentConfig.model) {
      return;
    }
    const url = this.task.componentConfig.model.entityName;
    if (!url) {
      return;
    }
    const [table, view] = url.split('-');
    Promise.all([
      this.api.post('Designer', ['model', table], {}, models),
      this.api.post('Designer', ['forms', table, view], {}, task),
    ]).then((responses) => {
      const errors = responses.map((resp) => resp.error).filter((err) => !!err);
      if (errors.length) {
        this.notification.error('Something went wrong on saving.');
        console.log(errors);
        return;
      }
      this.notification.success('Succsessfully saved');
    });
  }

  get isGrid() {
    return this.task.hasOwnProperty('defaultNode');
  }

  get hideName(): boolean {
    return this._hideName;
  }

  get loadingStatus(): boolean {
    return this._localLoading || this.loading;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get dockedTop(): Dock[] {
    return this.docked && this.docked.filter((dock) => dock.dock.toLowerCase() === 'top') || [];
  }

  get dockedBottom(): Dock[] {
    return this.docked && this.docked.filter((dock) => dock.dock.toLowerCase() === 'bottom') || [];
  }

  get docked(): Dock[] {
    return this.task && this.task.dockedItems || null;
  }

  get parentTask(): Task {
    return this._parentTask;
  }

  get task(): Task {
    return this._activeTask || this._task;
  }

  get stateId(): string {
    return this.task.stateId;
  }

  get processId(): string {
    return this.task.processInstanceId;
  }

  get canRunBuilder(): boolean {
    return this._canRunBuilder;
  }

  get progressPercentage(): string {
    const { current, total } = this.task.processProgress;
    const progress = (current * 100 / total).toFixed(0);
    return `${progress}%`;
  }

  private get form(): Promise<any> {
    return new Promise((resolve) => {
      this.hackLook(() => {
        return resolve(this.formRenderer);
      });
    });
  }

  private scrollToTop() {
    setTimeout(() => {
      this.formContainer.nativeElement.scrollIntoView();
    }, 1);
  }

  /**
   * Execute CB out of angular context.
   * @param cb
   */
  private hackLook(cb) {
    setTimeout(() => { cb(); }, 0);
  }

  private evalOnLoad(task: Task, script: string) {
    setTimeout(() => {
      const frm = new FormApi(this.formRenderer, this.process, task, this.notification);
      frm.init();
      try {
        // tslint:disable-next-line:no-eval
        eval(script);
      } catch (e) {
        console.error('Error executing onloadscript', e);
      }
    }, 5);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs && subs.unsubscribe());
    // this.onDone.emit({ done: false, close: true, manual: true });
    if (this.task) {
      this.process.clearTaskCached(this.task.taskId || this.task.id);
    }
    this.ref.detach();
  }
}
