import { Component, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';

@Component({
  selector: 'fv-controls-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsPhoneFieldComponent extends FVAbstractControl implements AfterViewInit {
  
   nonNumberCharRegEx: RegExp = new RegExp("(?<=.{1})[^0-9]", "gi");
   firstPhoneCharRegEx: RegExp = new RegExp("[^+0-9]", "gi");
  
  constructor(process: ProcessService) {
    super(process);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
    this.formControl.valueChanges.subscribe(value =>this.parsePhoneNumber(value))
  }

  parsePhoneNumber(value: string):void {
    if(value){
      const phoneNumber = value.replace(this.nonNumberCharRegEx, '')
                                  .replace(this.firstPhoneCharRegEx, '');
      this.formControl.setValue(phoneNumber, { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
    }
  }
}
