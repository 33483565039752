import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { IDGSEnvironment } from './../models/environment';
import { LoginMetadata } from './../models/login.metadata';
import { Auth } from '../models/auth';

export class ApiResponse {
  constructor(
    public data?: { count: number, data: Object } | any,
    public error?: any | undefined,
  ) { }
}

@Injectable()
export class ApiService extends StorageService {
  private sanitizeMark = '___mk___';

  constructor(
    private httpClient: HttpClient,
    @Inject('authenticator') private customAuthenticator,
    @Inject('environment') private environment: IDGSEnvironment,
  ) {
    super();
  }

  /**
   * Make GET request to api
   * @param point
   * @param args
   * @param parameters
   * @param options
   */
  get(point: string, args: string[] = [], parameters: Object = {}, options: Object = {}): Promise<any> {
    return this.request('GET', point, parameters, options, undefined, ...args);
  }

  /**
   * Make DELETE request to api
   * @param point
   * @param args
   * @param parameters
   * @param options
   */
  delete(point: string, args: string[] = [], parameters: Object = {}, options: Object = {}, body: object | string = {}): Promise<any> {
    return this.request('DELETE', point, parameters, options, body, ...args);
  }

  /**
   * Make OPTIONS request to api
   * @param point
   * @param args
   * @param parameters
   * @param options
   */
  options(point: string, args: string[] = [], parameters: Object = {}, options: Object = {}): Promise<any> {
    return this.request('OPTIONS', point, parameters, options, undefined, ...args);
  }

  /**
   * Make POST request to api
   * @param point
   * @param args
   * @param parameters
   * @param options
   */
  post(point: string, args: string[] = [], parameters: Object = {}, body: object | string = {}, options: Object = {}): Promise<any> {
    return this.request('POST', point, parameters, options, body, ...args);
  }

  /**
   * Make PATCH request to api
   * @param point
   * @param args
   * @param parameters
   * @param options
   */
  patch(point: string, args: string[] = [], parameters: Object = {}, body: object | string = {}, options: Object = {}): Promise<any> {
    return this.request('PATCH', point, parameters, options, body, ...args);
  }

  /**
   * Make PUT request to api
   * @param point
   * @param args
   * @param parameters
   * @param options
   */
  put(point: string, args: string[] = [], parameters: Object = {}, body: object | string = {}, options: Object = {}): Promise<any> {
    return this.request('PUT', point, parameters, options, body, ...args);
  }

  /**
   * Return generated url
   * @param point
   * @param args
   * @param parameters
   * @param body
   * @param options
   */
  getURL(point: string, args: string[] = [], parameters: Object = {}, body: object | string = {}, options: Object = {}) {
    return this.request('getURL', point, parameters, options, body, ...args);
  }

  get apiRenameList() {
    return ['Name', 'Title'];
  }

  get API(): string {
    return this.environment.apiUrl;
  }

  private rename(origin, target) {
    this.languages.forEach((lang) => {
      const from = `${origin}_${lang}`;
      const to = `${origin}`;
      if (target) {
        return;
      }
      if (target.hasOwnProperty(from) && !target.hasOwnProperty(to)) {
        target[to] = target[from];
        delete target[from];
      }
    });
  }

  /**
   * Generates final url from api_url + view + query parameters;
   * @param url
   * @param parameters
   * @param args
   */
  private generateUrl(url: string, parameters: Object, ...args): string {
    const endUrl = `${url}${args.length ? `/${args.join('/')}` : ''}`;
    const queryParams = Object.keys(parameters).map((key) => `${key}=${this.sanitize(parameters[key], key)}`).join('&');

    return `${endUrl}${queryParams ? `?${queryParams}` : ''}`;
  }

  private sanitize(item: any, param?: string) {
    const exists = item !== undefined && item !== null;
    const isNum = (val) => val.constructor === Number;
    const isString = (val) => val.constructor === String;
    if (!exists) {
      return item;
    }
    if (isString(item)) {
      return item.trim();
    }
    if (isNum(item)) {
      return item;
    }
    if (item && Array.isArray(item)) {
      item = item.map((curr) => {
        if (!curr) {
          return curr;
        }
        if (curr.hasOwnProperty('value')) {
          curr.value = curr.value.toString();
        }
        return curr;
      });
    }
    return JSON.stringify(item);
  }

  /**
   * If data contains fields like Name_ru/Name_ro/Name_en transform it into Name.
   * @param data
   */
  private resolve(response: any) {
    if (response && response.data && Array.isArray(response.data)) {
      response.data.forEach((item) => {
        this.apiRenameList.forEach((renameOrigin) => {
          this.rename(renameOrigin, item);
        });
      });
    }
    // Already been sanitized
    if (response && response.hasOwnProperty(this.sanitizeMark)) {
      return response;
    }
    return {
      data: response,
      error: null,
      [this.sanitizeMark]: true,
    };
  }

  /**
   * Private Abstract request function
   * @param method
   * @param point
   * @param parameters
   * @param options
   * @param args
   */
  private request(method: string, point: string, parameters: Object, options: Object, body: Object = null, ...args): Promise<any> | any {
    if (!method) {
      console.warn('Something went wrong with API service.');
      return;
    }
    let apiUrl = this.API;
    // If is already complete url i should not concat with api url.
    const fullUrl = new RegExp('^(?:[a-z]+:)?//', 'i');
    if (fullUrl.test(point)) {
      apiUrl = '';
    }
    method = method.toLowerCase();
    const url = this.generateUrl((options['apiUrl'] || apiUrl) + point, parameters, ...args);
    if (method === 'geturl') {
      return url;
    }
    const cacheUrl = `${url}|${method}`;
    return new Promise((resolve) => {
      const cache = super.get(cacheUrl);
      const progress = super.getProgress(cacheUrl);
      const skipCache = options['skipCache'] || false;

      if (cache && skipCache !== true) {
        // Extract response from already finished request
        return resolve(cache);
      } else if (progress && skipCache !== true) {
        // Extract response from already existing request in progress
        progress.subscribe((response) => {
          super.deleteProgress(cacheUrl);
          const resolvedData = this.resolve(response);
          return resolve(resolvedData);
        });
      } else {
        const cachingMethod = method === 'get' || method === 'options';

        let callParameters = [];

        // Add headers
        const defaultHeaders = {
          'Pragma': 'no-cache',
          'Cache-control': -1
        };
        const headers = Object.assign(defaultHeaders, options['headers'] || {});
        this.authenticator(headers, options);
        options['headers'] = new HttpHeaders(this.cleanHeaders(headers));

        if (cachingMethod) {
          callParameters = [url, options];
          // Request not in cache/progress so we set it.
          super.setProgress(cacheUrl);
        } else if (method === 'delete') {
          callParameters = [url, options, body || {}];
        } else {
          callParameters = [url, body || {}, options];
        }
        this.httpClient[method](...callParameters).toPromise().then((rawData) => {
          const resolvedData = this.resolve(rawData);
          if (cachingMethod) {
            super.set(cacheUrl, resolvedData);
          }
          const getProgress = super.getProgress(cacheUrl);
          if (getProgress) {
            getProgress.emit(resolvedData);
            super.deleteProgress(cacheUrl);
          }
          return resolve(resolvedData);
        }).catch((error) => {
          const res = { data: null, error };

          const getProgress = super.getProgress(cacheUrl);
          if (getProgress) {
            getProgress.emit(res);
            super.deleteProgress(cacheUrl);
          }
          return resolve(res);
        });
      }
    });
  }

  private authenticator(headers, options) {
    if (!this.customAuthenticator) {
      return this.defaultAuthenticator(headers, options);
    }
    return this.customAuthenticator(headers, options);
  }

  private defaultAuthenticator(headers, options) {
    const auth: Auth = JSON.parse(localStorage.getItem('auth'));
    const externalSessionIndex = JSON.parse(localStorage.getItem(LoginMetadata.externalSessionIndexKey));
    if (auth && !headers.hasOwnProperty('Authorization')) {
      headers['Authorization'] = `${auth.tokenType} ${auth.token}`;
    }
    if (externalSessionIndex && !headers.hasOwnProperty(LoginMetadata.externalSessionIndexKey)) {
      headers[`x-${LoginMetadata.externalSessionIndexKey}`] = `${externalSessionIndex}`;
    }
    const userContext = localStorage.getItem(LoginMetadata.activeRole) || (JSON.parse(localStorage.getItem(LoginMetadata.userContext)) || {}).role;
    if (!options['skipRole'] && userContext) {
      headers['x-Current-Role'] = `${userContext}`;
    }
  }

  private cleanHeaders(headers) {
    const cleaned = {};
    Object.keys(headers).forEach(key => {
      const value = headers[key];
      if (value !== null && value !== undefined) {
        cleaned[key] = value;
      }
    });
    return cleaned;
  }

  private get languages() {
    return ['en', 'ro', 'ru'];
  }
}
