// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Estonian [et]
//! author : Chris Gedrim : https://github.com/a90machado

const processRelativeTime = function (num: number, withoutSuffix: boolean, key: string, isFuture: boolean) {
  const format = {
      s : ['mÃµne sekundi', 'mÃµni sekund', 'paar sekundit'],
      ss: [num + 'sekundi', num + 'sekundit'],
      m : ['Ã¼he minuti', 'Ã¼ks minut'],
      mm: [num + ' minuti', num + ' minutit'],
      h : ['Ã¼he tunni', 'tund aega', 'Ã¼ks tund'],
      hh: [num + ' tunni', num + ' tundi'],
      d : ['Ã¼he pÃ¤eva', 'Ã¼ks pÃ¤ev'],
      M : ['kuu aja', 'kuu aega', 'Ã¼ks kuu'],
      MM: [num + ' kuu', num + ' kuud'],
      y : ['Ã¼he aasta', 'aasta', 'Ã¼ks aasta'],
      yy: [num + ' aasta', num + ' aastat']
  };
  if (withoutSuffix) {
      return format[key][2] ? format[key][2] : format[key][1];
  }
  return isFuture ? format[key][0] : format[key][1];
};

export const etLocale: LocaleData = {
  abbr: 'et',
  months: 'jaanuar_veebruar_mÃ¤rts_aprill_mai_juuni_juuli_august_september_oktoober_november_detsember'.split('_'),
  monthsShort: 'jaan_veebr_mÃ¤rts_apr_mai_juuni_juuli_aug_sept_okt_nov_dets'.split('_'),
  weekdays: 'pÃ¼hapÃ¤ev_esmaspÃ¤ev_teisipÃ¤ev_kolmapÃ¤ev_neljapÃ¤ev_reede_laupÃ¤ev'.split('_'),
  weekdaysShort: 'P_E_T_K_N_R_L'.split('_'),
  weekdaysMin: 'P_E_T_K_N_R_L'.split('_'),
  longDateFormat: {
    LT:   'H:mm',
    LTS:  'H:mm:ss',
    L:    'DD.MM.YYYY',
    LL:   'D. MMMM YYYY',
    LLL:  'D. MMMM YYYY H:mm',
    LLLL: 'dddd, D. MMMM YYYY H:mm'
  },
  calendar: {
    sameDay:  '[TÃ¤na,] LT',
    nextDay:  '[Homme,] LT',
    nextWeek: '[JÃ¤rgmine] dddd LT',
    lastDay:  '[Eile,] LT',
    lastWeek: '[Eelmine] dddd LT',
    sameElse: 'L'
  },
  relativeTime : {
    future : '%s pÃ¤rast',
    past   : '%s tagasi',
    s      : processRelativeTime,
    ss     : processRelativeTime,
    m      : processRelativeTime,
    mm     : processRelativeTime,
    h      : processRelativeTime,
    hh     : processRelativeTime,
    d      : processRelativeTime,
    dd     : '%d pÃ¤eva',
    M      : processRelativeTime,
    MM     : processRelativeTime,
    y      : processRelativeTime,
    yy     : processRelativeTime
  },
  dayOfMonthOrdinalParse : /\d{1,2}./,
  ordinal : '%d.',
  week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // The week that contains Jan 4th is the first week of the year.
  }
};
