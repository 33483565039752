import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Task } from '../models/task';
import { ApiService, ApiResponse, IDGSChangeSet, IDGSEnvironment } from '@dotgov/core';
import { FVFormBuilderService } from './formBuilder.service';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Injectable()
export class ProcessService extends FVFormBuilderService {
  private onError = new EventEmitter<{ processId: string, error: string }>();
  private loadingEmitter = new EventEmitter<{ status: boolean, processId?: string }>();
  private updateEmitter = new EventEmitter<Task>();
  private onChangeEmitter = new EventEmitter<IDGSChangeSet>();

  constructor(
    private api: ApiService,
    @Inject('environment') private environment: IDGSEnvironment,
  ) {
    super();
    super.set('environment', environment);
    window['pdfDebug'] = !!environment.debug;
  }

  /**
   * Reffers to API task start method.
   * @param path
   * @param payload
   * @param options
   */
  start(path: string[] = [], payload: Object = {}, options: Object = {}): Promise<any> {
    this.updateAllLoadings(true);
    return this.api.post('process', [...path, 'start'], undefined, payload, options).then((response: ApiResponse) => {
      const errorMsg = this.errorFromResponse(response);
      if (errorMsg) {
        this.catch(null, errorMsg);
        return;
      }
      super.set('start', response);
      this.activeTask = response.data;
      this.updateAllLoadings(false);
      return response;
    }).catch((error) => this.catch(null, error));
  }

  /**
   * Reffers to API task next method.
   * It doesnt do next at all. Its the hook must be after most of /start done to create task instance.
   * @param processId
   * @param options
   */
  next(processId: string, options: Object = {}) {
    this.setLoading(processId, false);
    return this.api.post('process', ['actions', processId, 'next'], {}, {}, options).then((response: ApiResponse) => {
      const errorMsg = this.errorFromResponse(response);
      if (errorMsg) {
        this.catch(processId, errorMsg);
        return;
      }
      super.set(`next-${processId}`, response.data);
      super.set(`process-${processId}-state`, 'next');
      // Record when id's of steps on same task mismatch
      const responseTask = response.data;
      // No memory chain
      const temp = JSON.parse(JSON.stringify(responseTask.processInstanceId));
      if (processId !== temp) {
        responseTask.processInstanceId = processId;
        super.set(`oldId-${processId}`, temp);
      }
      this.activeTask = response.data;
      this.setLoading(processId, false);
      return response;
    }).catch((error) => this.catch(processId, error));
  }

  /**
   * Reffers to API 'task' method.
   * Kinda execute some sort of logic with process.
   * Most used to switch between process steps.
   * @param processId
   * @param taskId
   * @param payload
   * @param options
   */
  task(processId: string, taskId?: string, payload: Object = {}, options: Object = {}) {
    this.setLoading(processId, true);
    const args = ['actions', processId];
    if (taskId) {
      args.push(taskId);
    }
    return this.api.post('process', args, options, payload).then((response: ApiResponse) => {
      const errorMsg = this.errorFromResponse(response);
      if (errorMsg) {
        const error = (response.error || {}).error || {};
        if (error.type === 'Dgs.Model.CustomException') {
          this.setLoading(processId, false);
          return response;
        }
        this.catch(processId, errorMsg);
        return response;
      }
      super.set(`process-${processId}-state`, 'task');
      super.set(`task-${processId}`, response.data);
      // Record when id's of steps on same task mismatch
      const responseTask = response.data;
      // No memory chain
      const temp = JSON.parse(JSON.stringify(responseTask.processInstanceId));
      if (processId !== temp) {
        responseTask.processInstanceId = processId;
        super.set(`oldId-${processId}`, temp);
      }
      this.activeTask = response.data;
      this.setLoading(processId, false);
      return response;
    }).catch((error) => this.catch(processId, error));
  }

  /**
   * Extracts error from task response.
   */
  errorFromResponse(response: ApiResponse) {
    const customError = `Something went wrong, please try again later. :(`;
    if (!response) {
      return customError;
    }
    const errBody = response.error;
    const err = errBody && errBody.error && errBody.error.message || errBody && errBody.statusText;
    // Server Error, should show custom error.
    if (err) {
      return err;
    }
    // Api Error, should show error itself.
    if (response.data && response.data.error) {
      return response.data.error;
    }
    return null;
  }

  /**
   * Updates loading status project-wide.
   */
  updateAllLoadings(status: boolean) {
    this.loadingEmitter.emit({ status });
  }

  /**
   * Get all controls cached for {taskId}.
   * @param taskId
   */
  getTaskCached(taskId) {
    if (!taskId) {
      return [];
    }
    return super.get(`controls-${taskId}`) || [];
  }

  /**
   * Clears all cached controls for {taskId}.
   * @param taskId
   */
  clearTaskCached(taskId) {
    if (!taskId) {
      return;
    }
    const cachedToThis = this.getTaskCached(taskId);
    cachedToThis.forEach((key) => {
      this.remove(key);
    });
    this.remove(`controls-${taskId}`);
  }

  /**
   * Cache {key} with {value} for {taskId}.
   * @param key
   * @param value
   * @param taskId
   */
  set(key: string, value: any, taskId?: string) {
    if (taskId) {
      const controlsRef = `controls-${taskId}`;
      super.set(controlsRef, [...this.getTaskCached(taskId), key]);
    }
    super.set(key, value);
  }

  /**
   * Update loading status for {processId}.
   * @param processId
   * @param status
   */
  setLoading(processId: string, status: boolean) {
    super.set(`oldId-${processId}-loading`, processId);
    this.loadingEmitter.emit({ status, processId });
  }

  get onAnyUpdate(): EventEmitter<any> {
    return this.updateEmitter;
  }

  get onAnyLoading(): EventEmitter<any> {
    return this.loadingEmitter;
  }

  get onAnyError(): EventEmitter<any> {
    return this.onError;
  }

  get onChange(): EventEmitter<any> {
    return this.onChangeEmitter;
  }

  set changes(changes: IDGSChangeSet) {
    this.onChangeEmitter.emit(changes);
  }

  set activeTask(task: Task) {
    const processId = task.processInstanceId;
    if (!processId) {
      return;
    }
    this.setLoading(task.processInstanceId, false);
    this.updateEmitter.emit(task);
  }

  set user(userData) {
    this._canRunBuilder = userData;
    this.changes = { changeName: 'userData', changeVal: this.canRunBuilder };
  }

  private catch(processId: string, error: string) {
    this.onError.emit({ processId, error });
    if (this.environment.debug) {
      console.error(error);
    }
    this.updateAllLoadings(false);
  }
}
