import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[multiLookup][formControlName],[multiLookup][formControl],[multiLookup][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MultiLookupValidatorDirective), multi: true },
  ],
})
export class MultiLookupValidatorDirective implements Validator {
  constructor(
    @Attribute('required') public required: boolean,
  ) { }

  validate(control: AbstractControl): { [key: string]: any } {
    const hasNoValue = !control || !control.value || !control.value.length;
    if (this.required && hasNoValue) {
      return {
        required: true,
      };
    }

    return null;
  }
}
