import {
  Component,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { FormatHelper } from '../../../helpers/format.helper';

@Component({
  selector: 'datagrid-viewer',
  templateUrl: './renderer.component.html',
  styleUrls: ['./renderer.component.scss'],
})
export class GridRendererComponent implements AfterViewInit {
  loading = true;
  fakePageSizeModel = 0;
  showAdvancedSearch: boolean;
  availableFilters = [];
  sorts = [];
  inlineButtons = [];
  filterForm: FormGroup;

  // tslint:disable-next-line:no-output-rename
  @Output('buttonClicked') buttonClick = new EventEmitter<any>();
  @Output('userActions') userActions = new EventEmitter<any>();
  @Output('pageUpdated') pageUpdated = new EventEmitter<number>();
  @Output('select') select = new EventEmitter<any>();

  @ViewChild('defaultButtonRenderTemplate', { static: true }) defButtonTemplate: TemplateRef<any>;

  @Input() footerTemplate: TemplateRef<any>;
  @Input() activePage = 1;
  @Input() searchVal = '';
  @Input() scrollbarH = false;
  @Input() scrollbarV = false;
  @Input() hideSearch = false;
  @Input() hideButtons = false;
  @Input() hideNav = false;
  @Input() hideFooter = false;
  @Input() advancedSearch = true;
  @Input() selectionType = 'multiClick';
  @Input() selectionLimit = 5;
  @Input() rowHeight = 'auto';
  @Input() columnMode = 'force';
  @Input() headerHeight = 40;
  @Input() footerHeight = 40;
  @Input() showPager = true;
  @Input() reorderable = true;
  @Input() swapColumns = true;
  @Input() resizable = true;
  @Input() sortable = true;
  @Input() editablePageSize = true;
  @Input() preventSelectEvent = false;
  @Input() class = 'bootstrap';
  @Input() selectAllRowsOnPage = false;
  @Input() pageSizes = [5, 10, 15, 50, 100];
  @Input()
  set actionButtons(value) {
    this._actionButtons = value || [];
    this.inlineButtons = this._actionButtons.filter((button) => button.renderInline);
  }
  get actionButtons() {
    return this._actionButtons;
  }
  @Input() actionsCell: TemplateRef<any>;
  @Input()
  get selected() {
    return this._selected;
  }
  set selected(value) {
    this._selected = value;
    this.selectedChange.emit(value);
  }
  @Output() selectedChange = new EventEmitter();
  @Input()
  set config(config) {
    this._config = config;
    this._columns = config.displayedColumns.map((col) => {
      const ref = this._config.availableColumns.find((_col) => col.name === _col.name) || {};
      return Object.assign({}, ref, col);
    });
    this.generateFilters(this.columns, this.rows);
  }
  @Input()
  set rows(rows) {
    this._rows = rows;
    this.generateFilters(this.columns, rows);
  }
  get rows() {
    let activeRows = this._rows;
    if (this.searchVal) {
      activeRows = activeRows.filter((row) => {
        // Object.values still not working for some browsers
        const rowMeta = Object.keys(row).map((key) => row[key]).join(' ').toLowerCase();
        const shouldFind = this.searchVal.toLowerCase().split(' ');
        return shouldFind.some((str) => rowMeta.indexOf(str) !== -1);
      });
    }
    if (this.activeFilters) {
      const testing = activeRows.filter((row) => {
        return Object.keys(this.activeFilters).every((filterKey: string) => {
          const filterVal = this.activeFilters[filterKey];
          if (!filterVal) {
            return true;
          }
          // roles; {guest: null; user: null, admin: null}; ["user"];
          // filterKey; filterVal; row[filterKey];
          return Object.keys(filterVal).every((key) => {
            if (!filterVal[key]) {
              return true;
            }
            return row[filterKey].indexOf(key) !== -1;
          });
        });
      });
      activeRows = testing;
    }
    return activeRows;
  }

  get columns() {
    return this._columns;
  }

  @Input()
  set pageSize(pageSize) {
    this.fakePageSizeModel = pageSize;
    this._pageSize = pageSize;
  }
  get pageSize() {
    return this._pageSize;
  }

  get activeFilters() {
    return this.filterForm && this.filterForm.value || {};
  }

  get fullClass() {
    const classes = ['ngx-datatable', this.class];
    if (this.scrollbarV) {
      classes.push('scrollbarV');
    }
    if (this.scrollbarH) {
      classes.push('scrollbarH');
    }
    return classes.join(' ');
  }

  private _config: any = {};
  private _searchChanged = new EventEmitter<string>();

  private _selected = [];
  private _actionButtons = [];
  private _columns = [];
  private _rows = [];
  private _pageSize = 10;
  private _lastSelected: any;

  @Input() displayCheck = () => true;

  constructor(private ref: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.fakePageSizeModel = this.pageSize;
    this.loading = false;
    this.ref.detectChanges();
    this._searchChanged.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe((searchVal) => {
      this.updateSearch(searchVal);
    });
  }

  formatCellValue(value, type) {
    return FormatHelper.Format(value, type);
  }

  activeFiltersByGroup(group) {
    if (!group) {
      return 0;
    }
    return Object.keys(this.activeFilters[group]).filter((key) => this.activeFilters[group][key]).length;
  }

  clearFilters(filter?: any) {
    if (!filter || !filter.name) {
      this.filterForm.reset();
      return;
    }
    this.filterForm.controls[filter.name].reset();
  }

  filterValue(filter, filterValue) {
    if (!this.activeFilters[filter]) {
      return null;
    }
    return this.activeFilters[filter][filterValue];
  }

  toggleAdvSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  activate(action) {
    this.userActions.emit(action);
  }

  reset() {
    this.selected = [];
    this.searchVal = '';
    this.updatePage(1);
    this.pageSize = 10;
  }

  buttonClicked(button, row) {
    this.buttonClick.emit({ button, row });
  }

  updatePageSize(pageSize) {
    this.pageSize = pageSize;
    this.ref.detectChanges();
  }

  updatePage(page) {
    this.activePage = page;
    this.pageUpdated.emit(this.activePage);
  }

  emitSearch(value) {
    if (value === undefined) {
      return;
    }
    this._searchChanged.emit(value);
  }

  updateSearch(searchVal: string) {
    this.searchVal = searchVal;
    this.ref.detectChanges();
  }

  onSelect(event) {
    this.select.emit(event);
    if (this.preventSelectEvent) {
      return;
    }
    if (this.selectionType === 'single') {
      return this.handleSingleSelect(event);
    }
    if (this.selectionType === 'multiClick') {
      return this.handleMultiSelect(event);
    }
    this.selected = event.selected;
    this.ref.detectChanges();
  }

  private handleSingleSelect(event) {
    if (this.selectionType !== 'single') {
      return;
    }
    if (this._lastSelected !== JSON.stringify(event.selected)) {
      this.selected = event.selected;
      this._lastSelected = JSON.stringify(event.selected);
      return;
    }
    this.selected = [];
    this._lastSelected = null;
    this.ref.detectChanges();
  }

  private handleMultiSelect(event) {
    if (this.selectionType !== 'multiClick') {
      return;
    }
    if (!this.selectionLimit || event.selected.length <= this.selectionLimit) {
      return;
    }
    if (!this.selected || !event.selected) {
      return;
    }
    const active = this.selected.filter((val) => !!val);
    const allButNotLast = active.slice(0, active.length - 2);
    this.selected = [...allButNotLast, event.selected[event.selected.length - 1]];
    this.ref.detectChanges();
  }

  private generateFilters(columns, rows) {
    if (!rows || !columns || !rows.length || !columns.length) {
      return;
    }
    const formGroup = {};
    this.availableFilters = columns
      .filter((col) => col.filters && col.filters.length)
      .map((col) => {
        const formControls = {};
        col.filters.map((filter) => formControls[filter.name] = new FormControl());
        formGroup[col.name] = new FormGroup(formControls);
        return {
          name: col.name,
          title: col.title,
          filters: col.filters,
        };
      });
    this.filterForm = new FormGroup(formGroup);
    this.sorts = this._config.availableColumns
      .filter((col) => col.sort)
      .map((col) => ({ prop: col.name, dir: col.sort }));
  }
}
