// Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormViewerModule } from './form-viewer.module';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Components
import { BootstrapComponent } from '../components/bootstrap/bootstrap.component';
import { NGXBootstrapModule } from './ngx-bootstrap.module';
import { DGSNotificationService, IDGSNotification } from '@dotgov/core';
import { RouterModule } from '@angular/router';

const toastrDefaultOptions = {
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
  enableHtml: true,
};

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@NgModule({
  declarations: [
    BootstrapComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '**', component: BootstrapComponent },
    ]),
    FormViewerModule.forRoot({ apiUrl: 'https://epermits.dotgov.md/api/' }),
    ToastrModule.forRoot(toastrDefaultOptions),
    NGXBootstrapModule.forRoot(),
  ],
  providers: [],
  bootstrap: [BootstrapComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DevFormviewerModule {
  constructor(
    private dgsNotification: DGSNotificationService,
    private notification: ToastrService,
  ) {
    this.dgsNotification.subscription((notify: IDGSNotification) => {
      const allowedTypes = ['error', 'info', 'success', 'warning'];
      if (allowedTypes.indexOf(notify.type) !== -1) {
        this.notification[notify.type](notify.message, notify.title);
      }
    });
  }
}
