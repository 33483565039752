import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { Helper } from '../components/shared/helper';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'div[movable]',
})
export class FVMovableDirective {
  private _mouseDown = false;
  private _mouseDownData: { [key: string]: number } = { x: 0, y: 0, newX: 0, newY: 0 };
  private element;

  @Input() onlySelf: boolean;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  get target(): HTMLElement {
    const original = this.element && this.element.parentNode && this.element.parentNode.parentNode;
    const target = Helper.parentContainer(original, (el) => el.className.indexOf('modal-content') !== -1);
    return target || original;
  }

  @HostListener('document:mousemove', ['$event'])
  mouseMove(event) {
    if (!this._mouseDown) {
      return;
    }
    const { clientX, clientY } = event;
    const mouseData = this._mouseDownData = {
      x: this._mouseDownData.newX - clientX,
      y: this._mouseDownData.newY - clientY,
      newX: clientX,
      newY: clientY,
    };
    const target = this.target;
    this.updateElement(target, 'none', target.offsetTop - mouseData.y, target.offsetLeft - mouseData.x);
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(event) {
    this.end();

    if (this.onlySelf && event.target !== this.element) {
      return;
    }
    this._mouseDownData = { newX: event.clientX, newY: event.clientY };
    this._mouseDown = true;
  }

  @HostListener('document:mouseleave')
  mouseLeave() {
    this.end();
  }

  @HostListener('document:mouseup')
  mouseup() {
    this.end();
  }

  private updateElement(target, userSelect = 'auto', top?: number, left?: number) {
    if (top) {
      target.style.top = `${top}px`;
    }
    if (left) {
      target.style.left = `${left}px`;
    }
    target.style.userSelect = userSelect;
  }

  private end() {
    this.updateElement(this.target, 'auto');
    this._mouseDown = false;
  }
}
