import { Component, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ControlType, Control, Column } from '../../../models/control';
import { FVDoneResponse } from '../../../models';
import { ProcessService } from '../../../services/process.service';
import { FVAbstractControl } from '../../shared/abstract-control.component';
import { IDGSChangeSet } from '@dotgov/core';
import { Helper } from '../../shared/helper';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-form-tab',
  templateUrl: './form-tab.component.html',
  styleUrls: ['./form-tab.component.css', '../controls/controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVFormTabComponent extends FVAbstractControl implements AfterViewInit, OnDestroy {
  class: string;

  @Output() titleUpdated: EventEmitter<string> = new EventEmitter();
  @Output() onDone: EventEmitter<FVDoneResponse> = new EventEmitter();
  @ViewChild('mainContainer') mainContainer;

  protected shouldShow = true;

  constructor(
    protected process: ProcessService,
    protected ref: ChangeDetectorRef,
  ) {
    super(process);

    this.subscriptions.push(this.process.onChange.subscribe((changeSet: IDGSChangeSet) => {
      const refName = this.control && (this.control.referenceName || this.control.sectionName);
      if (!refName) {
        return;
      }
      if (changeSet.changeName === `show-${refName}`) {
        this.shouldShow = !!changeSet.changeVal;
        this.class = this.generateClass();
      }
      const isDetectTarget = changeSet.changeVal === refName;
      if (changeSet.changeName === 'detectChanges' && isDetectTarget) {
        this.ref.detectChanges();
      }
    }));
  }

  ngAfterViewInit() {
    this.class = this.generateClass();
    this.ref.detectChanges();
    if (this.control && ControlType.controlType(this.control.controlType) === 'SectionLayout') {
      this.process.set(`section-${this.control.sectionName}`, this.control, this.task && this.task.taskId || this.task.id);
    }
  }

  updateTitle(title) {
    this.titleUpdated.emit(title);
  }

  controlType(num: number): string {
    return ControlType.controlType(num);
  }

  controlGroupType(num: number): string {
    return ControlType.controlGroupType(num);
  }

  columnsClass(control: Control) {
    const columnsCount = this.notEmptyColumns(control).length;
    return `columns-${columnsCount}`;
  }

  visibleColumns(control: Control | Column): Column[] {
    const columns = this.notEmptyColumns(control);
    return columns;
  }

  isHidden(control: Control): boolean {
    return !this.shouldShow || control.isHidden || ControlType.controlType(control.controlType) === 'HiddenField';
  }

  /**
   * Redirect event to parent component.
   * @param response
   */
  _onDone(response: FVDoneResponse) {
    this.onDone.emit(response);
  }

  /**
   * Returns debug text for current component.
   */
  toString(): string {
    const sectionName = this.control.sectionName;
    if (sectionName) {
      return `sectionName: ${sectionName}
      ${super.toString()}`;
    }
    return super.toString();
  }

  /**
   * Extend from debugger.ts. Check if you should see message on missing fields found. 
   */
  get missingFieldsDebug(): boolean {
    return this._missingFieldsDebug;
  }

  get isGrid(): boolean {
    return this.task.hasOwnProperty('defaultNode');
  }

  /**
   * Return all non-empty columns inside given one.
   * @param control
   */
  protected notEmptyColumns(control: Control | Column): Column[] {
    return (control['columns'] || []).filter((item) => {
      if (!item.controls.length) {
        return false;
      }
      let controls = [];
      if (item.controls) {
        controls = item.controls.filter((_control) => Helper.haveVisibles(_control));
      }
      return controls.length ? controls : false;
    });
  }

  protected generateClass(): string {
    return this.shouldShow && Helper.haveVisibles(this.control) && this.isPopulated() ? 'formTab' : '';
  }

  /**
   * Returns if container have any text ( not only comments ).
   */
  protected isPopulated(): boolean {
    return !!this.mainContainer && this.mainContainer.nativeElement.innerText;
  }

  ngOnDestroy() {
    super.OnDestroy(this.ref);
  }
}
