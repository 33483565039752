import { DockItem } from './dockItem';
import { Dock } from './dock';

export interface GridSort extends Object {
  property: string;
  direction: string;
}

export interface GridFilter extends Object {
  property: string;
  value: string;
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class GridColumn {
  constructor(
    public align?: string,
    public dataIndex?: string,
    public flex?: number,
    public hidden?: boolean,
    public text?: string,
    public type?: string,
    public width?: number,
  ) { }
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Store {
  constructor(
    public autoLoad?: boolean,
    public formFilter?: any[],
    public passColumns?: { key: string, operator: any, value: string | number }[],
    public proxy?: { url: string, multilang: boolean },
    public remoteFilter?: boolean,
    public remoteSort?: boolean,
    public title?: string,
  ) { }
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class GridComponentConfig {
  constructor(
    public columns?: GridColumn[],
    public store?: Store,
    public dockedItems?: Dock[],
    public idFilterProperty?: string,
    public model?: any,
    public predefinedFilter?: GridFilter[],
    public title?: string,
    public editHandlers?: EditHandlers,
  ) { }
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class EditHandlers {
  constructor(
    public autoNewRow?: boolean,
    public allowInlineEdit?: boolean,
    public addAction?: DockItem,
    public deleteAction?: DockItem,
    public editAction?: DockItem,
    public selectActionRo?: any,
  ) { }
}
