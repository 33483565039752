import { Field } from './field';
import { Proxy } from './proxy';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Model {
  constructor(
    public autoLoad?: boolean,
    public entityName?: string,
    public idProperty?: string,
    public fields?: Field[],
    public proxy?: Proxy,
  ) { }
}
