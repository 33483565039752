import { GridComponentConfig } from './gridComponentconfig';
import { Control } from './control';
import { Field } from './field';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class GridConfig {
  constructor(
    public componentClassName?: string,
    public componentConfig?: GridComponentConfig,
    public id?: string,
    public filters?: Object[],
    public parent?: Control,
    public model?: Field,
    public editable?: boolean,
    public hideFilter?: boolean,
    public notClickable?: boolean,
    public addAction?: boolean,
    public editAction?: boolean,
    public deleteAction?: boolean,
    public activePage?: number,
  ) { }
}
