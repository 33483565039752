// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Georgian [ka]
//! author : Irakli Janiashvili : https://github.com/irakli-janiashvili
//! author : Levan Tskipuri : https://github.com/tskipa

export const kaLocale: LocaleData =  {
  abbr: 'ka',
  months : {
    format: 'áááááá á¡_ááááá áááá¡_ááá á¢á¡_ááá áááá¡_áááá¡á¡_ááááá¡á¡_ááááá¡á¡_ááááá¡á¢á¡_á¡áá¥á¢ááááá á¡_áá¥á¢ááááá á¡_ááááááá á¡_áááááááá á¡'.split('_'),
    standalone: 'áááááá á_ááááá áááá_ááá á¢á_ááá ááá_áááá¡á_ááááá¡á_ááááá¡á_ááááá¡á¢á_á¡áá¥á¢ááááá á_áá¥á¢ááááá á_ááááááá á_áááááááá á'.split('_')
  },
  monthsShort : 'ááá_ááá_ááá _ááá _ááá_ááá_ááá_ááá_á¡áá¥_áá¥á¢_ááá_ááá'.split('_'),
  weekdays : {
    standalone: 'áááá á_áá á¨ááááá_á¡ááá¨ááááá_ááá®á¨ááááá_á®á£áá¨ááááá_ááá áá¡áááá_á¨ááááá'.split('_'),
    format: 'áááá áá¡_áá á¨ááááá¡_á¡ááá¨ááááá¡_ááá®á¨ááááá¡_á®á£áá¨ááááá¡_ááá áá¡áááá¡_á¨ááááá¡'.split('_'),
    isFormat: /(á¬ááá|á¨ááááá)/
  },
  weekdaysShort : 'ááá_áá á¨_á¡áá_ááá®_á®á£á_ááá _á¨áá'.split('_'),
  weekdaysMin : 'áá_áá _á¡á_áá_á®á£_áá_á¨á'.split('_'),
  longDateFormat : {
    LT : 'h:mm A',
    LTS : 'h:mm:ss A',
    L : 'DD/MM/YYYY',
    LL : 'D MMMM YYYY',
    LLL : 'D MMMM YYYY h:mm A',
    LLLL : 'dddd, D MMMM YYYY h:mm A'
  },
  calendar : {
    sameDay : '[áá¦áá¡] LT[-áá]',
    nextDay : '[á®ááá] LT[-áá]',
    lastDay : '[áá£á¨áá] LT[-áá]',
    nextWeek : '[á¨ááááá] dddd LT[-áá]',
    lastWeek : '[á¬ááá] dddd LT-áá',
    sameElse : 'L'
  },
  relativeTime : {
    future(s: number): string {
      var st = s.toString();
      return (/(á¬ááá|á¬á£áá|á¡áááá|á¬ááá)/).test(st) ?
      st.replace(/á$/, 'á¨á') :
      st + 'á¨á';
    },
    past(s: number): string {
      var st = s.toString();
      if ((/(á¬ááá|á¬á£áá|á¡áááá|áá¦á|ááá)/).test(st)) {
        return st.replace(/(á|á)$/, 'áá¡ á¬áá');
      }
      if ((/á¬ááá/).test(st)) {
        return st.replace(/á¬ááá$/, 'á¬ááá¡ á¬áá');
      }
    },
    s : 'á áááááááá á¬ááá',
    ss : '%d á¬ááá',
    m : 'á¬á£áá',
    mm : '%d á¬á£áá',
    h : 'á¡áááá',
    hh : '%d á¡áááá',
    d : 'áá¦á',
    dd : '%d áá¦á',
    M : 'ááá',
    MM : '%d ááá',
    y : 'á¬ááá',
    yy : '%d á¬ááá'
  },
  dayOfMonthOrdinalParse: /0|1-áá|áá-\d{1,2}|\d{1,2}-á/,
  ordinal(_num: number, _period: string): string {
    const num = Number(_num);
    if (num === 0) {
      return num.toString();
    }
    if (num === 1) {
      return num + '-áá';
    }
    if ((num < 20) || (num <= 100 && (num % 20 === 0)) || (num % 100 === 0)) {
      return 'áá-' + num;
    }
    return num + '-á';
  },
  week : {
    dow : 1,
    doy : 4
  }
};
