import { Injectable, EventEmitter } from '@angular/core';

export interface IDGSNotification {
  type: string;
  message: string;
  title: string;
}

@Injectable()
export class DGSNotificationService {
  private notifyEvent: EventEmitter<any> = new EventEmitter();

  error(message: string, title?: string) {
    this.emit({ type: 'error', message, title });
  }

  info(message: string, title?: string) {
    this.emit({ type: 'info', message, title });
  }

  success(message: string, title?: string) {
    this.emit({ type: 'success', message, title });
  }

  warning(message: string, title?: string) {
    this.emit({ type: 'warning', message, title });
  }

  subscription(cb) {
    return this.notifyEvent.subscribe(cb);
  }

  private emit(data) {
    this.notifyEvent.emit(data);
  }
}
