import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DGSLoadingComponent } from '../components/loading/loading.component';
import { NumberFiledsValidatorDirective } from '../../lib/directives/number-field.directive';

const components = [
  DGSLoadingComponent,
];

@NgModule({
  declarations: [
    ...components,
    NumberFiledsValidatorDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ...components,
    NumberFiledsValidatorDirective,
  ],
  providers: [],
})
export class DGSUICoreModule { }