import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'ltranslate', pure: false })
export class DGSLowerTranslatePipe implements PipeTransform {
  private translatePipe: TranslatePipe;
  constructor(protected translate: TranslateService, private _ref: ChangeDetectorRef) {
    this.translatePipe = new TranslatePipe(this.translate, this._ref);
  }

  transform(value: string = '', ...args: any[]): any {
    const isString = typeof value === 'string';
    let newValue = value;
    if (isString) {
      newValue = value.toLowerCase() || ' ';
    }
    const translation = this.translatePipe.transform(newValue, ...args);
    if (translation === newValue && isString) {
      return value;
    }
    return translation;
  }
}
