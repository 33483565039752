import { Component, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';
import { ControlType } from '../../../../models/control';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsNumberFieldComponent extends FVAbstractControl implements AfterViewInit {
  constructor(process: ProcessService) {
    super(process);
  }

  get controlType(): string {
    return ControlType.controlType(this.control.controlType);
  }

  ngAfterViewInit() {
    super.saveKey();

    let value = this.recordData[this.control.referenceName];
    const precision = this.control.precision;
    const showAs = ControlType.controlType(this.control.showAs || 122);
    if (!value || isNaN(parseInt(value, 10))) {
      return;
    }
    if ((showAs === 'MoneyField' || this.controlType === 'MoneyField') && precision === undefined || precision === null) {
      value = this.toDecimal(value, 2);
    } else if (precision) {
      value = this.toDecimal(value, precision);
    }
    super.setValue(value);
  }

  /**
   * Converts string into decimal with given {precision}.
   * @param num
   * @param precision
   */
  private toDecimal(num: number | string, precision: number): string {
    if (num !== 0 && !num) {
      return null;
    }
    return parseFloat(`${Math.round(num as any * 100) / 100}`).toFixed(precision);
  }

}
