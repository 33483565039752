// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return
// tslint:disable:object-literal-shorthand

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Korean [ko]
//! author : Kyungwook, Park : https://github.com/kyungw00k
//! author : Jeeeyul Lee <jeeeyul@gmail.com>

export const koLocale: LocaleData = {
  abbr: 'ko',
  months : '1ì_2ì_3ì_4ì_5ì_6ì_7ì_8ì_9ì_10ì_11ì_12ì'.split('_'),
  monthsShort : '1ì_2ì_3ì_4ì_5ì_6ì_7ì_8ì_9ì_10ì_11ì_12ì'.split('_'),
  weekdays : 'ì¼ìì¼_ììì¼_íìì¼_ììì¼_ëª©ìì¼_ê¸ìì¼_í ìì¼'.split('_'),
  weekdaysShort : 'ì¼_ì_í_ì_ëª©_ê¸_í '.split('_'),
  weekdaysMin : 'ì¼_ì_í_ì_ëª©_ê¸_í '.split('_'),
  longDateFormat : {
    LT : 'A h:mm',
    LTS : 'A h:mm:ss',
    L : 'YYYY.MM.DD',
    LL : 'YYYYë MMMM Dì¼',
    LLL : 'YYYYë MMMM Dì¼ A h:mm',
    LLLL : 'YYYYë MMMM Dì¼ dddd A h:mm',
    l : 'YYYY.MM.DD',
    ll : 'YYYYë MMMM Dì¼',
    lll : 'YYYYë MMMM Dì¼ A h:mm',
    llll : 'YYYYë MMMM Dì¼ dddd A h:mm'
  },
  calendar : {
    sameDay : 'ì¤ë LT',
    nextDay : 'ë´ì¼ LT',
    nextWeek : 'dddd LT',
    lastDay : 'ì´ì  LT',
    lastWeek : 'ì§ëì£¼ dddd LT',
    sameElse : 'L'
  },
  relativeTime : {
    future : '%s í',
    past : '%s ì ',
    s : 'ëª ì´',
    ss : '%dì´',
    m : '1ë¶',
    mm : '%dë¶',
    h : 'í ìê°',
    hh : '%dìê°',
    d : 'íë£¨',
    dd : '%dì¼',
    M : 'í ë¬',
    MM : '%dë¬',
    y : 'ì¼ ë',
    yy : '%dë'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(ì¼|ì|ì£¼)/,
  ordinal : function (num: number, period: string): string {
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
        return num + 'ì¼';
      case 'M':
        return num + 'ì';
      case 'w':
      case 'W':
        return num + 'ì£¼';
      default:
        return num.toString(10);
    }
  },
  meridiemParse : /ì¤ì |ì¤í/,
  isPM : function (token) {
    return token === 'ì¤í';
  },
  meridiem : function (hour, minute, isUpper) {
    return hour < 12 ? 'ì¤ì ' : 'ì¤í';
  }
};
