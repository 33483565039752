import { Control } from './../../models/control';
import { FormGroup } from '@angular/forms';
import { ProcessService } from '../../services/process.service';
import { ValidationService } from '../../services/validation.service';
import { Task, Field, ControlType } from '../../models';
import { Helper as DGSHelper, ApiService } from '@dotgov/core';
import { ObjectLiteral } from '../../models/objectLiteral';

/**
 * Extends Helepr for dotgov/core.
 * Static methods only.
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Helper extends DGSHelper {
  /**
   * Extracts all errors from form-group.
   * Changes the flags on the way.
   * @param _group
   * @param _process
   * @param _validation
   */
  static getErrors(_group: FormGroup, _process: ProcessService, _validation: ValidationService): Promise<any[]> {
    const promises = [];
    Object.keys(_group.controls).forEach((key) => {
      const control = _group.controls[key];
      if (!control || !control.errors) {
        return;
      }
      Object.keys(control.errors).forEach((errorKey) => {
        const controlRef = _process.get(key);
        _group.controls[key].markAsDirty();
        _group.controls[key].markAsTouched();
        _validation.update(true);
        promises.push(_validation.getError(errorKey, controlRef.label, controlRef.controlName, controlRef));
      });
    });
    return Promise.all(promises);
  }

  /**
   * Finds control by {refName} inside {task}.
   * @param refName
   * @param task
   */
  static controlRef(refName: string, task: Task): Control {
    const controls = task.componentConfig.controls;
    if (!controls) {
      return;
    }
    return controls.find((field: Field) => field.referenceName === refName);
  }

  /**
   * Generates fromData object based on given {group} + {process} + {task}.
   * @param _group
   * @param _process
   * @param _task
   */
  static getFormData(_group: FormGroup, _process: ProcessService, _task: Task): ObjectLiteral {
    const formControls = _group.value;
    const finalObj = {};
    Object.keys(formControls).forEach((key) => {
      const processValue = _process.get(`${key}-value`);
      const referenceControl = _process.get(key);
      // Remove controls from another contexts
      if (!referenceControl || !Helper.controlRef(referenceControl.referenceName, _task)) {
        return;
      }
      const multi = referenceControl.showAs && referenceControl.showAs === ControlType.EFormElement.MultiLookup;
      const isLookup = ControlType.controlType(referenceControl.controlType) === 'Lookup';
      const isFileInput = ControlType.controlType(referenceControl.controlType) === 'UploadFile';
      let value = ((isLookup && !multi) || isFileInput) ? processValue : formControls[key];
      const refLook = _process.get(`${referenceControl.referenceName}-value`);
      if (refLook && !value) {
        value = refLook;
      }
      if (!Helper.validValue(value) || !referenceControl) {
        return;
      }
      const controlType = ControlType.controlType(referenceControl.controlType);
      // Thats grid, must not be sent.
      if (referenceControl.controlType === ControlType.EFormElement.EditableGrid) {
        return;
      }
      // If is date, format it
      if (ControlType.isDateType(referenceControl.controlType)) {
        const showTime = controlType === 'DateTime';
        // If string than for sure day is first.
        const finalDate = typeof value === 'string' ? Helper.stringToDate(value, true) : value;
        value = Helper.formatDate(finalDate, showTime);
      }
      // If its dropdown, format it
      if (controlType === 'ListField') {
        if (Array.isArray(value)) {
          if (value.length === 1) {
            value = value[0];
          } else if (!value.length) {
            value = null;
          }
        }
      }
      if (isLookup) {
        value = Helper.sanitizeLookupValue(value, referenceControl);
      }
      if (typeof value === 'string') {
        value = value.trim();
      }
      finalObj[referenceControl['referenceName']] = value;
    });

    return finalObj;
  }

  /**
   * Transform lookup value to API required format.
   * @param value
   * @param control
   */
  static sanitizeLookupValue(value: any, control: Control): any[] | any {
    const isMulti = control.showAs === ControlType.EFormElement.MultiLookup;
    if (isMulti && value && !Array.isArray(value)) {
      return Object.keys(value).map((id) => {
        return id;
      });
    }
    return value;
  }

  /**
   * Removes dublicates from array.
   * Not using ...new Set(arr) because of --downlevelLiterals.
   * @param arr
   */
  static removeDublicates(arr: any) {
    const result = arr.filter((el, i, a) => i === a.indexOf(el));
    return [result];
  }

  /**
   * Extract child controls to {controls} from given {controls};
   * Using reference variable for easier flat-style resulted array.
   * @param controls
   * @param control
   */
  static getChildControls(controls, control) {
    if (!control) {
      return;
    }
    if (control.columns) {
      controls.push(...control.columns.map((item) => Helper.getChildControls(controls, item)));
    } else if (control.controls) {
      controls.push(...control.controls.map((item) => Helper.getChildControls(controls, item)));
    } else {
      controls.push(control);
    }
  }

  /**
   * Check whatever given {control} has visible child controls or not.
   * @param control
   */
  static haveVisibles(control): boolean {
    const controls = [];
    Helper.getChildControls(controls, control);
    const items = controls.filter(item => !!item);
    if (!items.length) {
      return false;
    }
    return items.some((_control: Control) => {
      const controlType = ControlType.controlType(_control.controlType);
      let shouldRender = ControlType.designFields.indexOf(controlType) === -1;
      if (!shouldRender && (controlType === 'CustomControl' || controlType === 'HtmlContent')) {
        shouldRender = true;
      }
      return !!(shouldRender && !_control.isHidden);
    });
  }

  /**
   * Triggers file download
   * @param api
   * @param rowData
   */
  static async downloadFile(api: ApiService, rowData: any) {
    const fileData = await api.get(rowData.url, [], {}, { skipCache: true, responseType: 'blob' });
    if (fileData.error) {
      return;
    }
    const fileUrl = URL.createObjectURL(fileData.data);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = rowData.fileName;
    a.innerHTML = 'download file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * Parse {container} and find closest body/[role] parrent ( will be updated by {customRule} if send ).
   * @param container
   * @param customRule Overrides default match behaviour. Still match default if override fails.
   */
  static parentContainer(container, customRule?: (el: HTMLElement) => {}) {
    let parent = container.nativeElement || container;
    let lastChild = parent;
    while (parent) {
      const customMatched = customRule && customRule(lastChild);
      const defaultMatched = !customMatched && lastChild && lastChild.localName === 'body' || lastChild.attributes['role'];
      if (customMatched || defaultMatched) {
        parent = undefined;
        return lastChild;
      }
      lastChild = parent;
      parent = parent.parentNode;
    }
    return lastChild;
  }

}
