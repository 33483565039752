import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SelectModule } from 'ng-select';
import { DGSCoreModule, IDGSEnvironment, DGSUICoreModule } from '@dotgov/core';

import { ProcessService } from '../services/process.service';
import { LanguageService } from '../services/language.service';
import { TranspilationService } from '../services/transpilation.service';
import { ValidationService } from '../services/validation.service';

import { FVModalComponent } from '../components/renderer/modal/modal.component';
import { FVRendererComponent } from '../components/renderer/form-renderer.component';
import { FVFormTabComponent } from '../components/renderer/FormTab/form-tab.component';
import { FVControlsListFieldComponent } from '../components/renderer/controls/ListField/list-field.component';
import { FVControlsLookupComponent } from '../components/renderer/controls/Lookup/lookup.component';
import { FVControlsTextFieldComponent } from '../components/renderer/controls/TextField/text-field.component';
import { FVNavigatorComponent } from '../components/renderer/navigator/navigator.component';
import { FVControlsDateComponent } from '../components/renderer/controls/Date/date.component';
import { FVControlsDateTimeComponent } from '../components/renderer/controls/DateTime/datetime.component';
import { FVControlsGridComponent } from '../components/renderer/Grid/grid.component';
import { FVControlsEditableGridComponent } from '../components/renderer/controls/EditableGrid/editable-grid.component';
import { FVControlsHtmlContentComponent } from '../components/renderer/controls/HtmlContent/html-content.component';
import { FVControlsCustomControlComponent } from '../components/renderer/controls/CustomControl/custom-control.component';
import { FVControlsCheckBoxComponent } from '../components/renderer/controls/CheckBox/check-box.component';
import { FVControlsNumberFieldComponent } from '../components/renderer/controls/NumberField/number-field.component';
import { FVControlsPhoneFieldComponent } from '../components/renderer/controls/PhoneField/phone-field.component';
import { FVControlsUploadFileComponent } from '../components/renderer/controls/upload-file/upload-file.component';
import { FVControlsProgressBarComponent } from '../components/renderer/controls/ProgressBar/progress-bar.component';
import { FVControlsRadioButtonComponent } from '../components/renderer/controls/RadioButton/radio-button.component';
import { FVControlsValidatorComponent } from '../components/renderer/controls/Validator/validator.component';
import { FVControlsMenuGridComponent } from '../components/renderer/MenuGrid/menu-grid.component';
import { FVControlsMenuComponent } from '../components/renderer/menu/menu.component';
import { FVMenuFolderComponent } from '../components/renderer/menu/folder/menu-folder.component';
import { FVControlsToggleComponent } from '../components/renderer/controls/toggle/toggle.component';
import { FVFormBuilderService } from '../services/formBuilder.service';
import { MultiLookupValidatorDirective } from '../directives/multilookup-validator.directive';
import { NGXBootstrapModule } from './ngx-bootstrap.module';
import { FVMovableDirective } from '../directives/draggable.directive';
import { NumberMaxLengthDirective } from '../directives/number-max-length.directive';
import { EscapeSpecialSymbolsDirective } from '../directives/escape-special-symbols.directive';


const components = [
  FVRendererComponent,
  FVFormTabComponent,
  FVControlsListFieldComponent,
  FVControlsLookupComponent,
  FVControlsTextFieldComponent,
  FVNavigatorComponent,
  FVControlsDateComponent,
  FVControlsDateTimeComponent,
  FVControlsGridComponent,
  FVControlsEditableGridComponent,
  FVControlsHtmlContentComponent,
  FVControlsCustomControlComponent,
  FVControlsCheckBoxComponent,
  FVControlsNumberFieldComponent,
  FVControlsPhoneFieldComponent,
  FVControlsUploadFileComponent,
  FVControlsProgressBarComponent,
  FVControlsRadioButtonComponent,
  FVModalComponent,
  FVControlsValidatorComponent,
  FVControlsMenuGridComponent,
  FVControlsMenuComponent,
  FVMenuFolderComponent,
  FVControlsToggleComponent,
  MultiLookupValidatorDirective,
  FVMovableDirective,
  NumberMaxLengthDirective,
  EscapeSpecialSymbolsDirective,
];

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    TranslateModule.forRoot(),
    DGSCoreModule.forRoot(null),
    DGSUICoreModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    NGXBootstrapModule.forRoot(),
  ],
  exports: [
    ...components,
  ],
  providers: [
    ProcessService,
    LanguageService,
    ValidationService,
    TranspilationService,
    FVFormBuilderService,
    MultiLookupValidatorDirective,
    FVMovableDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormViewerModule {
  constructor() {
    if (typeof window['clearFrmGlobals'] !== 'function') {
      window['clearFrmGlobals'] = () => { window['frmGlobals'] = {}; };
    }
  }

  public static forRoot(environment: IDGSEnvironment, componentFactories: any = {}): ModuleWithProviders<FormViewerModule> {
    return {
      ngModule: FormViewerModule,
      providers: [
        ProcessService,
        LanguageService,
        FVFormBuilderService,
        FVMovableDirective,
        { provide: 'environment', useValue: environment },
        { provide: 'componentFactories', useValue: componentFactories}
      ],
    };
  }
}
