import { Directive, OnInit, ElementRef, Input } from "@angular/core";

@Directive({
  selector: `[type='number']`,
})
export class NumberFiledsValidatorDirective implements OnInit {
  @Input() noValidate: boolean = false;
  private elemenet: HTMLInputElement;
  constructor(elRef: ElementRef) {
    this.elemenet = elRef.nativeElement;
  }

  ngOnInit() {
    const restricted = [
      69,   //  "e"
      187,  //  "="
      188,  //  ","
      189,  //  "-"
    ];
    this.elemenet.addEventListener('keydown', (event) => {
      if (this.noValidate) {
        return;
      }
      if (restricted.indexOf(event.keyCode) !== -1) {
        event.preventDefault();
      }
    });
    this.elemenet.addEventListener('paste', (event) => {
      setTimeout(() => {
        if (this.noValidate || !this || !event || !event.target || !event.target['value']) {
          return;
        }
        if (!event.target['value']) {
          event.target['value'] = '';
        }
      });
    });
  }
}
