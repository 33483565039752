// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';
import { getDayOfWeek } from '../units/day-of-week';

//! moment.js locale configuration
//! locale : Hungarian [hu]
//! author : Adam Brunner : https://github.com/adambrunner

let weekEndings = 'vasÃ¡rnap hÃ©tfÅn kedden szerdÃ¡n csÃ¼tÃ¶rtÃ¶kÃ¶n pÃ©nteken szombaton'.split(' ');
function translate(num: number, withoutSuffix: boolean, key: string, isFuture: boolean): string {
  switch (key) {
    case 's':
      return (isFuture || withoutSuffix) ? 'nÃ©hÃ¡ny mÃ¡sodperc' : 'nÃ©hÃ¡ny mÃ¡sodperce';
    case 'ss':
      return num + ((isFuture || withoutSuffix) ? ' mÃ¡sodperc' : ' mÃ¡sodperce');
    case 'm':
      return 'egy' + (isFuture || withoutSuffix ? ' perc' : ' perce');
    case 'mm':
      return num + (isFuture || withoutSuffix ? ' perc' : ' perce');
    case 'h':
      return 'egy' + (isFuture || withoutSuffix ? ' Ã³ra' : ' Ã³rÃ¡ja');
    case 'hh':
      return num + (isFuture || withoutSuffix ? ' Ã³ra' : ' Ã³rÃ¡ja');
    case 'd':
      return 'egy' + (isFuture || withoutSuffix ? ' nap' : ' napja');
    case 'dd':
      return num + (isFuture || withoutSuffix ? ' nap' : ' napja');
    case 'M':
      return 'egy' + (isFuture || withoutSuffix ? ' hÃ³nap' : ' hÃ³napja');
    case 'MM':
      return num + (isFuture || withoutSuffix ? ' hÃ³nap' : ' hÃ³napja');
    case 'y':
      return 'egy' + (isFuture || withoutSuffix ? ' Ã©v' : ' Ã©ve');
    case 'yy':
      return num + (isFuture || withoutSuffix ? ' Ã©v' : ' Ã©ve');
  }
  return '';
}
function week(date: Date, isFuture: boolean) {
  return (isFuture ? '' : '[mÃºlt] ') + '[' + weekEndings[getDayOfWeek(date)] + '] LT[-kor]';
}

export const huLocale: LocaleData = {
  abbr: 'hu',
  months : 'januÃ¡r_februÃ¡r_mÃ¡rcius_Ã¡prilis_mÃ¡jus_jÃºnius_jÃºlius_augusztus_szeptember_oktÃ³ber_november_december'.split('_'),
  monthsShort : 'jan_feb_mÃ¡rc_Ã¡pr_mÃ¡j_jÃºn_jÃºl_aug_szept_okt_nov_dec'.split('_'),
  weekdays : 'vasÃ¡rnap_hÃ©tfÅ_kedd_szerda_csÃ¼tÃ¶rtÃ¶k_pÃ©ntek_szombat'.split('_'),
  weekdaysShort : 'vas_hÃ©t_kedd_sze_csÃ¼t_pÃ©n_szo'.split('_'),
  weekdaysMin : 'v_h_k_sze_cs_p_szo'.split('_'),
  longDateFormat : {
    LT : 'H:mm',
    LTS : 'H:mm:ss',
    L : 'YYYY.MM.DD.',
    LL : 'YYYY. MMMM D.',
    LLL : 'YYYY. MMMM D. H:mm',
    LLLL : 'YYYY. MMMM D., dddd H:mm'
  },
  meridiemParse: /de|du/i,
  isPM (input) {
    return input.charAt(1).toLowerCase() === 'u';
  },
  meridiem (hours, minutes, isLower) {
    if (hours < 12) {
      return isLower === true ? 'de' : 'DE';
    } else {
      return isLower === true ? 'du' : 'DU';
    }
  },
  calendar : {
    sameDay : '[ma] LT[-kor]',
    nextDay : '[holnap] LT[-kor]',
    nextWeek (date: Date) {
      return week(date, true);
    },
    lastDay : '[tegnap] LT[-kor]',
    lastWeek (date: Date) {
      return week(date, false);
    },
    sameElse : 'L'
  },
  relativeTime : {
    future : '%s mÃºlva',
    past : '%s',
    s : translate,
    ss : translate,
    m : translate,
    mm : translate,
    h : translate,
    hh : translate,
    d : translate,
    dd : translate,
    M : translate,
    MM : translate,
    y : translate,
    yy : translate
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal : '%d.',
  week : {
    dow : 1, // Monday is the first day of the week.
    doy : 4  // The week that contains Jan 4th is the first week of the year.
  }
};
