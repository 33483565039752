// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return
// tslint:disable:object-literal-key-quotes

import { LocaleData } from '../locale/locale.class';
import { getDayOfWeek } from '../units/day-of-week';

//! moment.js locale configuration
//! locale : Slovenian [sl]
//! author : mihan : https://github.com/mihan

function processRelativeTime(number: number, withoutSuffix: boolean, key: string, isFuture: boolean): string {
  var result = number + ' ';
  switch (key) {
    case 's':
      return withoutSuffix || isFuture ? 'nekaj sekund' : 'nekaj sekundami';
    case 'ss':
      if (number === 1) {
        result += withoutSuffix ? 'sekundo' : 'sekundi';
      } else if (number === 2) {
        result += withoutSuffix || isFuture ? 'sekundi' : 'sekundah';
      } else if (number < 5) {
        result += withoutSuffix || isFuture ? 'sekunde' : 'sekundah';
      } else {
        result += withoutSuffix || isFuture ? 'sekund' : 'sekund';
      }
      return result;
    case 'm':
      return withoutSuffix ? 'ena minuta' : 'eno minuto';
    case 'mm':
      if (number === 1) {
        result += withoutSuffix ? 'minuta' : 'minuto';
      } else if (number === 2) {
        result += withoutSuffix || isFuture ? 'minuti' : 'minutama';
      } else if (number < 5) {
        result += withoutSuffix || isFuture ? 'minute' : 'minutami';
      } else {
        result += withoutSuffix || isFuture ? 'minut' : 'minutami';
      }
      return result;
    case 'h':
      return withoutSuffix ? 'ena ura' : 'eno uro';
    case 'hh':
      if (number === 1) {
        result += withoutSuffix ? 'ura' : 'uro';
      } else if (number === 2) {
        result += withoutSuffix || isFuture ? 'uri' : 'urama';
      } else if (number < 5) {
        result += withoutSuffix || isFuture ? 'ure' : 'urami';
      } else {
        result += withoutSuffix || isFuture ? 'ur' : 'urami';
      }
      return result;
    case 'd':
      return withoutSuffix || isFuture ? 'en dan' : 'enim dnem';
    case 'dd':
      if (number === 1) {
        result += withoutSuffix || isFuture ? 'dan' : 'dnem';
      } else if (number === 2) {
        result += withoutSuffix || isFuture ? 'dni' : 'dnevoma';
      } else {
        result += withoutSuffix || isFuture ? 'dni' : 'dnevi';
      }
      return result;
    case 'M':
      return withoutSuffix || isFuture ? 'en mesec' : 'enim mesecem';
    case 'MM':
      if (number === 1) {
        result += withoutSuffix || isFuture ? 'mesec' : 'mesecem';
      } else if (number === 2) {
        result += withoutSuffix || isFuture ? 'meseca' : 'mesecema';
      } else if (number < 5) {
        result += withoutSuffix || isFuture ? 'mesece' : 'meseci';
      } else {
        result += withoutSuffix || isFuture ? 'mesecev' : 'meseci';
      }
      return result;
    case 'y':
      return withoutSuffix || isFuture ? 'eno leto' : 'enim letom';
    case 'yy':
      if (number === 1) {
        result += withoutSuffix || isFuture ? 'leto' : 'letom';
      } else if (number === 2) {
        result += withoutSuffix || isFuture ? 'leti' : 'letoma';
      } else if (number < 5) {
        result += withoutSuffix || isFuture ? 'leta' : 'leti';
      } else {
        result += withoutSuffix || isFuture ? 'let' : 'leti';
      }
      return result;
  }
}

export const slLocale: LocaleData = {
  abbr: 'sl',
  months: 'januar_februar_marec_april_maj_junij_julij_avgust_september_oktober_november_december'.split('_'),
  monthsShort: 'jan._feb._mar._apr._maj._jun._jul._avg._sep._okt._nov._dec.'.split('_'),
  monthsParseExact: true,
  weekdays: 'nedelja_ponedeljek_torek_sreda_Äetrtek_petek_sobota'.split('_'),
  weekdaysShort: 'ned._pon._tor._sre._Äet._pet._sob.'.split('_'),
  weekdaysMin: 'ne_po_to_sr_Äe_pe_so'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY H:mm',
    LLLL: 'dddd, D. MMMM YYYY H:mm'
  },
  calendar: {
    sameDay: '[danes ob] LT',
    nextDay: '[jutri ob] LT',

    nextWeek(date: Date) {
      switch (getDayOfWeek(date)) {
        case 0:
          return '[v] [nedeljo] [ob] LT';
        case 3:
          return '[v] [sredo] [ob] LT';
        case 6:
          return '[v] [soboto] [ob] LT';
        case 1:
        case 2:
        case 4:
        case 5:
          return '[v] dddd [ob] LT';
      }
    },
    lastDay: '[vÄeraj ob] LT',
    lastWeek(date: Date) {
      switch (getDayOfWeek(date)) {
        case 0:
          return '[prejÅ¡njo] [nedeljo] [ob] LT';
        case 3:
          return '[prejÅ¡njo] [sredo] [ob] LT';
        case 6:
          return '[prejÅ¡njo] [soboto] [ob] LT';
        case 1:
        case 2:
        case 4:
        case 5:
          return '[prejÅ¡nji] dddd [ob] LT';
      }
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'Äez %s',
    past: 'pred %s',
    s: processRelativeTime,
    ss: processRelativeTime,
    m: processRelativeTime,
    mm: processRelativeTime,
    h: processRelativeTime,
    hh: processRelativeTime,
    d: processRelativeTime,
    dd: processRelativeTime,
    M: processRelativeTime,
    MM: processRelativeTime,
    y: processRelativeTime,
    yy: processRelativeTime
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7  // The week that contains Jan 1st is the first week of the year.
  }
};
