import { Component, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';
import { ApiService, ApiResponse, DGSNotificationService, IDGSChangeSet } from '@dotgov/core';
import { ValidationService } from '../../../../services/validation.service';
import { GridConfig, DockItem, Dock, FVDoneResponse, GridColumn, Control } from '../../../../models';
import { Helper } from '../../../shared/helper';
import { debounceTime, filter } from 'rxjs/operators';
import { ObjectLiteral } from './../../../../models/objectLiteral';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-editable-grid',
  templateUrl: './editable-grid.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsEditableGridComponent extends FVAbstractControl implements AfterViewInit, OnDestroy {
  lookupGrid: GridConfig;
  loading = true;
  showModal = false;
  addAction: DockItem;
  editAction: DockItem;
  deleteAction: DockItem;
  activeKeyfield: any;
  modalTitle: string;

  private _fakeEdit: boolean;
  private _activeDialog: string;
  private _activePage: number;

  onDoneFired = new EventEmitter<any>();
  @Output() onDone: EventEmitter<FVDoneResponse> = new EventEmitter();

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private validation: ValidationService,
    private notification: DGSNotificationService,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
    this.fetchGridData();
    this.onChange();
    this.onDoneFired.pipe(debounceTime(250), filter((v) => !v.manual)).subscribe((doneResponse) => this.doAction(doneResponse));
  }

  /**
   * Tells all other components about add-new modal must be triggered.
   * Here multiple grids start to communicate with each other.
   */
  onAddRowClick() {
    if (!this.canAddNew) {
      return;
    }
    this.process.changes = {
      changeName: 'prepareForAddNew',
      changeVal: {
        task: this.task.taskId,
        id: this.task.id,
        control: this.control.referenceName,
        data: this.generateUserData(),
      },
    };
  }

  /**
   * On `Edit` Button clicked.
   * @param row ObjectLiteral.
   */
  onEditRowClick(row: object) {
    this.closeModal();
    const url = this.editAction.dialogData.id.includes('/') ? this.editAction.dialogData.componentConfig.model.entityName: this.editAction.dialogData.id;
    this.api.get('data', [url, row['Keyfield']], {}, { skipCache: true }).then((response: ApiResponse) => {
      this.errorMsg = this.process.errorFromResponse(response);
      if (this.errorMsg) {
        this.error(this.errorMsg);
        return;
      }
      this._activeDialog = 'edit';

      const shared = this.parseFormFilter(this.controlRef());
      const recordData = Object.assign({}, this.generateUserData(), row, response.data || {});
      const dialogData = this.activeDialog.dialogData;

      dialogData.dockedItems = [...this.dockedTop, ...this.dockedBottom];
      // Backup original recordData for slaves.
      dialogData.componentConfig['originalData'] = recordData;
      dialogData.componentConfig.recordData = Object.assign({}, recordData, shared);

      this.showModal = true;
      this.loading = false;
      this.ref.detectChanges();
    });
  }

  onDeleteRowClick() {
    this._activeDialog = 'delete';
    this.modalTitle = 'Confirm';
    this.showModal = true;
    this.ref.detectChanges();
  }

  onDeleteConfirm() {
    const model = (this.addAction || this.deleteAction).dialogData.componentConfig.model;
    const keyfield = (this.activeKeyfield || {}).Keyfield;
    this.api.delete(model.proxy.url, [keyfield]).then(() => {
      this.markAsDirty();
      this.fetchGridData(true);
      this.closeModal();
    });
  }

  onDeleteCancel() {
    this.loading = false;
    this.closeModal();
  }

  closeModal() {
    this._activeDialog = '';
    this.showModal = false;
    if (this.ref) {
      this.ref.detectChanges();
    }
  }

  /**
   * Triggered on editableGrid updated.
   * Scans for any error and do corresponding update.
   * @param doneResponse
   */
  doAction(doneResponse: FVDoneResponse, forced?: boolean): Promise<boolean> {
    this.loading = true;
    return new Promise((resolve, reject) => {
      const onClose = () => {
        this.loading = false;
        if (doneResponse.close || doneResponse.done) {
          this.closeModal();
        }
        return resolve(false);
      };

      if (!doneResponse.group) {
        this.warn('Should got group data but didnt!');
        this.closeModal();
        return reject();
      }

      const data = doneResponse.data;
      if (!data) {
        onClose();
        return resolve(false);
      }
      return Helper.getErrors(doneResponse.group, this.process, this.validation).then((errors) => {
        // Allow to proceed when is forced and there is no errors but grid required.
        // Allow to insert into grid while there is no rows in grid.
        const hasErrorsButAllowed = !this.validation.scanForErrorsButAllowed(this.group.controls) && forced;
        if (errors && errors.length && !hasErrorsButAllowed) {
          this.loading = false;
          this.notification.error(errors.join('<br>'));
          this.info(`You tried to submit form with next errors: `, errors);
          return reject();
        }

        const sanitize = (value) => {
          return value && value.replace(/[\[\]']+/g, '') || '';
        };

        const ref = this.controlRef();
        const store = ref.editableGrid.store;
        const toConcat = store.passColumns || [];
        const postBody = JSON.parse(JSON.stringify(data)); // No chain
        const formFilters = [...store.formFilter, ...toConcat];

        formFilters.forEach((item) => {
          const sanitizedValue = sanitize(item.value);
          const sanitizedKey = sanitize(item.key);

          if (sanitizedValue !== item.value) {
            postBody[item.key] = this.task.componentConfig.recordData[sanitizedValue];
            return;
          }
          if (sanitizedKey !== item.key) {
            postBody[sanitizedKey] = this.task.componentConfig.recordData[sanitizedKey];
            return;
          }
          postBody[item.key] = item.value
            || this.task.componentConfig.recordData[item.key]
            || this.process.get(`${item.key}-value`);
        });
        const model = this.activeDialog.dialogData.componentConfig.model;

        const afterRequestDone = (response) => {
          this.markAsDirty();
          this.fetchGridData(true);

          if (doneResponse.action === 'onsave') {
            this._setFakeEdit(response.data);
            return resolve(true);
          }
          onClose();
          return resolve(false);
        };

        if (this._activeDialog === 'add' && !this._fakeEdit) {
          return this.api.post(model.proxy.url, [], undefined, postBody).then(afterRequestDone);
        }
        if (this._activeDialog === 'edit' || this._fakeEdit) {
          const active = (
            this.activeDialog &&
            this.activeDialog.dialogData &&
            this.activeDialog.dialogData.componentConfig ||
            {}
          ).recordData;
          const keyfield = (this.activeKeyfield || active).Keyfield;
          // When user on edit, for custom document removes attached file, we delelete the document.
          const isAdditionalFile = !!doneResponse.data['IsAdditional'];
          const contentFile = doneResponse.data['ContentFile'];
          if (isAdditionalFile && (!contentFile || (Array.isArray(contentFile) && contentFile.length === 0))) {
            this.onDeleteConfirm();
            return;
          }
          return this.api.put(model.proxy.url, [keyfield], undefined, postBody).then(afterRequestDone);
        }

        return this.api.post(model.proxy.url, [], undefined, postBody).then(afterRequestDone);
      });
    });
  }

  onGridHandler(handle) {
    const handler = handle.action;

    this._activePage = handle.activePage;
    this.loading = true;

    if (handler === 'add') {
      return this.onAddRowClick();
    }
    if (handler === 'edit') {
      return this.onEditRowClick(handle.row);
    }
    if (handler === 'delete') {
      return this.onDeleteRowClick();
    }
  }

  get canAddNew(): boolean {
    return !this.isReadOnly && !this.disabled;
  }

  /**
   * Returns list of items docked to the top.
   */
  get dockedTop(): Dock[] {
    const docked = this.docked && this.docked.filter((dock) => dock.dock.toLowerCase() === 'top') || [];
    const defaultDocked = this.defaultDockedTop || [];
    return [...defaultDocked, ...docked];
  }

  /**
   * Returns list of items docked to the bottom.
   */
  get dockedBottom(): Dock[] {
    const docked = this.docked && this.docked.filter((dock) => dock.dock.toLowerCase() === 'bottom') || [];
    const defaultDocked = this.defaultDockedBottom || [];
    return [...defaultDocked, ...docked];
  }

  get defaultDockedTop(): Dock[] {
    return [];
  }

  get defaultDockedBottom(): Dock[] {
    return [
      new Dock('bottom', [
        { iconCls: 'fa fa-save', listeners: [{ click: 'onsave' }], text: 'Save', btnClass: 'btn-success' },
        { iconCls: 'fa fa-save', listeners: [{ click: 'save-close' }], text: 'Save and close', btnClass: 'btn-success' },
      ]),
    ];
  }

  get docked(): Dock[] {
    const data = this.activeDialog;
    if (!data || !data.dialogData || !data.dialogData.componentConfig) {
      return [];
    }
    return data.dialogData.componentConfig.dockedItems || [];
  }

  get activeDialog(): DockItem {
    return this._activeDialog === 'add' && this.addAction
      || this._activeDialog === 'delete' && this.deleteAction
      || this._activeDialog === 'edit' && this.editAction;
  }

  get activeDialogName(): string {
    return this._activeDialog;
  }

  /**
   * Transform form filter into final object.
   * @param controlRef
   * @param toParse
   */
  private parseFormFilter(controlRef?: Control, toParse: any[] = []): ObjectLiteral<string> {
    const result = {};

    const formFilter = (((controlRef || {}).editableGrid || {}).store || {}).formFilter || [];
    [...formFilter, ...toParse]
      .forEach((item: { key: string, operator: string, value: string }) => {
        const simpleSanitize = (item.value || '').replace(/[\']+/g, ''); // Removes '
        const advancedSanitize = (item.value || '').replace(/[\[\]']+/g, ''); // Removes [] and '
        const sanitized = advancedSanitize !== item.value && advancedSanitize !== simpleSanitize; // There was reference? Or Just a string.
        const target = sanitized ? advancedSanitize : item.key;
        const sharedValue = this.process.get(`${target}-value`);
        const refKey = target === 'Keyfield' && this.task.componentConfig.model.idProperty;
        const originalData = this.task.componentConfig['originalData'];

        if (refKey && originalData && !originalData.hasOwnProperty(refKey)) {
          return result[item.key] = 'null';
        }

        result[item.key] = !sanitized && simpleSanitize
          || this.task.componentConfig.recordData[target]
          || sharedValue
          || 'null';
      });
    return result;
  }

  /**
   * Triggers add-new modal.
   */
  private triggerAddNew() {
    const hasRecordData = (task) => task && task.componentConfig && task.componentConfig.recordData;
    this.closeModal();
    this._activeDialog = 'add';
    this.activeDialog.dialogData.dockedItems = [...this.dockedTop, ...this.dockedBottom];
    this.activeDialog.dialogData.componentConfig['originalData'] = JSON.parse(
      JSON.stringify(this.activeDialog.dialogData.componentConfig.recordData),
    );
    // Share parentTask record data
    if (hasRecordData(this.parentTask) && hasRecordData(this.task)) {
      const parentData = this.parentTask.componentConfig.recordData;
      const activeData = this.task.componentConfig.recordData;
      this.task.componentConfig.recordData = Object.assign({}, parentData, activeData);
    }
    this.activeDialog.dialogData.componentConfig.recordData = Object.assign({},
      this.task.componentConfig.recordData,
      this.parseFormFilter(this.controlRef()),
      this.activeDialog.dialogData.componentConfig.recordData,
    );
    this.showModal = true;
    this.loading = false;
    this.ref.detectChanges();
  }

  /**
   * Do form save then tell new component to do whatever is needed.
   * @param changeVal
   */
  private prepareForAddNew(changeVal) {
    if (!this.activeDialog) {
      this._activeDialog = 'add';
    }
    const dialog = this.activeDialog && this.activeDialog.dialogData && this.activeDialog.dialogData.componentConfig.model;
    if (dialog && ((dialog.entityName === changeVal.id) || (changeVal.id.replace('/', '-').includes(dialog.entityName)))) {
      const data: FVDoneResponse = { data: changeVal.data || this.generateUserData(), action: 'onsave', group: this.group };
      this.doAction(data, true).then(() => {
        setTimeout(() => {
          this.process.changes = { changeName: 'afterPreAddNewSaveDone', changeVal };
        }, 0);
      });
    }
  }

  /**
   * Trigers add new after pre-save been done.
   * @param changeVal
   */
  private afterPreAddNewSaveDone(changeVal) {
    if (!this.activeDialog) {
      this._activeDialog = 'add';
    }
    if (changeVal.id && this.task && this.task.id === changeVal.id) {
      this.triggerAddNew();
    }
  }

  /**
   * After add add new preparation done.
   * @param changeName
   * @param changeVal
   * @param isTarget
   */
  private prepareForAddNewDone(changeName, changeVal, isTarget) {
    if (changeName !== 'prepareForAddNewDone' || !isTarget || !changeVal.success) {
      return;
    }
    const { modalBody, scrollTop } = changeVal;
    if (modalBody.scrollTo) {
      modalBody.scrollTo(0, scrollTop);
    }
    if (!changeVal.task && changeVal.id && changeVal.id === this.task.id) {
      this._activeDialog = 'add';
      this.doAction({ data: this.generateUserData(), action: 'onsave', group: this.group });
    }
    this.triggerAddNew();
  }

  /**
   * When first save been done, switch to update mode without changing form.
   * Sometimes save/update form may differ. That way formviewer allowes to stay on save form untill you quit.
   * @param recordData
   */
  private _setFakeEdit(recordData) {
    if (this._activeDialog !== 'add') {
      return;
    }
    this._fakeEdit = true;
    this.activeDialog.dialogData.dockedItems = [...this.dockedTop, ...this.dockedBottom];
    const shared = this.parseFormFilter(this.controlRef());
    this.addAction.dialogData.componentConfig['originalData'] = recordData;
    this.addAction.dialogData.componentConfig.recordData = Object.assign({}, recordData, shared);
    this.showModal = true;
    this.loading = false;
    this.ref.detectChanges();
  }

  /**
   * Fetching all the grid configuration.
   * @param clearData
   */
  private fetchGridData(clearData: boolean = false) {
    this.modalTitle = this.lookupGrid && this.lookupGrid.componentConfig && this.lookupGrid.componentConfig.title;
    const ref = this.controlRef();
    if (!ref) {
      return;
    }
    this.loading = true;
    const lookupGrid: GridConfig = this.lookupGrid = {};
    if (this.ref) {
      this.ref.detectChanges();
    }
    lookupGrid.componentConfig = ref.editableGrid;
    const filters = this.parseFormFilter(
      null,
      [...(ref.editableGrid.store.formFilter || []), ...(ref.editableGrid.store.passColumns || [])],
    );
    lookupGrid.filters = Object.keys(filters).map((key) => ({ property: key, value: filters[key] }));
    lookupGrid.parent = this.control;
    lookupGrid.model = ref;
    lookupGrid.editable = true;
    lookupGrid.hideFilter = true;
    lookupGrid.notClickable = false;
    lookupGrid.activePage = this._activePage;

    // Setup handlers
    const handlers = this.controlRef().editableGrid.editHandlers;
    lookupGrid.addAction = !!handlers.addAction;
    lookupGrid.editAction = !!handlers.editAction;
    lookupGrid.deleteAction = !!handlers.deleteAction;
    this.addAction = handlers.addAction;
    this.editAction = handlers.editAction;
    this.deleteAction = handlers.deleteAction;
    if (clearData && lookupGrid && lookupGrid.componentConfig
      && lookupGrid.componentConfig.store
      && lookupGrid.componentConfig.store.proxy) {
      this.api.removeMatched(lookupGrid.componentConfig.store.proxy.url);
    }

    // Add actions column if needed
    const hasButtons = Boolean(lookupGrid.editAction || lookupGrid.deleteAction);
    const editExists = lookupGrid.componentConfig.columns.some((column) => column.type === 'edit');
    if (hasButtons && !editExists) {
      lookupGrid.componentConfig.columns = [
        ...(lookupGrid.componentConfig.columns || []),
        new GridColumn(null, null, null, null, 'Actions', 'edit'),
      ];
    }

    this.lookupGrid = lookupGrid;
    this.loading = false;
    if (this.ref) {
      this.ref.detectChanges();
    }
    this._fakeEdit = false;
  }

  private markAsDirty() {
    if (this.group) {
      this.group['form'].markAsDirty();
    }
  }

  /**
   * Subscribes for grid-to-grid hooks.
   */
  private onChange() {
    this.subscriptions.push(
      this.process.onChange.subscribe((changes: IDGSChangeSet) => {
        const { changeName, changeVal } = changes;
        const task = changeVal.task;
        const isTarget = task && task === this.task.taskId && changeVal.control === this.control.referenceName;
        this.prepareForAddNewDone(changeName, changeVal, isTarget);
        if (changeVal.id && !changeVal.task) {
          if (changeName === 'prepareForAddNew' && changeVal.id && !changeVal.task) {
            this.prepareForAddNew(changeVal);
          }
          if (changeName === 'afterPreAddNewSaveDone') {
            this.afterPreAddNewSaveDone(changeVal);
          }
        }
      }),
    );
  }

  ngOnDestroy() {
    super.OnDestroy(this.ref);
  }
}
