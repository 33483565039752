import { IDGSEnvironment } from '../models/environment';

export class Debugger {
  protected _environment: IDGSEnvironment;
  protected _buttonsDebug: boolean;
  protected _missingFieldsDebug: boolean;

  constructor() { }

  protected init(environment: IDGSEnvironment) {
    const debugAll = environment.debug === true;
    this._environment = environment;
    this._buttonsDebug = debugAll || (environment.debug && environment.debug['buttons'] === true);
    this._missingFieldsDebug = debugAll || (environment.debug && environment.debug['missingFields'] === true);
  }

  protected log(...messages) {
    this.action('log', ...messages);
  }

  protected warn(...messages) {
    this.action('warn', ...messages);
  }

  protected error(...messages) {
    this.action('error', ...messages);
  }

  protected info(...messages) {
    this.action('info', ...messages);
  }

  protected debug(...messages) {
    this.action('debug', ...messages);
  }

  private action(method: string, ...messages) {
    const shouldRuntimeDebug = window['dgs_debug'] === true;
    const debug = this._environment.debug;
    if (!debug || typeof debug !== "object" && typeof debug !== 'boolean') {
      return;
    }
    const shouldDebug = debug === true || debug['logs'] === true || debug['logs'] && debug['logs'][method] === true;
    if (shouldDebug || shouldRuntimeDebug) {
      console[method](...messages);
    }
  }
}
