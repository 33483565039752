import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Folder } from '../../../../models/menu';
import { ProcessService } from '../../../../services/process.service';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-menu-folder',
  templateUrl: './menu-folder.component.html',
  styleUrls: ['./menu-folder.component.css'],
})
export class FVMenuFolderComponent {
  debug: boolean;

  @Output() onFolderClick = new EventEmitter<Folder>();

  protected _activeNode: string;
  @Input() set activeNode(activeNode: string) {
    this._activeNode = activeNode;
  }

  protected _designer: any;
  @Input() set designer(designer: any) {
    this._designer = designer;
  }

  protected _level: number;
  @Input() set level(level: number) {
    this._level = level;
  }

  private _folder: Folder;
  @Input() set folder(folder: Folder) {
    this._folder = folder;
  }

  get level(): number {
    return this._level || 0;
  }

  get activeNode(): string {
    return this._activeNode;
  }

  get folder(): Folder {
    return this._folder;
  }

  get designer(): any {
    return this._designer;
  }

  get debugHtml(): string {
    if (!this.designer) {
      return;
    }
    return `<span>folder: ${this.designer.name}.</span><br>
    <span>table: ${this.designer.model.table}.</span><br>
    <span>table-view: ${this.designer.model.view}.</span>`;
  }

  constructor(
    private process: ProcessService,
  ) {
    const debug = this.process.get('environment').debug || window['debug'];
    if (!debug) {
      return;
    }
    this.debug = debug === true || debug.tooltips === true;
  }

  folderClicked(folder: Folder) {
    this.onFolderClick.emit(folder);
  }
}
