import { ControlType } from './../models/control';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { ValidationErrors, FormControl, AbstractControl } from '@angular/forms';
import { LanguageService } from '../services/language.service';
import { IDGSEnvironment, Debugger } from '@dotgov/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Control } from '../models/control';
import { ProcessService } from './process.service';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class FormErrors {
  constructor(
    public control?: FormControl,
    public errors?: ValidationErrors,
  ) { }
}

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Injectable()
export class ValidationService extends Debugger {
  private updateValidation: EventEmitter<any> = new EventEmitter();

  private errorsText = {
    'required': `is required field.`,
    'pattern': `is not a valid field. Please check info how to complete this field.`,
    'unknown': `is not valid. Please make sure you have completed right each field.`,
  };

  constructor(
    @Inject('environment') protected environment: IDGSEnvironment,
    private language: LanguageService,
    private process: ProcessService,
  ) {
    super();
    super.init(this.environment);
  }

  /**
   * Return unknown type error.
   * @param warn
   */
  unkownError(warn?: boolean): Promise<string> {
    if (warn === undefined) {
      warn = !!this.environment.debug;
    }
    if (warn) {
      this.warn('Validation meet unkown error');
    }
    return this.getError('unknown');
  }

  /**
   * Transform form-group {errorName} into readable one. ( translation included )
   * @param errorName
   * @param controlTitle
   * @param controlName
   * @param controlRef
   */
  getError(errorName: string, controlTitle?: string, controlName?: string, controlRef?: Control): Promise<string> {
    if (!this.errorsText.hasOwnProperty(errorName)) {
      this.warn(`Error: System tried to load unknown error '${errorName}'.`);
    }
    if (controlName && controlName === 'm-sign') {
      return this.language.getTranslate(`You must sign the document before proceed.`).then((translatedMsg: string) => {
        return translatedMsg;
      });
    }
    const customError = errorName === 'pattern' && controlRef && controlRef.paternMessage;
    const errToTranslate = customError || this.errorsText[errorName] || this.unkownError();
    return this.language.getTranslate(errToTranslate).then((errorMsg: string) => {
      return this.language.getTranslate(controlTitle || 'This').then((tControlTitle: string) => {
        const renderThis = !customError && 'This' || '';
        const prefix = controlTitle ? `"${this.capitalize(tControlTitle)}"` : renderThis;
        return `${prefix} ${errorMsg.toLowerCase()}`.trim();
      });
    });
  }

  /**
   * Check if given {controlRef} got any errors.
   * @param errors
   * @param controlRef
   * @param hideLabel
   * @returns Found errors.
   */
  scanForControlErrors(errors: ValidationErrors, controlRef: Control, hideLabel?: boolean): Promise<string[]> {
    return new Promise((resolve, reject) => {
      if (!errors) {
        return [];
      }
      const promises = Object.keys(errors).map((errorName) => {
        if (errors[errorName]) {
          return this.getError(errorName, hideLabel ? '' : controlRef.label, '', controlRef) || this.unkownError();
        }
        return null;
      }).filter(err => !!err);
      return Promise.all(promises).then(resolve, reject);
    });
  }

  /**
   * Scan for any error on form except allowed ones.
   * Now as allowed match only required form-grid type.
   * @param controls
   */
  scanForErrorsButAllowed(
    controls: { [key: string]: FormControl | AbstractControl },
    allowed = [ControlType.EFormElement.EditableGrid],
  ): boolean {
    return Object.keys(controls).every((key) => {
      const control = controls[key];
      if (control.errors) {
        const controlRef = this.process.get(key) || {};
        return allowed.indexOf(controlRef.controlType) === -1;
      }
      return true;
    });
  }

  onUpdate(cb): Subscription {
    return this.updateValidation.pipe(debounceTime(150), distinctUntilChanged()).subscribe(cb);
  }

  update(value?: any) {
    this.updateValidation.emit(value);
  }

  private capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
