import { ComponentConfig } from './../../models/componentConfig';
import { Input, Output, EventEmitter, ChangeDetectorRef, ReflectiveInjector, Directive } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Task, ControlType, Control, Field } from '../../models';
import { ProcessService } from '../../services/process.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Helper } from './helper';
import { Subscription } from 'rxjs/index';
import { FormApi } from './form-api';
import { Debugger, DGSNotificationService } from '@dotgov/core';
import { ObjectLiteral } from '../../models/objectLiteral';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Directive()
export class FVAbstractControl extends Debugger {
  debugStatus = false;
  bsDateConfig: Partial<BsDatepickerConfig>;
  errorMsg: string;
  environment: any;
  _notification: DGSNotificationService;

  protected formData: Task;
  protected isDestroyed: boolean;
  protected subscriptions: Subscription[] = [];

  protected _task: Task;
  @Input() set task(task: Task) {
    this._task = task;
  }

  protected _parentTask: Task;
  @Input() set parentTask(parentTask: Task) {
    this._parentTask = parentTask;
  }

  protected _control: Control;
  @Input() set control(control: Control) {
    this._control = control;
  }

  protected _stateId: string;
  @Input() set stateId(stateId: string) {
    this._stateId = stateId;
  }

  protected _processId: string;
  @Input() set processId(processId: string) {
    this._processId = processId;
  }

  protected _group: FormGroup;
  @Input() set group(group: FormGroup) {
    this._group = group;
  }

  protected _tabIndex: number;
  @Input() set tabIndex(tabIndex: number) {
    this._tabIndex = tabIndex;
  }

  protected _disabled: boolean;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  @Output() updateGroup: EventEmitter<any> = new EventEmitter();

  private _process: ProcessService;
  constructor(_process: ProcessService) {
    super();
    this.loadNotificationService();
    this._process = _process;
    this.environment = _process.get('environment');
    super.init(this.environment);
    this.bsDateConfig = Object.assign({}, { containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false });
  }

  generateUserData(): any {
    return Helper.getFormData(this.group, this._process, this.task);
  }

  /**
   * Returns debug text for current component.
   */
  toString(): string {
    return `control group: ${this.control && ControlType.controlGroupType(this.control.controlType)}
      controlType: ${this.control && ControlType.controlType(this.control.controlType)} (${this.control && this.control.controlType})
      isHidden: ${this.control && this.control.isHidden}
      isReadOnly: ${this.isReadOnly}
      isRequired: ${this.control && this.control.isRequired}
      key: ${this.control && this.control.key}
      referenceName: ${this.control && this.control.referenceName}
      dataSourceType: ${this.control && this.control.dataSourceType}
      label: ${this.control && this.control.label} hidden: ${this.hideLabel}
      description: ${this.control && this.control.description}
      showAs: ${this.control && ControlType.controlType(this.control.showAs)} (${this.control.showAs})`;
  }

  updateFormGroup(event) {
    this.updateGroup.emit(event);
  }

  hasHandler(handler: 'add' | 'edit' | 'delete'): boolean {
    if (!handler) {
      return;
    }
    const ref = this.controlRef();
    const action = ref && ref.editableGrid && (ref.editableGrid.editHandlers || {})[`${handler}Action`];
    return !!action.dialogData;
  }

  get recordData(): ObjectLiteral {
    return this.componentConfig.recordData || {};
  }

  get parentTask(): Task {
    return this._parentTask;
  }

  get task(): Task {
    return this._task;
  }

  get control(): Control {
    return this._control;
  }

  get stateId(): string {
    return this._stateId;
  }

  get processId(): string {
    return this._processId;
  }

  get group(): FormGroup {
    return this._group;
  }

  get tabIndex(): number {
    return this._tabIndex;
  }

  get defaultControlSize(): number {
    return 48;
  }

  get tooltip(): string {
    return this.control.description || '';
  }

  get disabled(): boolean | null {
    return Boolean(this._disabled || this.control && this.control.isDisabled) || null;
  }

  get isReadOnly(): boolean {
    return this.control.isReadOnly || this.componentConfig.readonly;
  }

  get componentConfig(): ComponentConfig {
    return this.task && this.task.componentConfig && this.task.componentConfig || {};
  }

  get buttonsDebug(): boolean {
    return window['debug'] || this._buttonsDebug;
  }

  get hideLabel(): boolean {
    if (this.environment.formBuilder) {
      return false;
    }
    return this.control.titlePosition === 'off' || this.control.showLabel === false;
  }

  get formControl(): AbstractControl {
    return this.group.controls[this.control.key];
  }

  protected setValue(_value?: any) {
    const value = _value || this.recordData[this.control.referenceName];
    if (value !== undefined) {
      this.updateGroup.emit({ key: this.control.key, value });
    }
  }

  protected AfterViewInit() {
    this.setValue();
    this.saveKey();
    this.evalEventJs();
  }

  protected saveKey() {
    const key = this._control.key;
    this._process.set(key, this._control, this.task && this.task.taskId || this.task.id);
    this._process.set(`control-${this._control.referenceName}`, this._control, this.task && this.task.taskId || this.task.id);
  }

  protected controlRef(refName?: string, task?: Task): Control | Field {
    const controls = (task || this.task).componentConfig.controls;
    if (!controls) {
      return;
    }
    return controls.find((field: Control) => field.referenceName === (refName || this.control.referenceName));
  }

  protected evalEventJs() {
    if (this.environment.formBuilder) {
      return;
    }
    // Must be in timeout in order to wait for all the controls got rendered by angular forms
    setTimeout(() => {
      const eventJs = this.control && this.control.eventJs;
      if (eventJs) {
        const frm = new FormApi(this.group, this._process, this.task, this._notification);
        frm.init();
        try {
          // tslint:disable-next-line:no-eval
          eval(eventJs);
        } catch (e) {
          this.error('Error executing eventJs', e);
        }
      }
    }, 1);
  }

  private loadNotificationService() {
    // Manually rerieve the monitoring service from the injector
    // so that constructor has no dependencies that must be passed in from child
    // tslint:disable-next-line: deprecation
    const injector = ReflectiveInjector.resolveAndCreate([
      DGSNotificationService,
    ]);
    this._notification = injector.get(DGSNotificationService);
  }
  protected OnDestroy(ref?: ChangeDetectorRef) {
    this.isDestroyed = true;
    this.subscriptions.forEach((subs) => subs && subs.unsubscribe());
    if (ref) {
      ref.detach();
    }
  }
}
