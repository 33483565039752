import { GridComponentConfig } from './gridComponentconfig';
import { Extract } from './extract';

export interface Column {
  css: string;
  controls: Control[];
}

export interface BindingSet {
  from: string;
  to: string;
  refKey: string;
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Control {
  constructor(
    public bindingSet?: BindingSet[],
    public handler?: any,
    public isHidden?: boolean,
    public isReadOnly?: boolean,
    public isDisabled?: boolean,
    public isRequired?: boolean,
    public key?: string,
    public onCreateValue?: any,
    public onUpdateValue?: any,
    public referenceName?: string,
    public sectionName?: string,
    public selectAction?: any,
    public view?: any,
    public controlType?: string | number | any,
    public dataSourceType?: number,
    public label?: string,
    public options?: any,
    public showAs?: number,
    public columns?: Column[],
    public сontrolType?: number,
    public editableGrid?: GridComponentConfig,
    public controlName?: string,
    public description?: string,
    public eventJs?: string,
    public content?: string,
    public watermark?: string,
    public controls?: Control[],
    public placeholder?: string,
    public size?: number,
    public inline?: boolean,
    public isMultiple?: boolean,
    public precision?: number,
    public titlePosition?: string,
    public showLabel?: boolean,
    public pattern?: string,
    public format?: string,
    public extract?: Extract,
    public minDate?: Date | string | number,
    public maxDate?: Date | string | number,
    public allowedFileTypes?: string[],
    public allowedFileSize?: number,
    public paternMessage?: string,
  ) { }
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class ControlType {
  constructor() { }

  static inputFields = [
    'TextField',
    'TextBox',
    'TextArea',
    'EmailField',
    'NumberField',
    'MoneyField',
    'PositiveNumber',
    'CheckBox',
    'Toggle',
    'RadioButton',
    'UploadFile',
    'ListField',
    'PickList',
    'Lookup',
    'RadioButtonList',
    'DropDownList',
    'Date',
    'Time',
    'DateTime',
    'Phone',
  ];

  static formFields = [
    'Label',
    'Button',
    'EditableGrid',
    'MenuGrid',
    'ProgressBar',
  ];

  static designFields = [
    'HiddenField',
    'TableLayout',
    'BootstrapRow',
    'SectionLayout',
    'FlexRow',
    'HtmlContent',
    'FormTab',
    'Form',
    'CustomControl',
  ];

  static EFormElement = Object.freeze({
    UNDEFINED: 0,

    TextBox: 1,
    TextArea: 2,
    Button: 3,
    CheckBox: 4,
    UploadFile: 5,
    RadioButton: 6,
    RadioButtonList: 7,
    DropDownList: 8,
    Date: 9,
    Time: 10,
    DateTime: 11,
    TableLayout: 13,
    BootstrapRow: 14,
    FlexRow: 15,
    Toggle: 16,
    URL: 17,
    CheckList: 18,
    Phone: 999,

    HiddenField: 50,

    Label: 100,
    TextField: 101,
    ListField: 107,
    PickList: 108,
    Lookup: 109,
    MultiLookup: 110,
    EditableGrid: 114,

    EmailField: 121,
    NumberField: 122,
    MoneyField: 123,
    PositiveNumber: 124,

    HtmlContent: 200,
    CustomControl: 201,
    GMAP: 202,

    SectionLayout: 300,

    MenuGrid: 400,
    ProgressBar: 401,

    FormTab: 1000,
    Form: 1001,
  });

  static controlType(num: number): string {
    const enums = ControlType.EFormElement;
    return Object.keys(enums).find((key) => enums[key] === num);
  }

  static controlGroupType(num: number): 'input' | 'form' | 'design' | 'undefined' {
    return ControlType.isInputField(num) && 'input'
      || ControlType.isFormField(num) && 'form'
      || ControlType.isDesignField(num) && 'design'
      || 'undefined';
  }

  static isInputField(num: number): boolean {
    const controlType = ControlType.controlType(num);
    const inputFields = ControlType.inputFields;
    return inputFields.indexOf(controlType) !== -1;
  }

  static isFormField(num: number): boolean {
    const controlType = ControlType.controlType(num);
    const formFields = ControlType.formFields;
    return formFields.indexOf(controlType) !== -1;
  }

  static isDesignField(num: number): boolean {
    const controlType = ControlType.controlType(num);
    const designFields = ControlType.designFields;
    return designFields.indexOf(controlType) !== -1;
  }

  static isDateType(num: number): boolean {
    const dateInputs = [9, 10, 11];
    return dateInputs.indexOf(num) !== -1;
  }
}
