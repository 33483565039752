
/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Path {
  constructor(
    public key?: string,
    public value?: string,
  ) { }
}
