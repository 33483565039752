import { AbstractControl, FormControl } from '@angular/forms';
import { OnDestroy, ViewChild, Directive } from '@angular/core';
import { BsLocaleService} from 'ngx-bootstrap/datepicker';
import {  ruLocale, enGbLocale } from 'ngx-bootstrap/locale';
import {  defineLocale } from 'ngx-bootstrap/chronos';
import { FVAbstractControl } from './abstract-control.component';
import { ProcessService } from '../../services/process.service';
import { LanguageService } from '../../services/language.service';
import { Helper } from './helper';
import { Lang } from '@dotgov/core';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Directive()
export class FVControlsAbstractTimeComponent extends FVAbstractControl implements OnDestroy {
  @ViewChild('container') container;

  readonly HEIGHT: number = 340;

  private canFitBottom: boolean;

  constructor(_process: ProcessService, _localeService: BsLocaleService, _language: LanguageService) {
    super(_process);
    defineLocale('ru', ruLocale);
    defineLocale('ro', enGbLocale);
    if (_language.language) {
      _localeService.use(_language.language.Code);
    }
    this.subscriptions.push(_language.languageChanged.subscribe((lang: Lang) => {
      _localeService.use(lang.Code);
    }));
  }

  get minDate(): Date | string | number {
    return this.control && this.control.minDate || undefined;
  }

  get maxDate(): Date | string | number {
    return this.control && this.control.maxDate || undefined;
  }

  get formControl(): FormControl | AbstractControl {
    return this.group && this.group.controls[this.control.key];
  }

  get placement(): string {
    return this.canFitBottom ? 'bottom' : 'top';
  }

  protected AfterViewInit() {
    super.AfterViewInit();
    this.setPlacement();
  }

  openCalendar(ref) {
    if (!ref || !ref.toggle) {
      return;
    }
    const controlRef: any = this.controlRef() || {};
    if (controlRef.readonly || controlRef.disabled) {
      return;
    }
    ref.toggle();
  }

  /**
   * Protected because override property must have same access level as super
   * @param _value
   */
  protected setValue(_value?: any) {
    const value = _value || this.task.componentConfig.recordData[this.control.referenceName];
    if (value !== undefined) {
      this.updateGroup.emit({ key: this.control.key, value: Helper.formatDate(value, true, true) });
    }
  }

  private setPlacement() {
    setTimeout(() => {
      const modalDoc = Helper.parentContainer(this.container);
      const enoughtModalSize = modalDoc.scrollHeight < window.innerHeight;
      const spaceToBottom = modalDoc.scrollHeight - this.container.nativeElement.parentElement.offsetTop;
      const enoughtBottomSpace = spaceToBottom > this.HEIGHT;
      this.canFitBottom = enoughtModalSize && enoughtBottomSpace || this.HEIGHT > modalDoc.height - 150;
    }, 1);
  }

  ngOnDestroy() {
    super.OnDestroy();
  }
}
