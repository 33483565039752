import { Injectable, Type, ComponentFactoryResolver, ReflectiveInjector, ComponentRef, Inject } from '@angular/core';
import { IDGSEnvironment } from '../models/environment';

@Injectable()
export class FactoryService {
  static HAS_ROUTE = null;
  static NOT_FOUND = undefined;

  private debug: boolean;
  constructor(private resolver: ComponentFactoryResolver, @Inject('environment') private environment: IDGSEnvironment = {}) {
    this.debug = Boolean(environment.debug);
  }

  /**
   * How to use:
   * // html
   * <div #container></div>
   * // class
   * @ViewChild('container', { read: ViewContainerRef }) container;
   * // ngAfterViewInit
   * const factory = this.factoryService.componentByName('ComponentName');
   * this.container.createComponent(factory);
   * this.ref.detectChanges();
   *
   * @param componentName
   */
  componentByName(componentName: string, target, inputs: Object): ComponentRef<any> {
    const factories = Array.from(this.resolver['_factories'].keys());
    const factoryClass = <Type<any>>factories.find((x: any) => x.name === componentName);

    if (!factoryClass) {
      if (this.debug) {
        console.warn(`Could not load ${componentName}`);
      }
      return;
    }

    return this.createComponent(factoryClass, inputs, target);
  }

  /**
   * How to use:
   * // html
   * <div #container></div>
   * // class
   * @ViewChild('container', { read: ViewContainerRef }) container;
   * // ngAfterViewInit
   * const factory = this.factoryService.componentbySelector('component-selector');
   * this.container.createComponent(factory);
   * this.ref.detectChanges();
   *
   * @param componentName
   */
  componentbySelector(componentSelector: string, componentFactories: {selector:string, factoryClass: any}[] , target, inputs: object = {}): ComponentRef<any> {
    if (!componentSelector) {
      if (this.debug) {
        console.warn('Started client-control with no target component.');
      }
      return FactoryService.NOT_FOUND;
    }
    let res;
    componentFactories.forEach((value) => {
      if (value && value.selector === componentSelector) {
        res = this.createComponent(value.factoryClass, inputs, target);
        return;
      }
    });
    if (!res) {
      if (this.debug) {
        console.warn(`Could not load ${componentSelector}`);
      }
      return FactoryService.NOT_FOUND;
    }
    if (!res.create) {
      return FactoryService.HAS_ROUTE;
    }
    return res;
  }

  private createComponent(factoryClass, inputs, target): ComponentRef<any> {
    if (!target || !target.insert) {
      if (this.debug) {
        console.warn(`Could not get target for `, factoryClass, '|', target);
      }
      return FactoryService.NOT_FOUND;
    }
    if (!factoryClass) {
      if (this.debug) {
        console.warn(`Could not load factory class`);
      }
      return FactoryService.NOT_FOUND;
    }

    const inputProviders = Object.keys(inputs).map((inputName) => {
      return { provide: inputName, useValue: inputs[inputName] };
    });
    const resolvedInputs = ReflectiveInjector.resolve(inputProviders);

    // We create an injector out of the data we want to pass down and this components injector
    const injector = ReflectiveInjector.fromResolvedProviders(resolvedInputs, target.parentInjector);

    // We create a factory out of the component we want to create
    const factory = this.resolver.resolveComponentFactory(factoryClass);

    // We create the component using the factory and the injector
    const component = factory.create(injector);

    // We insert the component into the dom container
    target.insert(component.hostView);
    Object.keys(inputs).forEach((input) => {
      component.instance[input] = inputs[input];
    });
    return component;
  }
}
