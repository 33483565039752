import { Component, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm, FormControl } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';
import { ValidationService } from '../../../../services/validation.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsValidatorComponent extends FVAbstractControl implements AfterViewInit, OnDestroy {
  errors: string[] = [];

  // Selector '[formControl]' is used by NgForm so i renamed it to FVFormControl
  // tslint:disable-next-line:no-input-rename
  @Input('FVFormControl') fc: FormControl;

  constructor(
    private process: ProcessService,
    private validator: ValidationService,
    private ref: ChangeDetectorRef,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.process.onAnyUpdate.pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(this.checkForErrors.bind(this)));
    this.subscriptions.push(this.group.valueChanges.pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(this.checkForErrors.bind(this)));
    this.validator.onUpdate(this.checkForErrors.bind(this));
    this.checkForErrors();
    this.ref.detectChanges();
  }

  /**
   * Scan for any error on current control.
   * Triggered on any change by subscribes ahead.
   */
  private checkForErrors() {
    const oldErrors = this.process.get(`validator-${this.task.taskId}`) || {};
    const control = this.fc;
    if (!control) {
      return;
    }
    
    if (!control.invalid || !this.group.dirty) {
      this.errors = [];
      delete oldErrors[this.control.key];
      return;
    }
    oldErrors[this.control.key] = this.tabIndex;
    this.process.set(`validator-${this.task.taskId}`, oldErrors);
    if (control.errors) {
      this.validator.scanForControlErrors(control.errors, this.control, true).then((errors) => {
        this.errors = errors;
      });
    } else {
      this.warn('Found error but not error type, throwing unknown!');
      this.validator.unkownError(false).then((error) => {
        this.errors = [error];
      });
    }
  }

  ngOnDestroy() {
    super.OnDestroy(this.ref);
  }
}
