import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FVAbstractControl } from '../../shared/abstract-control.component';
import { ValidationService } from '../../../services/validation.service';
import { ApiResponse, DGSNotificationService, IDGSChangeSet } from '@dotgov/core';
import { ProcessService } from '../../../services/process.service';
import { Task, Dock, DockItem } from '../../../models';
import { Helper } from '../../shared/helper';
import { FormApi } from '../../shared/form-api';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.css', '../controls/controls.css'],
})
export class FVNavigatorComponent extends FVAbstractControl implements AfterViewInit, OnDestroy {
  loading = true;
  showModal: boolean;
  isGrouped = false;

  addBtn = {
    listeners: [{click: 'save-close'}]
  };
  cancelBtn = {
    listeners: [{click: 'close'}]
  }
  private __task: Task;
  private _dock: Dock[];
  private _items: any;

  private _dockType: string;
  @Input() set dock(dock: Dock[]) {
    if (JSON.stringify(this._dock) === JSON.stringify(dock)) {
      return;
    }
    this._dock = dock;
    const items = [];
    dock.forEach((_dock: Dock) => {
      items.push(...(_dock.items || []));
    });
    this._items = items.map((item) => Object.assign({ selector: Helper.camelize(item.text) }, item));
  }

  @Input() firstLevel: boolean;

  private _isGrid: boolean;
  @Input() set isGrid(isGrid: boolean) {
    this._isGrid = isGrid;
  }

  private _gridItem: any;
  @Input() set gridItem(gridItem: any) {
    this._gridItem = gridItem;
  }

  @Input() set type(type: string) {
    this._dockType = type;
  }

  @Input() customSubmit = false;

  @Output() onDone: EventEmitter<any> = new EventEmitter();
  @Output() onMenuItemClicked: EventEmitter<any> = new EventEmitter();
  @Output() onNavigation: EventEmitter<DockItem> = new EventEmitter();
  @Output() onNavigationError: EventEmitter<any> = new EventEmitter();

  constructor(
    private process: ProcessService,
    private notification: DGSNotificationService,
    private validation: ValidationService,
    private elRef: ElementRef,
  ) {
    super(process);
    this.loading = false;
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.process.onChange.subscribe((changes: IDGSChangeSet) => {
      const { changeName, changeVal } = changes;
      if (changeName !== 'prepareForAddNew' || !this.firstLevel) {
        return;
      }
      const modalBody = Helper.parentContainer(this.elRef, (el) => el.className.indexOf('modal-body') !== -1);
      const scrollTop = (modalBody || {}).scrollTop;
      if (changeVal.task && changeVal.task === this.task.taskId) {
        const onDone = (success: Boolean) => {
          setTimeout(() => {
            this.process.changes = {
              changeName: 'prepareForAddNewDone',
              changeVal: {
                task: changeVal.task,
                control: changeVal.control,
                success,
                modalBody,
                scrollTop,
              },
            };
          }, 0);
        };
        this.onClick({
          iconCls: 'fa fa-floppy-o',
          listeners: [{ click: 'Save' }],
          text: 'Save',
          selector: 'save',
        }, true).then(() => onDone(true)).catch(() => onDone(false));
      }
    }));
    this.subscriptions.push(this.process.onAnyUpdate.subscribe((task: Task) => {
      if (task && this.__task && task.processInstanceId !== this.__task.processInstanceId) {
        return;
      }
      this.__task = task;
    }));
  }

  onClick(item: DockItem, forced?: boolean): Promise<any> {
    const clickListener = this.getClickListener(item);
    if (!clickListener.click) {
      return Promise.reject(false);
    }
    const onbeforesave = (task = this.task): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (!this.group) {
          return reject('No form group');
        }
        if (!task || !task.componentConfig || !task.componentConfig.items) {
          return resolve(null);
        }
        // Eval on save scrips
        const promises = task.componentConfig.items.map((_item) => {
          const handler = (_item.handlers || {})['onsavescript'];
          if (!handler) {
            return;
          }
          return this.evalOnSave(this.task, handler);
        }).filter((promise) => !!promise);
        return resolve(Promise.all(promises));
      });
    };
    this.loading = true;
    const actionName = clickListener.click.toLowerCase();
    if (actionName === 'oncancel') {
      return this.cancel();
    } else if (actionName === 'onsave') {
      return this._finally(onbeforesave(), this.save.bind(this));
    } else if (actionName === 'save-close') {
      return this._finally(onbeforesave(), this.saveClose.bind(this));
    } else if (actionName === 'close' || actionName === 'onclose' || actionName === 'none') {
      return this.closeUndefined(true, actionName);
    }
    if (this.isGrid) {
      this.onMenuItemClicked.emit({ actionName, dockItem: item, activeItem: this.gridItem, group: this.group });
      this.loading = false;
      return Promise.resolve();
    }
    const form = this.group;
    form['form'].updateValueAndValidity({ emitEvent: true });
    if (!form) {
      this.warn('Navigator called out of form context!');
      return Promise.reject('Navigator called out of form context!');
    }
    if (!this.isClickable(item.selector) && !forced) {
      this.log(this.pristineError);
      this.loading = false;
      return Promise.reject('Cannot submit pristine');
    }
    // Allow to proceed when is forced and there is no errors but grid required.
    // Allow to insert into grid while there is no rows in grid.
    const hasErrorsButAllowed = !this.validation.scanForErrorsButAllowed(this.group.controls) && forced;
    // Allow step back without validation.
    if (!form.invalid || hasErrorsButAllowed || actionName === 'back') {
      const before = actionName !== 'back' ? onbeforesave : () => Promise.resolve(true);
      const userData = this.generateUserData();
      const finalStep = () => this.doAction(actionName, userData).then((response: ApiResponse) => {
        const error = (response.error || {}).error || {};
        if (error.type === 'Dgs.Model.CustomException') {
          this.loading = false;
          this.notification.error(error.message);
          return Promise.resolve({ data: this.task, error });
        }
        this.errorMsg = this.process.errorFromResponse(response);
        if (this.errorMsg) {
          this.error(this.errorMsg);
          this.loading = false;
          return Promise.reject(this.errorMsg);
        }
        this.loading = false;
        if (response.data.status === Task.TASK_DONE_STATUS) {
          this.onDone.emit({ done: true, data: userData, action: actionName, close: true, group: this.group });
        }
        setTimeout(() => {
          if (this.group && this.group['form']) {
            this.group['form'].markAsPristine({ onlySelf: false });
            this.group['form'].updateValueAndValidity({ emitEvent: true });
          }
        }, 100);
        return Promise.resolve(response);
      });
      return this._finally(before(), finalStep.bind(this));
    }
    // Extracting the errors from every control
    const errorsPromise = Helper.getErrors(this.group, this.process, this.validation);
    return errorsPromise.then((errors) => {
      if (errors && errors.length) {
        this.notification.error(errors.join('<br>'));
        this.info(`You tried to submit form with next errors: `, errors);
      }
      this.loading = false;
      this.onNavigationError.emit(this.process.get(`validator-${this.task.taskId}`));
      return Promise.reject(errors);
    });
  }

  getClickListener(item: DockItem): { click?: string } {
    return item.listeners.find((listener) => listener.hasOwnProperty('click'));
  }

  btnClass(btn: any, isMultiple: boolean = false): string {
    const btnClass = `${btn.btnClass || 'btn-primary'} ${this.dockType || ''}`;
    const multiple = isMultiple && `btn btn-col ${btnClass}`;
    const single = !isMultiple && `mx-3 btn btn-col btn-col-${this.items.length} ${btnClass}`;

    return single || multiple || '';
  }

  isClickable(selector): boolean {
    return (['submitRequest', 'save', 'saveAndClose'].indexOf(selector) !== -1) ? !(this.group && (this.group.pristine)) : true;
  }

  /**
   * Returns debug text for current component.
   */
  toString(): string {
    return Object.keys(this.debugData).map((key) => `${key}: ${this.debugData[key]}`).join('\n');
  }

  hideTooltip(tooltip) {
    tooltip.hide();
    console.log({
      ...this.debugData,
      '[DEBUG][Navigator] GridItem': JSON.stringify(this.gridItem || {}),
      '[DEBUG][Navigator] Task': this.task,
    });
  }

  get debugData() {
    return {
      isGrid: this.isGrid,
      type: this.type,
      pristine: this.pristine,
      isFirstLevel: this.firstLevel,
    };
  }

  get pristine(): boolean {
    return this.group && this.group.pristine;
  }

  get pristineError(): string {
    return 'Cannot save untouched form.';
  }

  get containerClass(): string {
    return `docked-buttons p-5 ${this.dockType}`;
  }

  get items(): any {
    return this._items;
  }

  get dockType(): string {
    return this._dockType;
  }

  get dock(): Dock[] {
    return this._dock;
  }

  get isGrid(): boolean {
    return this._isGrid;
  }

  get gridItem(): any {
    return this._gridItem;
  }

  private cancel() {
    this.onDone.emit({ close: true, group: this.group });
    return Promise.resolve(false);
  }

  private save() {
    this.loading = false;
    this.onDone.emit({ data: this.generateUserData(), action: 'onsave', group: this.group });
  }

  private saveClose() {
    this.loading = false;
    const data = this.isGrid ? this.gridItem : this.generateUserData();
    this.onDone.emit({ data, action: 'save-close', close: true, group: this.group });
  }

  private closeUndefined(isNone: boolean = false, action = 'none') {
    if (!isNone) {
      this.warn(`Unknown button clicked.`);
    }
    this.onDone.emit({ data: undefined, action, close: true, group: this.group });
    return Promise.resolve(false);
  }

  private doAction(action: string, userData: Object = {}) {
    const originalProcessId = this.process.get(`oldId-${this.processId}`);
    return this.process.task(originalProcessId || this.processId, this.stateId, { taskAction: action, userData });
  }

  private evalOnSave(task: Task, script: string) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const frm = new FormApi(this.group, this.process, task, this.notification);
        frm.init();
        try {
          // tslint:disable-next-line:no-eval
          return resolve(eval(script));
        } catch (e) {
          console.error('Error executing onsavescript', e);
          return reject(e);
        }
      }, 5);
    });
  }

  private _finally(promise: Promise<any>, func: Function | any) {
    return promise.then((res) => func(res)).catch((res) => func(res));
  }

  ngOnDestroy() {
    super.OnDestroy();
  }
}
