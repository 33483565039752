// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Japanese [ja]
//! author : LI Long : https://github.com/baryon

export const jaLocale: LocaleData =  {
  abbr: 'ja',
  months : '1æ_2æ_3æ_4æ_5æ_6æ_7æ_8æ_9æ_10æ_11æ_12æ'.split('_'),
  monthsShort : '1æ_2æ_3æ_4æ_5æ_6æ_7æ_8æ_9æ_10æ_11æ_12æ'.split('_'),
  weekdays : 'æ¥ææ¥_æææ¥_ç«ææ¥_æ°´ææ¥_æ¨ææ¥_éææ¥_åææ¥'.split('_'),
  weekdaysShort : 'æ¥_æ_ç«_æ°´_æ¨_é_å'.split('_'),
  weekdaysMin : 'æ¥_æ_ç«_æ°´_æ¨_é_å'.split('_'),
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss',
    L : 'YYYY/MM/DD',
    LL : 'YYYYå¹´MæDæ¥',
    LLL : 'YYYYå¹´MæDæ¥ HH:mm',
    LLLL : 'YYYYå¹´MæDæ¥ HH:mm dddd',
    l : 'YYYY/MM/DD',
    ll : 'YYYYå¹´MæDæ¥',
    lll : 'YYYYå¹´MæDæ¥ HH:mm',
    llll : 'YYYYå¹´MæDæ¥ HH:mm dddd'
  },
  meridiemParse: /åå|åå¾/i,
  isPM (input) {
    return input === 'åå¾';
  },
  meridiem (hour, minute, isLower) {
    if (hour < 12) {
      return 'åå';
    } else {
      return 'åå¾';
    }
  },
  calendar : {
    sameDay : '[ä»æ¥] LT',
    nextDay : '[ææ¥] LT',
    nextWeek : '[æ¥é±]dddd LT',
    lastDay : '[æ¨æ¥] LT',
    lastWeek : '[åé±]dddd LT',
    sameElse : 'L'
  },
  dayOfMonthOrdinalParse : /\d{1,2}æ¥/,
  ordinal (num: number, period: string): string {
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
        return num + 'æ¥';
      default:
        return num.toString(10);
    }
  },
  relativeTime : {
    future : '%så¾',
    past : '%så',
    s : 'æ°ç§',
    ss : '%dç§',
    m : '1å',
    mm : '%då',
    h : '1æé',
    hh : '%dæé',
    d : '1æ¥',
    dd : '%dæ¥',
    M : '1ã¶æ',
    MM : '%dã¶æ',
    y : '1å¹´',
    yy : '%då¹´'
  }
};
