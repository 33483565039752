import { Directive,  ElementRef, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EscapeSpecialSymbolsDirective),   // tslint:disable-line
  multi: true,
};

@Directive({ selector: '[escape-special-symbols]',
host: {
  '(change)': 'onChange($event.target.value)',
  '(input)': 'onChange($event.target.value)',
},
providers: [INPUT_VALUE_ACCESSOR]
})

export class EscapeSpecialSymbolsDirective implements ControlValueAccessor {

  @Input('escape-chars') SPECIAL_CHARS = ['#', '$', '%', '&', '\'', '*', '+', '-', 
                                          '>', '=', '<', ';', '^', '`', '{' , '|', '}', '~' ]
  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(private _elementRef: ElementRef) {}
 
  writeValue(value: string): void {
    this._elementRef.nativeElement.value = this.parseValue(value);
  }

  registerOnChange(fn: (any) => void): void {
    this.onChange = (value) => {
      value = this.parseValue(value);
      this._elementRef.nativeElement.value = value;
      fn(value);
    };
  }

  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  parseValue(value: string) {
    if (this.hasSpecialChars(value)) {
      this.SPECIAL_CHARS.forEach(char => value = value.replace(char, ''));
    }
    return value;
  }

  // parseRegularEx(value: string): string {
  //   return value.replace( new RegExp(this.regEx, 'g'), '');
  // }


  private hasSpecialChars(value:string) {
    if (!value || !value.length) {
      return false;
    }

    if (this.SPECIAL_CHARS.some(specialChar => value.includes(specialChar))) {
      return true;
    }
  }
}
