import { AfterViewInit, Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ProcessService } from '../../../../services/process.service';
import { FVAbstractControl } from '../../../shared/abstract-control.component';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./../controls.css', './check-box.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsCheckBoxComponent extends FVAbstractControl implements AfterViewInit {
  constructor(process: ProcessService) {
    super(process);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
  }

  get isCheckbox(): boolean {
    if (!this.control || !this.control.format) {
      return false;
    }
    return this.control.format.toLowerCase().indexOf('checkbox') !== -1;
  }
}
