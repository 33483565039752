import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  TabsModule,
} from 'ngx-bootstrap/tabs';
import {
  TypeaheadModule,
} from 'ngx-bootstrap/typeahead';
import {
  AccordionModule,
} from 'ngx-bootstrap/accordion';
import {
  BsDropdownModule,
} from 'ngx-bootstrap/dropdown';

import {
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import {
  TooltipModule,
} from 'ngx-bootstrap/tooltip';
import {
  PaginationModule,
} from 'ngx-bootstrap/pagination';
import {
  SortableModule
} from 'ngx-bootstrap/sortable';
import {
  ProgressbarModule
} from 'ngx-bootstrap/progressbar';
import {
  ModalModule
} from 'ngx-bootstrap/modal';


const bootstrapStuff = [
  TypeaheadModule,
  AccordionModule,
  BsDropdownModule,
  BsDatepickerModule,
  TooltipModule,
  TabsModule,
  PaginationModule,
  SortableModule,
  NgxPaginationModule,
  ModalModule,
  ProgressbarModule,
];

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...bootstrapStuff,
  ],
  exports: [
    ...bootstrapStuff,
  ],
  providers: [],
})
export class NGXBootstrapModule {
  public static forRoot(): ModuleWithProviders<NGXBootstrapModule> {
    return {
      ngModule: NGXBootstrapModule,
      providers: [
        TypeaheadModule.forRoot().providers,
        AccordionModule.forRoot().providers,
        BsDropdownModule.forRoot().providers,
        BsDatepickerModule.forRoot().providers,
        TooltipModule.forRoot().providers,
        TabsModule.forRoot().providers,
        PaginationModule.forRoot().providers,
        SortableModule.forRoot().providers,
        ModalModule.forRoot().providers,
        ProgressbarModule.forRoot().providers,
      ],
    };
  }
}
