import { Injectable, EventEmitter } from '@angular/core';
import { StorageService } from '@dotgov/core';
import { ObjectLiteral } from '../models/objectLiteral';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Injectable()
export class FVFormBuilderService extends StorageService {
  onFocus = new EventEmitter<any>();
  onFocusLost = new EventEmitter<any>();

  private _canRunProcessBuilder: boolean;
  private _processBuilderStatus: boolean;
  private _processBuilderStatusChanged = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  get processBuilderStatus(): boolean {
    return this._processBuilderStatus;
  }

  get canRunBuilder(): boolean {
    return this._canRunProcessBuilder;
  }

  focus(event: any) {
    this.onFocus.emit(event);
  }

  loseFocus(event: any) {
    this.onFocusLost.emit(event);
  }

  set processBuilder(value: boolean) {
    if (!this._canRunProcessBuilder) {
      return;
    }
    this._processBuilderStatus = value;
    this._processBuilderStatusChanged.emit(value);
  }

  protected set _canRunBuilder(userData: ObjectLiteral) {
    const isAdmin = userData && userData.isAdmin;
    this._canRunProcessBuilder = userData ? isAdmin : false;
  }
}
