import { Component, Input, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { DroppableDirective, DraggableDirective } from '@swimlane/ngx-dnd';

@Component({
  selector: 'grid-query-editor',
  templateUrl: './query-editor.component.html',
  styleUrls: ['./query-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QueryEditorComponent implements AfterViewInit {
  @Input()
  set config(config) {
    this._config = config;
  }

  private _config: any = {};

  // @Input()
  @ViewChild(DroppableDirective)
  droppable: any;

  // @Input()
  @ViewChild(DraggableDirective)
  draggable: any;


  constructor() { }

  ngAfterViewInit() { }

  get config() {
    return this._config;
  }

  get columns() {
    return this._config.availableColumns || [];
  }

  get queries() {
    return this._config.queries || [];
  }

  get operators() {
    return this._config.operators || [
      { name: 'Equal', value: '=' },
      { name: 'Not Equal', value: '!=' },
      { name: 'Lower', value: '<' },
      { name: 'Lower Equal', value: '<=' },
      { name: 'Greater', value: '>' },
      { name: 'Greater Equal', value: '>=' },
      { name: 'Like', value: '%=%' },
      { name: 'Starts With', value: '%=' },
      { name: 'Ends With', value: '=%' },
    ];
  }

  get connections() {
    return this._config.connections || [
      { name: 'And', value: 'and' },
      { name: 'Or', value: 'or' },
    ];
  }

  get subgroupSettings() {
    return this.config && this.config.group || {};
  }

  queryUpdated(event, queryGroup, query, type: string) {
    // handle changes here
    setTimeout(() => {
      query[type] = event.target.value;
    });
  }

  createClause(group) {
    const defaultConnection = this.config && this.config.connections && this.config.connections[0] && this.config.connections[0].value;
    group.queries.push({ connection: defaultConnection, attribute: '', operator: '', value: '' });
  }

  createGroup(group?: any) {
    if (group) {
      if (!group.queries || group.queries.length < (this.subgroupSettings.minForGroup || 2)) {
        return;
      }
      group.queries.push({
        queries: [
          {
            queries: [],
          }
        ]
      }
      );
      return;
    }
    this._config.queries.push({ queries: [] });
  }

  remove(queryGroup, groupIndex) {
    queryGroup.queries = queryGroup.queries.filter((gr, index) => index !== groupIndex);
  }

  removeGroup(queryGroup, groupIndex) {
    if (!queryGroup || !queryGroup.queries) {
      return;
    }
    queryGroup.queries = queryGroup.queries.filter((gr, index) => index !== groupIndex);
  }
}
