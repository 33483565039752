import { Breadcrumbps } from './breadcrumbs';
import { ComponentConfig } from './componentConfig';
import { Dock } from './dock';
import { FormGroup } from '@angular/forms';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Task {
  static TASK_DONE_STATUS = 1;
  constructor(
    public breadcrumbps?: Breadcrumbps,
    public clientComponent?: string,
    public componentConfig?: ComponentConfig,
    public dockedItems?: Dock[],
    public processInstanceId?: string,
    public processName?: string,
    public processProgress?: {
      current: number,
      total: number,
    },
    public stateId?: string,
    public status?: number,
    public taskId?: string,
    public id?: string,
    public componentClassName?: string,
  ) { }
}


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class FVDoneResponse {
  constructor(
    public done?: boolean,
    public close?: boolean,
    public data?: Object,
    public action?: string,
    public group?: FormGroup,
    public manual?: boolean,
  ) { }
}
