import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm, AbstractControl } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';
import { LanguageService } from '../../../../services/language.service';
import { ValidationService } from '../../../../services/validation.service';
import { ApiService, ApiResponse, IDGSChangeSet } from '@dotgov/core';
import { Control, GridConfig, BindingSet, Dock, ControlType, Task } from '../../../../models';
import { Helper } from './../../../shared/helper';
import { ObjectLiteral } from '../../../../models/objectLiteral';
import { isEmpty } from 'lodash';
/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./../controls.css', './lookup.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsLookupComponent extends FVAbstractControl implements AfterViewInit {
  lookupGrid: GridConfig;
  showModal = false;
  loading = true;
  multipleData: any = {
    original: {},
    active: [],
    options: [],
  };

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private validationService: ValidationService,
    private language: LanguageService,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
    if (this.isMultilookup) {
      this.openLookup(this.control, true);
    }
    this.ref.detectChanges();
    this.loading = false;
    this.process.onChange.subscribe((changes: IDGSChangeSet) => {
      if (changes.changeName === `lookup-${this.control.referenceName}`) {
        this.setValue(changes.changeVal, true);
      }
    });
  }

  /**
   * Update lookup on custom grid output.
   * @param response
   */
  onAdvancedGridResponse(response?: any) {
    if (response === undefined) {
      this.modalClosed();
      return;
    }
    const { keyfield, row } = response;
    this.setValue(keyfield, true, row);
    this.modalClosed();
  }

  modalClosed() {
    this.lookupGrid = undefined;
    this.showModal = false;
    this.ref.detectChanges();
  }

  /**
   * Removes value from multi-lookup.
   * @param id
   */
  removeValue(id: any) {
    this.multipleData.active = this.multipleData.active.filter((val) => val !== id);
    this.formControl.setValue(this.multipleData.active);
  }

  /**
   * Returns name of row based on id.
   */
  multiName(id: any): string {
    return this.multipleData.original[id];
  }

  /**
   * Check if there is some value typed inside multi-lookup.
   * Placeholder not shown when there is some selected value but no text typed.
   * @param div
   */
  isTextTyped(div): boolean {
    if (!div || !div.hostElement || !div.hostElement.nativeElement) {
      return;
    }
    const input = div.hostElement.nativeElement.querySelector('input');
    return (input || {}).value;
  }

  /**
   * Open lookup based on control settings.
   * @param control
   * @param multi
   */
  openLookup(control: Control, multi: boolean = false) {
    // Checks need only by simple lookup
    if (!multi) {
      if (this.formControl) {
        this.formControl.markAsTouched();
        this.validationService.update();
      } else {
        this.warn('Lookup been clicked with no control info.');
      }
      // Delete old modal if found
      this.modalClosed();
      // Should not open, exit
      if (this.isReadOnly || this.disabled) {
        return;
      }
    }
    const reference = this.controlRef() || {};
    // Have no reference, exit
    if (!reference.extract || !reference.extract.table) {
      this.warn(`Missing 'extract' or 'extract.table' from lookup ${reference.referenceName}.`);
      return;
    }

    const request = `${reference.extract.table}-${reference.extract.view || 'default'}`;
    const filters = (control.bindingSet || reference.bindingSet || []).map((bindingSet) => {
      return this.parseBindingSet(bindingSet);
    }).filter((filter) => !!filter);
    this.updateLoading(true);

    this.api.get('lookup', [request], {}, { skipCache: true }).then((response: ApiResponse) => {
      this.errorMsg = this.process.errorFromResponse(response);
      if (this.errorMsg) {
        this.error(this.errorMsg);
        this.updateLoading(false);
        return;
      }
      if (multi) {
        return this.fetchMultiLookupData(response.data, filters);
      }
      this.lookupGrid = response.data;
      this.lookupGrid.filters = filters.length ? filters : undefined;
      this.lookupGrid.parent = control;
      this.lookupGrid.model = reference;
      this.lookupGrid.componentConfig.dockedItems = [...this.defaultDockedBottom];
      this.showModal = true;
      setTimeout(() => {
        this.updateLoading(false);
      }, 100);
      this.ref.detectChanges();
    });
  }

  get defaultDockedBottom(): Dock[] {
    return [
      new Dock('bottom', [
        { iconCls: '', listeners: [{ click: 'onclose' }], text: 'Close', btnClass: 'btn-danger' },
        { iconCls: '', listeners: [{ click: 'none' }], text: 'Clear', btnClass: 'btn-basic' },
        { iconCls: 'fa fa-save', listeners: [{ click: 'save-close' }], text: 'Save and close', btnClass: 'btn-success' },
      ]),
    ];
  }

  get isMultilookup(): boolean {
    return this.control.showAs === ControlType.EFormElement.MultiLookup;
  }

  get formControl(): AbstractControl {
    return this.group.controls[this.control.key];
  }

  /**
   * Fetch values for multi-lookup.
   * @param task
   * @param filters
   */
  protected fetchMultiLookupData(task: Task, filters: any[]) {
    if (!task || !task.componentConfig || !task.componentConfig.store || !task.componentConfig.store.proxy) {
      this.updateLoading(false);
      return;
    }
    const invalidFilters = filters.filter((filter => filter.value === 'null'));
    if (invalidFilters.length) {
      this.updateLoading(false);
      return this.warn('You have tried to load lookup data with invalid filter', JSON.stringify(invalidFilters));
    }
    const params = {
      start: 0,
      page: 0,
      limit: 0,
      filter: filters,
      fromForm: true,
    };
    this.api.get(task.componentConfig.store.proxy.url, [], params, { skipCache: true }).then((result: ApiResponse) => {
      const error = this.process.errorFromResponse(result);
      if (error) {
        this.updateLoading(false);
        return;
      }
      const original = {};
      (result.data && result.data.data || []).forEach((item) => {
        original[item.Keyfield.toString()] = item.Name;
      });
      this.multipleData.original = original;
      this.multipleData.options = this.getMultiOptions(original);
      // Removing active if they wont match
      this.multipleData.active = this.multipleData.active.filter((id) => this.multipleData.options.find((option) => option.value === id));
      this.updateLoading(false);
    });
  }

  /**
   * Protected because override property must have same access level as super
   * @param _value
   */
  protected setValue(_value?: string, forced: boolean = false, row?: any) {
    const value = forced ? _value : _value || this.task.componentConfig.recordData[this.control.referenceName];
    const refTitle = this.task.componentConfig.recordData[`${this.control.referenceName}_ReferenceTitle`];
    if (value === null) {
      this.updateValue(null);
      return;
    }
    if (!value && refTitle) {
      this.updateValue(refTitle);
      this.ref.detectChanges();
      return;
    }
    if (!value) {
      return;
    }
    const reference = this.controlRef();
    if (!reference.extract) {
      this.warn(`Control ${reference.referenceName} has no extract data!`);
      return;
    }
    const lang = this.language.language && this.language.language.Code;
    const suffix = lang && lang !== 'en' ? `_${lang}` : '';
    const request = `${reference.extract.table}-${reference.extract.view || 'default'}${suffix}`;
    this.process.set(`${this._control.referenceName}-value`, value, this.task && this.task.taskId || this.task.id);
    this.process.set(`${this._control.key}-value`, value, this.task && this.task.taskId || this.task.id);
    this.setAdvancedValue(value, row);
    if (_value !== undefined && this.group) {
      this.group['form'].markAsDirty();
    }
    if (!this.isMultilookup) {
      return this.api.get('data', ['referencetitle', request, value]).then((response: ApiResponse) => {
        this.errorMsg = this.process.errorFromResponse(response);
        if (this.errorMsg) {
          this.error(this.errorMsg);
          return;
        }
        const emitVal = response.data && response.data.data || response.data;
        this.updateValue(emitVal, false);
      });
    }
    this.updateValue(value, false);
  }

  private parseBindingSet(bindingSet: BindingSet): { property: string, value: string } | {} {
    const refControl: Control = this.process.get(`control-${bindingSet.from}`) || this.controlRef();
    if (!refControl) {
      return;
    }
    const property = bindingSet.to;
    let value = this.process.get(`${refControl.key}-value`) || this.group.value[refControl.key] || this.recordData[property];
    const origin = this.parentTask;
    if (!value && origin) {
      const parentControl = this.findNested(origin, property) || {};
      if (!parentControl || !parentControl.key) {
        return {};
      }
      const parentDefault = (origin && origin.componentConfig && origin.componentConfig.recordData || {})[property];
      const parentVal = this.process.get(`${parentControl.key}-value`) || parentDefault;
      if (!parentVal) {
        return {};
      }
      value = parentVal;
    }
    let result = {};
    if (!!value)
      result = { property, value: value};
    return result; 
  }

  /**
   * Return {ref} if it was found in {origin};
   * @param origin
   * @param ref
   */
  private findNested(origin, ref): ObjectLiteral {
    if (!origin) {
      return;
    }
    if (origin.referenceName === ref) {
      return origin;
    }
    let result, property;
    for (property in origin) {
      if (origin.hasOwnProperty(property) && typeof origin[property] === 'object') {
        result = this.findNested(origin[property], ref);
        if (result) {
          return result;
        }
      }
    }
    return result;
  }

  /**
   * Update value for form and process.
   * @param _value
   * @param updateProcess
   */
  private updateValue(_value: any, updateProcess: boolean = true) {
    const value = Helper.sanitizeLookupValue(_value, this.control);
    if (updateProcess) {
      this.process.set(`${this._control.referenceName}-value`, value, this.task && this.task.taskId || this.task.id);
      this.process.set(`${this._control.key}-value`, value, this.task && this.task.taskId || this.task.id);
    }
    if (value && typeof value === 'string' && isNaN(Number(value))) {
      return this.language.getTranslate(value).then((translate) => {
        // No translate found
        if (translate === value.toLowerCase()) {
          translate = value;
        }
        this.updateGroup.emit({ key: this.control.key, value: translate });
      });
    }
    if (this.isMultilookup) {
      this.multipleData = {
        original: _value,
        sanitized: value,
        options: this.getMultiOptions(_value),
        active: this.multipleData.active,
      };
      this.updateGroup.emit({ key: this.control.key, value });
    }
    this.updateGroup.emit({ key: this.control.key, value });
  }

  private updateLoading(status) {
    this.process.setLoading(this.processId, status);
    this.loading = status;
  }

  private getMultiOptions(value): { value: string, label: string }[] {
    return Object.keys(value).map((id) => {
      const item = value[id];
      return { value: item.Keyfield || id, label: item.Name || item };
    });
  }

  async setAdvancedValue(value: string, row: any) {
    row = !!row ? Promise.resolve(row) : this.fetchAdvancedValue(value);

    this.process.set(
      `${this._control.key}-value-advanced`,
      row,
      (this.task && this.task.taskId) || this.task.id);
  }

  async fetchAdvancedValue(key) {
    let row;
    // fetch row
    const reference = this.controlRef() || {};
    const request = `${reference.extract.table}-${
      reference.extract.view || 'default'
      }`;
    let lookupGrid = this.lookupGrid;

    if (!lookupGrid) {
      const response = await this.api.get(
        'lookup',
        [request],
        {}
      );
      if (response && response.data) {
        lookupGrid = response.data;
      }
    }

    const filters = (this.control.bindingSet || reference.bindingSet || [])
    .map((bindingSet) => {
      let parsedSet = this.parseBindingSet(bindingSet);
      if(!isEmpty(parsedSet))
        return parsedSet;

    })
    .filter((f) => !!f);
    
    let res: any = {};

    if(!!key && !!filters.length){
      const filter = [...filters, { property: 'keyfield', value: key }];
      const params = { page: 0, start: 0, limit: 1, filter };
      res = await this.api.get(lookupGrid.componentConfig.store.proxy.url, [], params, { skipCache: true });
    }

    if (res.data && res.data.data && res.data.data.length) {
      row = res.data.data[0];
    } else {
      console.warn('no data found for lookup row with id: ', key);
    }
    return row;
  }
}
