import { Path } from './path';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Breadcrumbps {
  constructor(
    public current?: string,
    public path?: Path,
  ) { }
}
