// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
// https://github.com/moment/moment/blob/develop/locale/fi.js

var numbersPast = 'nolla yksi kaksi kolme neljÃ¤ viisi kuusi seitsemÃ¤n kahdeksan yhdeksÃ¤n'.split(' '),
  numbersFuture = [
    'nolla', 'yhden', 'kahden', 'kolmen', 'neljÃ¤n', 'viiden', 'kuuden',
    numbersPast[7], numbersPast[8], numbersPast[9]
  ];

function translate(num: number, withoutSuffix: boolean, key: string, isFuture: boolean): string {
  var result = '';
  switch (key) {
    case 's':
      return isFuture ? 'muutaman sekunnin' : 'muutama sekunti';
    case 'ss':
      return isFuture ? 'sekunnin' : 'sekuntia';
    case 'm':
      return isFuture ? 'minuutin' : 'minuutti';
    case 'mm':
      result = isFuture ? 'minuutin' : 'minuuttia';
      break;
    case 'h':
      return isFuture ? 'tunnin' : 'tunti';
    case 'hh':
      result = isFuture ? 'tunnin' : 'tuntia';
      break;
    case 'd':
      return isFuture ? 'pÃ¤ivÃ¤n' : 'pÃ¤ivÃ¤';
    case 'dd':
      result = isFuture ? 'pÃ¤ivÃ¤n' : 'pÃ¤ivÃ¤Ã¤';
      break;
    case 'M':
      return isFuture ? 'kuukauden' : 'kuukausi';
    case 'MM':
      result = isFuture ? 'kuukauden' : 'kuukautta';
      break;
    case 'y':
      return isFuture ? 'vuoden' : 'vuosi';
    case 'yy':
      result = isFuture ? 'vuoden' : 'vuotta';
      break;
  }
  result = verbalNumber(num, isFuture) + ' ' + result;
  return result;
}

function verbalNumber(num: number, isFuture: boolean) {
  return num < 10 ? (isFuture ? numbersFuture[num] : numbersPast[num]) : num;
}

export const fiLocale: LocaleData = {
  abbr: 'fi',
  months: 'tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesÃ¤kuu_heinÃ¤kuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu'.split('_'),
  monthsShort: 'tammi_helmi_maalis_huhti_touko_kesÃ¤_heinÃ¤_elo_syys_loka_marras_joulu'.split('_'),
  weekdays: 'sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai'.split('_'),
  weekdaysShort: 'su_ma_ti_ke_to_pe_la'.split('_'),
  weekdaysMin: 'su_ma_ti_ke_to_pe_la'.split('_'),
  longDateFormat: {
    LT: 'HH.mm',
    LTS: 'HH.mm.ss',
    L: 'DD.MM.YYYY',
    LL: 'Do MMMM[ta] YYYY',
    LLL: 'Do MMMM[ta] YYYY, [klo] HH.mm',
    LLLL: 'dddd, Do MMMM[ta] YYYY, [klo] HH.mm',
    l: 'D.M.YYYY',
    ll: 'Do MMM YYYY',
    lll: 'Do MMM YYYY, [klo] HH.mm',
    llll: 'ddd, Do MMM YYYY, [klo] HH.mm'
  },
  calendar: {
    sameDay: '[tÃ¤nÃ¤Ã¤n] [klo] LT',
    nextDay: '[huomenna] [klo] LT',
    nextWeek: 'dddd [klo] LT',
    lastDay: '[eilen] [klo] LT',
    lastWeek: '[viime] dddd[na] [klo] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: '%s pÃ¤Ã¤stÃ¤',
    past: '%s sitten',
    s: translate,
    ss: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4  // The week that contains Jan 4th is the first week of the year.
  }
};
