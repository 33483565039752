import { DockItem } from './dockItem';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Dock {
  constructor(
    public dock?: string,
    public items?: DockItem[],
  ) { }
}
