import { Tab } from './tab';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Item {
  constructor(
    public tabs?: Tab[],
    public handlers?: { onloadscript: string, onsavescript: string },
  ) { }
}
