import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Injectable()
export class TranspilationService {
  constructor(private language: LanguageService) { }

  /**
   * Resolve all the interpolations and pipes from given html.
   * For now it can recognize only translate pipe.
   * @param html
   */
  transpileHtml(html: string): Promise<string> {
    const rawMatches = html.match(this.pattern) || [];
    const matches = rawMatches.map(this.sanitize);
    return new Promise((resolve) => {
      const promises: Promise<any>[] = [];
      matches.forEach((match) => {
        if (match.indexOf('|') !== -1) {
          promises.push(this.resolvePipe(match));
        } else {
          promises.push(Promise.resolve(match));
        }
      });
      Promise.all(promises).then((results) => {
        rawMatches.forEach((rawMatch, rawIndex) => {
          html = html.replace(rawMatch, results[rawIndex]);
        });
        return resolve(html);
      });
    });
  }

  /**
   * Resolve pipe by match. ( Ex: 'some_key | ltranslate' ).
   * Can only deal with translate pipe for now.
   * @param match
   */
  resolvePipe(match: string) {
    if (match.endsWith('|translate')) {
      const target = match.split('|')[0].trim() || '';
      return new Promise((resolve) => {
        return resolve(this.language.getTranslate(target));
      });
    }
    return Promise.resolve('unknown pipe');
  }

  /**
   * Remove any of [whitespaces, ', {, }];
   * @param match
   */
  sanitize(match: string): string {
    return match.replace(/[ '{}]/g, '');
  }

  // Match html for any interpolations like <h1>{{ test }}</h1> and returnes them as group 1
  get pattern(): RegExp {
    return /{{(['"]?[A-Za-z_\s0-9]+)?(['"])?([\s|]?)+([A-Za-z_\s]+)(['"])?}}/g;
  }

}
