import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../shared/abstract-control.component';
import { ProcessService } from '../../../services/process.service';
import { Menu, Folder } from '../../../models/menu';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./../controls/controls.css', './menu.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsMenuComponent extends FVAbstractControl {
  @Output() onFolderClick = new EventEmitter<Folder>();

  protected _menu: Menu;
  @Input() set menu(menu: Menu) {
    this._menu = menu;
  }

  protected _designer: any;
  @Input() set designer(designer: any) {
    this._designer = designer;
  }

  protected _activeNode: string;
  @Input() set activeNode(activeNode: string) {
    this._activeNode = activeNode;
  }

  get menu(): Menu {
    return this._menu;
  }

  get designer(): any {
    return this._designer;
  }

  get activeNode(): string {
    return this._activeNode;
  }

  constructor(process: ProcessService) {
    super(process);
  }

  folderClicked(folder: Folder) {
    this.onFolderClick.emit(folder);
  }
}
