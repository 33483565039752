import { Helper as DGSHelper } from '@dotgov/core';

export class FormatHelper {
  constructor() { }

  static Format(value, type) {
    if (type === 'date' || type === 'datetime') {
      return FormatHelper.FormatDate(value, type === 'datetime');
    }
    return value;
  }

  static FormatDate(date, showTime?: boolean) {
    return DGSHelper.formatDate(date, showTime);
  }
}
