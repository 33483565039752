import { Component, AfterViewInit, ViewChild, ChangeDetectorRef, ViewContainerRef, Inject } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';
import { FactoryService } from '@dotgov/core';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-custom-control',
  templateUrl: './custom-control.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsCustomControlComponent extends FVAbstractControl implements AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef }) container;

  constructor(
     @Inject('componentFactories') private componentFactories: any,
    private ref: ChangeDetectorRef,
    private factoryService: FactoryService,
    process: ProcessService,
  ) {
    super(process);
  }

  /**
   * Setup configuration for component to be rendered.
   */
  ngAfterViewInit() {
    super.AfterViewInit();
    if (!this.control || !this.control.controlName) {
      this.warn(`Detected custom control with no controlName field.`, this.control);
      return;
    }
    this.debug(`Rendering ${this.control.controlName} using factory.`);
    const inputs = {
      task: this.task,
      control: this.control,
      stateId: this.stateId,
      processId: this.processId,
      group: this.group,
    };
    this.factoryService.componentbySelector(this.control.controlName, this.componentFactories, this.container, inputs);
    this.ref.detectChanges();
  }

  /**
   * Returns debug text for current component.
   */
  toString(): string {
    return `${super.toString()}
    controlName: ${this.control.controlName}`;
  }

}
