import { Component, AfterViewInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ProcessService } from '../../../../services/process.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LanguageService } from '../../../../services/language.service';
import { FVControlsAbstractTimeComponent } from '../../../shared/date.component';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-date',
  templateUrl: './date.component.html',
  styleUrls: ['../controls.css', './styles/main.css', './styles/themes.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  encapsulation: ViewEncapsulation.None,
})
export class FVControlsDateComponent extends FVControlsAbstractTimeComponent implements AfterViewInit, OnDestroy {
  constructor(
    process: ProcessService,
    _localeService: BsLocaleService,
    language: LanguageService,
  ) {
    super(process, _localeService, language);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
  }

  ngOnDestroy() {
    super.OnDestroy();
  }

}
