
/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class DockItem {
  constructor(
    public dialogData?: any,
    public disabled?: any,
    public iconCls?: string,
    public isDblClick?: any,
    public listeners?: { click?: string }[],
    public reference?: any,
    public referenceName?: any,
    public selectionEnable?: any,
    public text?: string,
    public tooltip?: string,
    public btnClass?: string,
    public selector?: string,
  ) { }
}
