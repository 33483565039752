import { IDGSChangeSet } from '@dotgov/core';
import { Component, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsProgressBarComponent extends FVAbstractControl implements OnDestroy {
  private _progress: number;

  constructor(private process: ProcessService) {
    super(process);

    this.subscriptions.push(this.process.onChange.subscribe((changeSet: IDGSChangeSet) => {
      if (changeSet.changeName !== 'progressBar' || !changeSet.changeVal || !changeSet.changeVal.hasOwnProperty('referenceName')) {
        return;
      }
      const ref = changeSet.changeVal.referenceName;
      if (ref === this.control.referenceName) {
        this._progress = changeSet.changeVal.progress;
      }
    }));
  }

  get progress(): number {
    return this._progress;
  }

  ngOnDestroy() {
    super.OnDestroy();
  }
}
