import { Component, Input, ViewChild, EventEmitter, Output, OnDestroy, HostListener } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subscription, Subject } from 'rxjs/index';
import { throttleTime } from 'rxjs/operators';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['../controls/controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVModalComponent implements OnDestroy {
  hideDebouncer: Subject<any> = new Subject();
  @Input() set showModal(show: boolean) {
    if (this._showModal !== show) {
      if (show === true) {
        this.openModal();
      } else {
        this.hideDebouncer.next();
      }
    }
  }
  @Input() keyboardPrompt: boolean;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() modalClass: string;
  @Input() id: string;

  @Output() onModalClose: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalRef') modalRef;

  private _showModal: boolean;
  private modal;
  private oldState;
  private subscriptions: Subscription[] = [];
  constructor(
    private modalService: BsModalService,
  ) {
    this.subscriptions.push(this.modalService.onHidden.subscribe(() => { this.hideDebouncer.next(); }));

    // Throttle for preventing executing same function 2+ times per 100ms
    this.subscriptions.push(this.hideDebouncer.pipe(throttleTime(200)).subscribe(() => { this.doCheck(); }));
  }

  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
    if (this.modalService.getModalsCount() > 1) {
      return;
    }
    if (!this.keyboardPrompt) {
      return;
    }
    if (event.keyCode === 27) {
      event.preventDefault();
    }
    const wnd = window.confirm('Are you sure you want to close the modal window?');
    if (this.modal && wnd) {
      this.closeModal();
    }
  }

  doCheck(timeout: number = 150) {
    setTimeout(() => {
      if (!this.id) {
        return;
      }
      const elem = document.getElementById(this.id);
      const newState = elem ? true : null;
      if (newState !== this.oldState) {
        if (!newState) {
          this.closeModal();
        }
        this.oldState = newState;
      }
    }, timeout);
  }

  closeModal() {
    if (this.modal) {
      this.modal.hide();
      this.onModalClose.emit(true);
      this.modal = undefined;
    }
  }

  private openModal() {
    // To be sure that inputs have loaded
    setTimeout(() => {
      this.modal = this.modalService.show(this.modalRef, {
        class: this.modalClass || 'modal-lg',
        ignoreBackdropClick: true,
        keyboard: !this.keyboardPrompt,
        backdrop: true,
      });
    }, 1);
  }

  ngOnDestroy() {
    this.closeModal();
    this.loading = true;
    this.onModalClose.emit(true);

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
