export class Helper {
  static validValue(val) {
    const type = typeof val;
    if (val === undefined) {
      return false;
    } else if (type === 'string') {
      return val !== '';
    } else if (type === 'number') {
      return Number.isFinite(val);
    } else {
      return true;
    }
  }

  /**
   * @param _date
   * @param showTime Decide if we should show the time as well or not
   * @param forControl Decide if should return format dd/mm/yyyy
   * @returns YYYY-MM-DD HH:mm
   */
  static formatDate(_date: any, showTime = true, forControl = false) {
    if (!_date) {
      return;
    }
    const date = Helper.convertToDate(_date);
    const pad = (n) => n <= 9 ? `0${n}` : n;
    const time = ` ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    if (forControl) {
      return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}${showTime ? time : ''}`.trim();
    }
    // For .post send MM/dd/yyyy HH:mm:ss.
    return `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${date.getFullYear()}${showTime ? time : ''}`.trim();
  }

  static stringToDate(strDate: string, dayFirst: boolean = false) {
    // IE11 fix, if match: "2018-03-14 04:31:56.142"
    // Invalid date fix: 01/02/2018 => 01, 02, 2017; 2018-02-01 => 2018, 02, 01
    let res = strDate.match(/\d+/igm).map((res) => Number(res));
    let [MM, dd, YYYY, HH, mm, ss] = res;
    if (dayFirst) {
      [dd, MM, YYYY] = res;
    } else if (MM > 12) {
      // First was year, transforming to correspond.
      [YYYY, MM, dd] = res;
    }
    const sanitize = (num) => num !== undefined ? num : 0;
    return new Date(sanitize(YYYY), sanitize(Number(MM) - 1), sanitize(dd), sanitize(HH), sanitize(mm), sanitize(ss));
  }

  static convertToDate(d: any) {
    if (d === undefined || d === null) {
      return NaN;
    }
    const isDate = d.constructor === Date;
    const isArray = d.constructor === Array;
    const isNum = d.constructor === Number;
    const isString = d.constructor === String;
    const isObject = typeof d === 'object';
    return (
      isDate && d
      || isArray && new Date(d[0], d[1], d[2])
      || isNum && new Date(d)
      || isString && Helper.stringToDate(d)
      || isObject && new Date(d.year, d.month, d.date)
      || NaN
    );
  }

  static camelize(str: string) {
    const capitalize = (_str) => _str.charAt(0).toUpperCase() + _str.toLowerCase().slice(1);
    const string = str.toLowerCase().replace(/[^A-Za-z0-9]/g, ' ').split(' ')
      .reduce((result, word) => result + capitalize(word.toLowerCase()));
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

}
