// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Hebrew [he]
//! author : Tomer Cohen : https://github.com/tomer
//! author : Moshe Simantov : https://github.com/DevelopmentIL
//! author : Tal Ater : https://github.com/TalAter

export const heLocale: LocaleData = {
  abbr: 'he',
  months: '×× ×××¨_×¤××¨×××¨_××¨×¥_××¤×¨××_×××_××× ×_××××_×××××¡×_×¡×¤××××¨_×××§××××¨_× ×××××¨_××¦×××¨'.split('_'),
  monthsShort: '×× ××³_×¤××¨×³_××¨×¥_××¤×¨×³_×××_××× ×_××××_××××³_×¡×¤××³_×××§×³_× ×××³_××¦××³'.split('_'),
  weekdays: '×¨××©××_×©× ×_×©×××©×_×¨×××¢×_××××©×_×©××©×_×©××ª'.split('_'),
  weekdaysShort: '××³_××³_××³_××³_××³_××³_×©×³'.split('_'),
  weekdaysMin: '×_×_×_×_×_×_×©'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [×]MMMM YYYY',
    LLL: 'D [×]MMMM YYYY HH:mm',
    LLLL: 'dddd, D [×]MMMM YYYY HH:mm',
    l: 'D/M/YYYY',
    ll: 'D MMM YYYY',
    lll: 'D MMM YYYY HH:mm',
    llll: 'ddd, D MMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[×××× ×Ö¾]LT',
    nextDay: '[×××¨ ×Ö¾]LT',
    nextWeek: 'dddd [××©×¢×] LT',
    lastDay: '[××ª××× ×Ö¾]LT',
    lastWeek: '[××××] dddd [××××¨×× ××©×¢×] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: '××¢×× %s',
    past: '××¤× × %s',
    s: '××¡×¤×¨ ×©× ×××ª',
    ss: '%d ×©× ×××ª',
    m: '××§×',
    mm: '%d ××§××ª',
    h: '×©×¢×',
    hh(num: number): string {
      if (num === 2) {
        return '×©×¢×ª×××';
      }
      return num + ' ×©×¢××ª';
    },
    d: '×××',
    dd(num: number): string {
      if (num === 2) {
        return '××××××';
      }
      return num + ' ××××';
    },
    M: '××××©',
    MM(num: number): string {
      if (num === 2) {
        return '××××©×××';
      }
      return num + ' ××××©××';
    },
    y: '×©× ×',
    yy(num: number): string {
      if (num === 2) {
        return '×©× ×ª×××';
      } else if (num % 10 === 0 && num !== 10) {
        return num + ' ×©× ×';
      }
      return num + ' ×©× ××';
    }
  },
  meridiemParse: /×××"×¦|××¤× ×"×¦|×××¨× ××¦××¨×××|××¤× × ××¦××¨×××|××¤× ××ª ×××§×¨|××××§×¨|××¢×¨×/i,
  isPM(input) {
    return /^(×××"×¦|×××¨× ××¦××¨×××|××¢×¨×)$/.test(input);
  },
  meridiem(hour, minute, isLower) {
    if (hour < 5) {
      return '××¤× ××ª ×××§×¨';
    } else if (hour < 10) {
      return '××××§×¨';
    } else if (hour < 12) {
      return isLower ? '××¤× ×"×¦' : '××¤× × ××¦××¨×××';
    } else if (hour < 18) {
      return isLower ? '×××"×¦' : '×××¨× ××¦××¨×××';
    } else {
      return '××¢×¨×';
    }
  }
};
