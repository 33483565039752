import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class StorageService {
  private _storage: Object = {};
  private _inProgress: Object = {};

  constructor() { }

  get(key: string): any {
    return this._storage[key];
  }

  set(key: string, value: any) {
    this._storage[key] = value;
  }

  remove(key: string) {
    delete this._storage[key];
  }

  removeMatched(match: string) {
    Object.keys(this._storage).forEach((key) => {
      if (key.indexOf(match) !== -1) {
        this.remove(key);
      }
    });
    Object.keys(this._inProgress).forEach((key) => {
      if (key.indexOf(match) !== -1) {
        this.deleteProgress(key);
      }
    });
  }

  clearCache() {
    this._inProgress = {};
    this._storage = {};
  }

  protected getProgress(key: string): EventEmitter<any> | undefined | null {
    return this._inProgress[key];
  }

  protected deleteProgress(key: string) {
    delete this._inProgress[key];
  }

  protected setProgress(key: string) {
    this._inProgress[key] = new EventEmitter<any>();
  }
}
