import {
  Component,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../shared/abstract-control.component';
import { ApiService, ApiResponse, DGSNotificationService, Lang } from '@dotgov/core';
import { ProcessService } from '../../../services/process.service';
import { LanguageService } from '../../../services/language.service';
import { GridConfig, GridColumn, GridSort, GridFilter, Dock } from '../../../models';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs/index';
import { Helper } from '../../shared';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['../controls/controls.css', './grid.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsGridComponent extends FVAbstractControl implements AfterViewInit, OnDestroy {
  loading: boolean;
  lookupRows: Object[] = [];
  itemsPerPage: number;
  downloadPages = 4;
  currentPage = 0;
  totalItems = 0;

  searchCol = '';
  searchVal = '';
  searchEvent = new Subject<any>();
  isReloaded: boolean;

  theadSize = 38;
  hasRows = undefined;

  @ViewChild('theadContainer') theadContainer;
  @ViewChild('searchInput') searchInput;

  private _sort = {};
  private _multiSort = false;
  private _destroyed: boolean;
  private _oldSearchVal = '';

  @Input() set gridConfig(gridData: GridConfig) {
    this._gridData = gridData;
  }

  @Input() editable: boolean;
  @Input() dblClick: boolean;

  @Input() set reload(reload: boolean) {
    if (reload === true && this.itemsPerPage) {
      this.updateData();
    }
    this.isReloaded = reload;
  }

  @Output() onHandler: EventEmitter<{ action: string, row: object, activePage: number }> = new EventEmitter();
  @Output() onResponse: EventEmitter<any> = new EventEmitter();
  @Output() onAdvancedResponse: EventEmitter<any> = new EventEmitter();
  @Output() onActiveItemChange: EventEmitter<string> = new EventEmitter();
  @Output() onMenuItemClicked: EventEmitter<string> = new EventEmitter();
  @Output() titleUpdated: EventEmitter<string> = new EventEmitter();

  private _dblClickDialog: any;
  private _gridData: GridConfig;
  private _activeItem: any;
  private _lang: Lang;

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private language: LanguageService,
    private ref: ChangeDetectorRef,
    private notification: DGSNotificationService,
  ) {
    super(process);
    // Do search with 0.45s debounce.
    this.subscriptions.push(this.searchEvent
      .pipe(
        debounceTime(450),
        distinctUntilChanged(),
        map((event: any) => event.target && event.target.value || event),
      ).subscribe((val) => {
        // To drop 'distinct' option
        if (val === null) {
          return;
        }
        this.search(this.searchCol, this.searchVal, true).then(() => {
          if (!this.searchInput || !this.searchInput.nativeElement) {
            return;
          }
          this.searchInput.nativeElement.nextElementSibling.focus();
        });
      }));
    this.subscriptions.push(this.language.languageChanged.subscribe((lang) => {
      this._lang = lang;
      this.activeItem = undefined;
      this.searchEvent.next({ target: { value: null } });
      this.reset();
    }));
  }

  ngAfterViewInit() {
    this.itemsPerPage = this.environment.itemsPerPage || 10;
    if (!this._lang) {
      this.updateData(true);
    }
    this.getTHeadSize();
    if (!this.process.get(`gridchange-${this.control.referenceName}`)) {
      this.process.set(`gridchange-${this.control.referenceName}`, new EventEmitter<any>(), this.task && this.task.taskId || this.task.id);
    }
  }

  @HostListener('document:resize')
  onResize() {
    this.getTHeadSize();
  }

  sort(column: GridColumn) {
    if (!this.rows.length) {
      return;
    }
    // Action column should not be sortable.
    if (column.type === 'edit') {
      return;
    }
    if (column) {
      const sortObj = this._sort[column.dataIndex];
      if (!this._multiSort) {
        const sort = {};
        sort[column.dataIndex] = this._sort[column.dataIndex];
        this._sort = sort;
      }
      if (!sortObj) {
        this._sort[column.dataIndex] = { direction: 'ASC', property: column.dataIndex };
      } else {
        this._sort[column.dataIndex].direction = sortObj.direction === 'DESC' ? 'ASC' : 'DESC';
      }
    }

    // Transform to array
    const sortings = Object.keys(this._sort).map((key) => {
      return this._sort[key];
    });
    this.fetchGridData(0, sortings, undefined, true);
  }

  emitSearch(event: any) {
    if (!this.searchVal || !this.searchCol) {
      return;
    }
    this.ref.detectChanges();

    // Enter pressed
    if (event.keyCode === 13) {
      this.search(this.searchCol, this.searchVal, true);
      return;
    }
    if (this.searchVal === this._oldSearchVal) {
      return;
    }
    this.searchEvent.next(event);
  }

  menuItemClicked(data) {
    this.onMenuItemClicked.emit(data);
  }

  sortIcon(column: GridColumn): string {
    const sortObj = this._sort[column.dataIndex];
    if (!sortObj) {
      return '';
    }
    return sortObj && sortObj.direction === 'ASC' ? 'fa fa-sort-down' : 'fa fa-sort-up';
  }

  sendResponse(row: Object, dblClick: boolean = true) {
    if (!row || !row.hasOwnProperty('Keyfield')) {
      this.error('Grid response triggered with no context.');
      return;
    }
    const keyfield = row['Keyfield'];
    this.activeItem = row;

    if (dblClick && this.isDblClick && !this.gridConfig.notClickable) {
      this.loadingStatus = true;
      if (!this._dblClickDialog) {
        this.onResponse.emit(keyfield);
        this.onAdvancedResponse.emit({ keyfield, target: undefined, row });
        return;
      }
      return this.api.get('data', [this._dblClickDialog.id, keyfield]).then((recordData) => {
        const newRecordData = recordData.data || {};
        const target = this._dblClickDialog;
        target.componentConfig.recordData = Object.assign({}, target.componentConfig.recordData, newRecordData);
        this.onResponse.emit({ keyfield, target });
        this.onAdvancedResponse.emit({ keyfield, target, row });
        this.loadingStatus = false;
        // `` for preventing reference
        let finalTitle = `${newRecordData.title}`;
        if (newRecordData.windowMask) {
          finalTitle = `${newRecordData.windowMask}`;
          (newRecordData.windowMask.match(/#(\w+)#+/ig) || []).forEach((match) => {
            const ref = match.replace(/#/g, '');
            finalTitle.replace(match, target.componentConfig.recordData[ref]);
          });
        }
        if (finalTitle) {
          this.titleUpdated.emit(finalTitle);
        }
      });
    }
    this.loadingStatus = false;
  }

  onNavigation(navData) {
    const action = navData.action;
    if (action === 'onclose') {
      this.onResponse.emit();
      this.onAdvancedResponse.emit();
      return;
    }
    if (!this.activeItem && action !== 'none') {
      this.loadingStatus = false;
      if (!navData.close) {
        this.notification.warning('Cannot save with no selected row. Please choose row, none, or press ESC.');
        return;
      }
      this.onResponse.emit(null);
      this.onAdvancedResponse.emit({ keyfield: null, target: null, row: null });
      return;
    }
    this.loadingStatus = true;
    this.lookupRows = [];
    this.hasRows = undefined;

    if (action === 'none') {
      this.onResponse.emit(null);
      this.onAdvancedResponse.emit({ keyfield: null, target: null, row: null });
    } else {
      this.sendResponse(this.activeItem);
    }
    if (this.group) {
      this.group['form'].markAsDirty();
    }
  }

  updatePage(newPage) {
    this.currentPage = newPage.page || newPage;
    if (this.currentPage > this.downloadPages) {
      this.fetchGridData(this.currentPage, this.values(this._sort), { property: this.searchCol, value: this.searchVal }, true);
    }
    this.ref.detectChanges();
  }

  rowData(column: GridColumn, row: Object) {
    if (!row) {
      return;
    }
    const firstDefined = (...values) => {
      return values.find((val) => val !== undefined && val !== null);
    };
    const dataIndex = this.sanitizeColumnName(column.dataIndex);
    const lang = this.language.language && this.language.language.Code;
    const translatedRow = row[dataIndex + (lang && lang !== 'en' ? `_${lang}` : '')];
    const translatedOptions = firstDefined(row[dataIndex + '_ReferenceTitle'], translatedRow, row[dataIndex]);
    const vanillaOptions = firstDefined(row[column.dataIndex + '_ReferenceTitle'], row[column.dataIndex]);
    return firstDefined(translatedOptions, vanillaOptions, '').toString();
  }

  search(property, value, forceUpdate: boolean = false) {
    this.ref.detectChanges();
    this._oldSearchVal = value;
    if ((!property || !value) && !forceUpdate) {
      this.log(`[D] Search failed with message: No search data.`);
      return Promise.resolve(false);
    }
    this.activeItem = undefined;

    this.currentPage = 0;
    if (this.searchType === 'date') {
      value = Helper.formatDate(value, false);
    }
    return this.fetchGridData(0, undefined, { property, value });
  }

  resetSearch() {
    this.searchVal = '';
    return this.fetchGridData(0);
  }

  reset() {
    this._sort = {};
    this.searchCol = this.defaultCol;
    this.searchVal = '';
    this.currentPage = 0;
    return this.fetchGridData(0, undefined, undefined, true);
  }

  /**
   * On user button clicked.
   */
  handleButton(action: string, row: Object) {
    if (this.isReadOnly) {
      return;
    }
    this.onHandler.emit({ action, row, activePage: this.currentPage });
  }

  /**
   * Trigger file download.
   * @param rowData
   */
  downloadFile(rowData: any) {
    Helper.downloadFile(this.api, rowData);
  }

  fileName(rowData: any): string {
    return rowData && rowData.fileName;
  }

  sanitizeColumnName(columnName: string): string {
    if (!columnName) {
      return;
    }
    let result = columnName;
    this.api.apiRenameList.forEach((rename) => {
      if (columnName.startsWith(`${rename}_`)) {
        result = rename;
      }
    });
    return result;
  }

  isObject(item): boolean {
    return Array.isArray(item) || typeof item === 'object';
  }

  /**
   * Transform any to array.
   * @param item
   */
  arrayFrom(item): any[] {
    if (Array.isArray(item)) {
      return item;
    }
    return Object.keys(item || {}).map((key) => {
      return item[key];
    });
  }

  get isDblClick(): boolean {
    return this._dblClickDialog !== undefined ? this._dblClickDialog : this.dblClick;
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  get defaultCol(): string {
    return this.columns && this.columns[0] && this.columns[0].dataIndex || '';
  }

  get activeCol(): GridColumn {
    const activeColValue = this.searchCol;
    return this.columns.find((col) => col.dataIndex === activeColValue);
  }

  get searchType(): string {
    const activeCol = this.activeCol || {};
    if (activeCol.type) {
      const typeAssigns = {
        'datecolumn': 'date',
        'lookupcolumn': 'text',
        'gridcolumn': 'text',
      };
      return typeAssigns[activeCol.type] || 'text';
    }
    return 'text';
  }

  get rows(): any[] {
    return (this.lookupRows || []).filter(row => !!row);
  }

  get gridConfig(): GridConfig {
    return this._gridData;
  }

  get columns(): GridColumn[] {
    if (!this.gridConfig || !this.gridConfig.componentConfig) {
      return [];
    }
    return (this.gridConfig.componentConfig.columns || []).filter((column: GridColumn) => !column.hidden);
  }

  get activeItem(): any {
    return this._activeItem;
  }

  get dockedTop(): Dock[] {
    return this.docked && this.docked.filter((dock) => dock.dock.toLowerCase() === 'top') || [];
  }

  get dockedBottom(): Dock[] {
    return this.docked && this.docked.filter((dock) => dock.dock.toLowerCase() === 'bottom') || [];
  }

  get docked(): Dock[] {
    return this.gridConfig && this.gridConfig.componentConfig && this.gridConfig.componentConfig.dockedItems || null;
  }

  get showTools(): boolean {
    return this.gridConfig && (this.gridConfig.editAction || this.gridConfig.deleteAction);
  }

  set loadingStatus(status: boolean) {
    this.loading = status;
    this.ref.detectChanges();
  }

  set activeItem(val: any) {
    this._activeItem = val;
    this.onActiveItemChange.emit(this._activeItem);
  }

  private updateData(forced?: boolean) {
    this.searchCol = '';
    this.searchVal = '';
    this.activeItem = undefined;
    this.fetchGridData(0, undefined, undefined, forced || this.isReloaded).then((response: ApiResponse) => {
      this.searchCol = this.defaultCol;
      this.ref.detectChanges();
    });
  }

  private fetchGridData(page: number = 0, sorts?: GridSort[], filter?: GridFilter, skipCache?: boolean): Promise<any> {
    if (!this.gridConfig || !this.gridConfig.componentConfig) {
      return Promise.resolve();
    }
    (this.gridConfig.componentConfig.dockedItems || []).forEach((docked) => {
      if (!docked.items) {
        this.warn(`Meet wrong configuration for docked items.`);
      }
      (docked.items || []).forEach((item) => {
        if (item.isDblClick) {
          this._dblClickDialog = item.dialogData;
        }
      });
    });
    this.loadingStatus = true;
    const pageNotSet = page === 0;

    const componentConfig = this.gridConfig.componentConfig;
    const multiLang = componentConfig.store.proxy.multilang;
    let proxyUrl = componentConfig.store.proxy.url;
    const lang = this.language.language && this.language.language.Code;
    if (multiLang && lang && lang !== 'en') {
      proxyUrl += `_${lang}`;
    }
    let limit = this.downloadPages * this.itemsPerPage;
    if (!pageNotSet) {
      limit = this.itemsPerPage;
    }
    const start = 0;

    const fromForm = (this.task.clientComponent || this.task.componentClassName || '').toLowerCase() === 'form';
    const params = { page, start, limit, filter: [], sort: [], fromForm };
    const mustFilters = this.gridConfig.filters;
    if (mustFilters) {
      params['filter'].push(...mustFilters);
    }
    if (filter) {
      params['filter'].push(filter);
    }
    if (sorts) {
      params['sort'].push(...sorts);
    }
    if (skipCache) {
      this.api.removeMatched(proxyUrl);
    }
    if (params['filter'].some((_filter) => _filter.value === 'null')) {
      this.loadingStatus = false;
      this.log(
        `Tried to fetch grid but got empty filters. Grid: ${this.gridConfig.id || this.task.componentConfig.title}`,
        params['filter'],
      );
      return Promise.resolve(false);
    }
    return this.api.get(proxyUrl, [], params, { skipCache }).then((response: ApiResponse) => {
      this.errorMsg = this.process.errorFromResponse(response);
      if (this.errorMsg) {
        // this.error(this.errorMsg);
        this.loading = false;
        return;
      }
      if (this.gridConfig.activePage) {
        this.updatePage(this.gridConfig.activePage);
      }
      const data = response.data.data;
      if (pageNotSet) {
        this.totalItems = response.data.count;
        const changeName = `grid-${this.gridConfig.id || this.gridConfig.model.referenceName}-rows`;
        this.process.changes = { changeName, changeVal: this.totalItems };
        this.lookupRows = data;
        this.hasRows = this.rows.length ? true : undefined;
        this.loading = false;
      } else {
        const from = (page - 1) * this.itemsPerPage;
        const to = from + this.itemsPerPage;
        let i;
        // Complete with undefined missing fields (will be overrided by normal when user will try to get them)
        for (i = 0; i < from; i++) {
          if (!this.lookupRows[i]) {
            this.lookupRows[i] = {};
          }
        }
        for (i = from; i < to; i++) {
          this.lookupRows[i] = data[i - from];
        }
        this.debug(`Loaded grid data from ${from} till ${to}`);
      }
      const exposedGridInfo = {
        items: {
          count: this.totalItems,
          data: response.data,
        },
        activePage: this.gridConfig.activePage || 0,
        canAddNew: !!this.gridConfig.addAction,
        canEdit: !!this.gridConfig.editAction,
        model: this.gridConfig.model,
        parent: this.gridConfig.parent,
      };
      this.process.set(`gridinfo-${this.control.referenceName}`, exposedGridInfo, this.task && this.task.taskId || this.task.id);
      this.loading = false;
      this.getTHeadSize();
      this.ref.detectChanges();

      const onChange = this.process.get(`gridchange-${this.control.referenceName}`);
      if (onChange) {
        onChange.emit(this._destroyed);
      }
      return Promise.resolve(response);
    });
  }

  private getTHeadSize() {
    if (this._destroyed) {
      return;
    }
    setTimeout(() => {
      const height = this.theadContainer && this.theadContainer.nativeElement.offsetHeight || null;
      if (height && height !== this.theadSize) {
        this.theadSize = height;
      }
    }, 10);
  }

  /**
   * Same as Object.values ( easier than to implement polyfill ).
   * @param obj
   */
  private values(obj): any[] {
    return Object.keys(obj).map((key) => obj[key]);
  }

  ngOnDestroy() {
    super.OnDestroy(this.ref);
    this._destroyed = true;
  }
}
