import { Component, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./../controls.css', './toggle.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsToggleComponent extends FVAbstractControl implements AfterViewInit {
  constructor(process: ProcessService) {
    super(process);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
  }

}
