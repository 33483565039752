import { Control } from './control';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class Tab {
  constructor(
    public controls?: Control[],
    public key?: string,
    public label?: string,
    public сontrolType?: number,
  ) { }
}
