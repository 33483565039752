// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return
// tslint:disable:object-literal-shorthand

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Mongolian [mn]
//! author : Javkhlantugs Nyamdorj : https://github.com/javkhaanj7

function translate(num: number, withoutSuffix: boolean, key: string, isFuture: boolean) {
  switch (key) {
    case 's':
      return withoutSuffix ? 'ÑÑÐ´ÑÑÐ½ ÑÐµÐºÑÐ½Ð´' : 'ÑÑÐ´ÑÑÐ½ ÑÐµÐºÑÐ½Ð´ÑÐ½';
    case 'ss':
      return num + (withoutSuffix ? ' ÑÐµÐºÑÐ½Ð´' : ' ÑÐµÐºÑÐ½Ð´ÑÐ½');
    case 'm':
    case 'mm':
      return num + (withoutSuffix ? ' Ð¼Ð¸Ð½ÑÑ' : ' Ð¼Ð¸Ð½ÑÑÑÐ½');
    case 'h':
    case 'hh':
      return num + (withoutSuffix ? ' ÑÐ°Ð³' : ' ÑÐ°Ð³Ð¸Ð¹Ð½');
    case 'd':
    case 'dd':
      return num + (withoutSuffix ? ' Ó©Ð´Ó©Ñ' : ' Ó©Ð´ÑÐ¸Ð¹Ð½');
    case 'M':
    case 'MM':
      return num + (withoutSuffix ? ' ÑÐ°Ñ' : ' ÑÐ°ÑÑÐ½');
    case 'y':
    case 'yy':
      return num + (withoutSuffix ? ' Ð¶Ð¸Ð»' : ' Ð¶Ð¸Ð»Ð¸Ð¹Ð½');
    default:
      return num.toString(10);
  }
}

export const mnLocale: LocaleData = {
  abbr: 'mn',
  months: 'ÐÑÐ³Ð´Ò¯Ð³ÑÑÑ ÑÐ°Ñ_Ð¥Ð¾ÑÑÐ´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ_ÐÑÑÐ°Ð²Ð´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ_ÐÓ©ÑÓ©Ð²Ð´Ò¯Ð³ÑÑÑ ÑÐ°Ñ_Ð¢Ð°Ð²Ð´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ_ÐÑÑÐ³Ð°Ð´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ_ÐÐ¾Ð»Ð´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ_ÐÐ°Ð¹Ð¼Ð´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ_ÐÑÐ´Ò¯Ð³ÑÑÑ ÑÐ°Ñ_ÐÑÐ°Ð²Ð´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ_ÐÑÐ²Ð°Ð½ Ð½ÑÐ³Ð´Ò¯Ð³ÑÑÑ ÑÐ°Ñ_ÐÑÐ²Ð°Ð½ ÑÐ¾ÑÑÐ´ÑÐ³Ð°Ð°Ñ ÑÐ°Ñ'.split('_'),
  monthsShort: '1 ÑÐ°Ñ_2 ÑÐ°Ñ_3 ÑÐ°Ñ_4 ÑÐ°Ñ_5 ÑÐ°Ñ_6 ÑÐ°Ñ_7 ÑÐ°Ñ_8 ÑÐ°Ñ_9 ÑÐ°Ñ_10 ÑÐ°Ñ_11 ÑÐ°Ñ_12 ÑÐ°Ñ'.split('_'),
  monthsParseExact: true,
  weekdays: 'ÐÑÐ¼_ÐÐ°Ð²Ð°Ð°_ÐÑÐ³Ð¼Ð°Ñ_ÐÑÐ°Ð³Ð²Ð°_ÐÒ¯ÑÑÐ²_ÐÐ°Ð°ÑÐ°Ð½_ÐÑÐ¼Ð±Ð°'.split('_'),
  weekdaysShort: 'ÐÑÐ¼_ÐÐ°Ð²_ÐÑÐ³_ÐÑÐ°_ÐÒ¯Ñ_ÐÐ°Ð°_ÐÑÐ¼'.split('_'),
  weekdaysMin: 'ÐÑ_ÐÐ°_ÐÑ_ÐÑ_ÐÒ¯_ÐÐ°_ÐÑ'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY-MM-DD',
    LL: 'YYYY Ð¾Ð½Ñ MMMMÑÐ½ D',
    LLL: 'YYYY Ð¾Ð½Ñ MMMMÑÐ½ D HH:mm',
    LLLL: 'dddd, YYYY Ð¾Ð½Ñ MMMMÑÐ½ D HH:mm'
  },
  meridiemParse: /Ò®Ó¨|Ò®Ð¥/i,
  isPM: function (input) {
    return input === 'Ò®Ð¥';
  },
  meridiem: function (hour, minute, isLower) {
    if (hour < 12) {
      return 'Ò®Ó¨';
    } else {
      return 'Ò®Ð¥';
    }
  },
  calendar: {
    sameDay: '[Ó¨Ð½Ó©Ó©Ð´Ó©Ñ] LT',
    nextDay: '[ÐÐ°ÑÐ³Ð°Ð°Ñ] LT',
    nextWeek: '[ÐÑÑÑ] dddd LT',
    lastDay: '[Ó¨ÑÐ¸Ð³Ð´Ó©Ñ] LT',
    lastWeek: '[Ó¨Ð½Ð³Ó©ÑÑÓ©Ð½] dddd LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: '%s Ð´Ð°ÑÐ°Ð°',
    past: '%s Ó©Ð¼Ð½Ó©',
    s: translate,
    ss: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate
  },
  dayOfMonthOrdinalParse: /\d{1,2} Ó©Ð´Ó©Ñ/,
  ordinal: function (num: number, period: string): string {
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
        return num + ' Ó©Ð´Ó©Ñ';
      default:
        return num.toString(10);
    }
  }
};
