import { Injectable, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService, Lang, IDGSEnvironment } from '@dotgov/core';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Injectable()
export class LanguageService {
  defaultLanguage: Lang;
  languageChanged: EventEmitter<Lang> = new EventEmitter();

  private _languages: Lang[];
  private _language: Lang = this.loadSavedLanguage() || this.defaultLanguage;
  private _translations: Object = {};

  constructor(
    @Inject('environment') private environment: IDGSEnvironment,
    private translate: TranslateService,
    private apiService: ApiService,
  ) {
    this.defaultLanguage = environment.defaultLanguage || new Lang('English', 'en', 'fa fa-flag');
    this._languages = this.environment.languages || [];
    this.translate.setDefaultLang(this.defaultLanguage.Code);
  }

  loadSavedLanguage() {
    return JSON.parse(localStorage.getItem('language')) || false;
  }

  getTranslate(key?: string): Promise<string> {
    if (!key || !key.toLowerCase) {
      if (this.environment.debug) {
        console.warn('Unexpected translate key');
      }
      key = '';
    }
    return new Promise((resolve, reject) => {
      this.translate.get(key.toLowerCase() || '').subscribe(resolve, reject);
    });
  }

  addTranslate(lang: Lang, translation: Object) {
    if (!translation) {
      return;
    }
    this._translations[lang.Code] = translation;
    this.language = lang;
  }

  get languages(): Promise<Lang[]> {
    return Promise.resolve(this._languages);
  }

  get language(): Lang {
    return this._language;
  }

  set language(lang: Lang) {
    if (!this.language || !this.language.Code) {
      this._language = lang;
    }
    this.translate.use(this.language.Code);
    this.translate.addLangs([this.language.Code]);
    const translation = this._translations[lang.Code];

    const handleTranslation = (_translation) => {
      this.translate.setTranslation(this.language.Code, _translation);
      localStorage.setItem('language', JSON.stringify(lang));
      if (lang.Code !== this._language.Code) {
        this._language = lang;
        this.languageChanged.emit(lang);
      }
    };
    if (translation) {
      handleTranslation(translation);
      return;
    }
    this.apiService.get('translate', [this.language.Code]).then((translations) => {
      handleTranslation(translations);
    });
  }

}
