/* tslint:disable */
export const latinMap: { [key: string]: string } = {
    'Ã': 'A',
    'Ä': 'A',
    'áº®': 'A',
    'áº¶': 'A',
    'áº°': 'A',
    'áº²': 'A',
    'áº´': 'A',
    'Ç': 'A',
    'Ã': 'A',
    'áº¤': 'A',
    'áº¬': 'A',
    'áº¦': 'A',
    'áº¨': 'A',
    'áºª': 'A',
    'Ã': 'A',
    'Ç': 'A',
    'È¦': 'A',
    'Ç ': 'A',
    'áº ': 'A',
    'È': 'A',
    'Ã': 'A',
    'áº¢': 'A',
    'È': 'A',
    'Ä': 'A',
    'Ä': 'A',
    'Ã': 'A',
    'Çº': 'A',
    'á¸': 'A',
    'Èº': 'A',
    'Ã': 'A',
    'ê²': 'AA',
    'Ã': 'AE',
    'Ç¼': 'AE',
    'Ç¢': 'AE',
    'ê´': 'AO',
    'ê¶': 'AU',
    'ê¸': 'AV',
    'êº': 'AV',
    'ê¼': 'AY',
    'á¸': 'B',
    'á¸': 'B',
    'Æ': 'B',
    'á¸': 'B',
    'É': 'B',
    'Æ': 'B',
    'Ä': 'C',
    'Ä': 'C',
    'Ã': 'C',
    'á¸': 'C',
    'Ä': 'C',
    'Ä': 'C',
    'Æ': 'C',
    'È»': 'C',
    'Ä': 'D',
    'á¸': 'D',
    'á¸': 'D',
    'á¸': 'D',
    'á¸': 'D',
    'Æ': 'D',
    'á¸': 'D',
    'Ç²': 'D',
    'Ç': 'D',
    'Ä': 'D',
    'Æ': 'D',
    'Ç±': 'DZ',
    'Ç': 'DZ',
    'Ã': 'E',
    'Ä': 'E',
    'Ä': 'E',
    'È¨': 'E',
    'á¸': 'E',
    'Ã': 'E',
    'áº¾': 'E',
    'á»': 'E',
    'á»': 'E',
    'á»': 'E',
    'á»': 'E',
    'á¸': 'E',
    'Ã': 'E',
    'Ä': 'E',
    'áº¸': 'E',
    'È': 'E',
    'Ã': 'E',
    'áºº': 'E',
    'È': 'E',
    'Ä': 'E',
    'á¸': 'E',
    'á¸': 'E',
    'Ä': 'E',
    'É': 'E',
    'áº¼': 'E',
    'á¸': 'E',
    'êª': 'ET',
    'á¸': 'F',
    'Æ': 'F',
    'Ç´': 'G',
    'Ä': 'G',
    'Ç¦': 'G',
    'Ä¢': 'G',
    'Ä': 'G',
    'Ä ': 'G',
    'Æ': 'G',
    'á¸ ': 'G',
    'Ç¤': 'G',
    'á¸ª': 'H',
    'È': 'H',
    'á¸¨': 'H',
    'Ä¤': 'H',
    'â±§': 'H',
    'á¸¦': 'H',
    'á¸¢': 'H',
    'á¸¤': 'H',
    'Ä¦': 'H',
    'Ã': 'I',
    'Ä¬': 'I',
    'Ç': 'I',
    'Ã': 'I',
    'Ã': 'I',
    'á¸®': 'I',
    'Ä°': 'I',
    'á»': 'I',
    'È': 'I',
    'Ã': 'I',
    'á»': 'I',
    'È': 'I',
    'Äª': 'I',
    'Ä®': 'I',
    'Æ': 'I',
    'Ä¨': 'I',
    'á¸¬': 'I',
    'ê¹': 'D',
    'ê»': 'F',
    'ê½': 'G',
    'ê': 'R',
    'ê': 'S',
    'ê': 'T',
    'ê¬': 'IS',
    'Ä´': 'J',
    'É': 'J',
    'á¸°': 'K',
    'Ç¨': 'K',
    'Ä¶': 'K',
    'â±©': 'K',
    'ê': 'K',
    'á¸²': 'K',
    'Æ': 'K',
    'á¸´': 'K',
    'ê': 'K',
    'ê': 'K',
    'Ä¹': 'L',
    'È½': 'L',
    'Ä½': 'L',
    'Ä»': 'L',
    'á¸¼': 'L',
    'á¸¶': 'L',
    'á¸¸': 'L',
    'â± ': 'L',
    'ê': 'L',
    'á¸º': 'L',
    'Ä¿': 'L',
    'â±¢': 'L',
    'Ç': 'L',
    'Å': 'L',
    'Ç': 'LJ',
    'á¸¾': 'M',
    'á¹': 'M',
    'á¹': 'M',
    'â±®': 'M',
    'Å': 'N',
    'Å': 'N',
    'Å': 'N',
    'á¹': 'N',
    'á¹': 'N',
    'á¹': 'N',
    'Ç¸': 'N',
    'Æ': 'N',
    'á¹': 'N',
    'È ': 'N',
    'Ç': 'N',
    'Ã': 'N',
    'Ç': 'NJ',
    'Ã': 'O',
    'Å': 'O',
    'Ç': 'O',
    'Ã': 'O',
    'á»': 'O',
    'á»': 'O',
    'á»': 'O',
    'á»': 'O',
    'á»': 'O',
    'Ã': 'O',
    'Èª': 'O',
    'È®': 'O',
    'È°': 'O',
    'á»': 'O',
    'Å': 'O',
    'È': 'O',
    'Ã': 'O',
    'á»': 'O',
    'Æ ': 'O',
    'á»': 'O',
    'á»¢': 'O',
    'á»': 'O',
    'á»': 'O',
    'á» ': 'O',
    'È': 'O',
    'ê': 'O',
    'ê': 'O',
    'Å': 'O',
    'á¹': 'O',
    'á¹': 'O',
    'Æ': 'O',
    'Çª': 'O',
    'Ç¬': 'O',
    'Ã': 'O',
    'Ç¾': 'O',
    'Ã': 'O',
    'á¹': 'O',
    'á¹': 'O',
    'È¬': 'O',
    'Æ¢': 'OI',
    'ê': 'OO',
    'Æ': 'E',
    'Æ': 'O',
    'È¢': 'OU',
    'á¹': 'P',
    'á¹': 'P',
    'ê': 'P',
    'Æ¤': 'P',
    'ê': 'P',
    'â±£': 'P',
    'ê': 'P',
    'ê': 'Q',
    'ê': 'Q',
    'Å': 'R',
    'Å': 'R',
    'Å': 'R',
    'á¹': 'R',
    'á¹': 'R',
    'á¹': 'R',
    'È': 'R',
    'È': 'R',
    'á¹': 'R',
    'É': 'R',
    'â±¤': 'R',
    'ê¾': 'C',
    'Æ': 'E',
    'Å': 'S',
    'á¹¤': 'S',
    'Å ': 'S',
    'á¹¦': 'S',
    'Å': 'S',
    'Å': 'S',
    'È': 'S',
    'á¹ ': 'S',
    'á¹¢': 'S',
    'á¹¨': 'S',
    'Å¤': 'T',
    'Å¢': 'T',
    'á¹°': 'T',
    'È': 'T',
    'È¾': 'T',
    'á¹ª': 'T',
    'á¹¬': 'T',
    'Æ¬': 'T',
    'á¹®': 'T',
    'Æ®': 'T',
    'Å¦': 'T',
    'â±¯': 'A',
    'ê': 'L',
    'Æ': 'M',
    'É': 'V',
    'ê¨': 'TZ',
    'Ã': 'U',
    'Å¬': 'U',
    'Ç': 'U',
    'Ã': 'U',
    'á¹¶': 'U',
    'Ã': 'U',
    'Ç': 'U',
    'Ç': 'U',
    'Ç': 'U',
    'Ç': 'U',
    'á¹²': 'U',
    'á»¤': 'U',
    'Å°': 'U',
    'È': 'U',
    'Ã': 'U',
    'á»¦': 'U',
    'Æ¯': 'U',
    'á»¨': 'U',
    'á»°': 'U',
    'á»ª': 'U',
    'á»¬': 'U',
    'á»®': 'U',
    'È': 'U',
    'Åª': 'U',
    'á¹º': 'U',
    'Å²': 'U',
    'Å®': 'U',
    'Å¨': 'U',
    'á¹¸': 'U',
    'á¹´': 'U',
    'ê': 'V',
    'á¹¾': 'V',
    'Æ²': 'V',
    'á¹¼': 'V',
    'ê ': 'VY',
    'áº': 'W',
    'Å´': 'W',
    'áº': 'W',
    'áº': 'W',
    'áº': 'W',
    'áº': 'W',
    'â±²': 'W',
    'áº': 'X',
    'áº': 'X',
    'Ã': 'Y',
    'Å¶': 'Y',
    'Å¸': 'Y',
    'áº': 'Y',
    'á»´': 'Y',
    'á»²': 'Y',
    'Æ³': 'Y',
    'á»¶': 'Y',
    'á»¾': 'Y',
    'È²': 'Y',
    'É': 'Y',
    'á»¸': 'Y',
    'Å¹': 'Z',
    'Å½': 'Z',
    'áº': 'Z',
    'â±«': 'Z',
    'Å»': 'Z',
    'áº': 'Z',
    'È¤': 'Z',
    'áº': 'Z',
    'Æµ': 'Z',
    'Ä²': 'IJ',
    'Å': 'OE',
    'á´': 'A',
    'á´': 'AE',
    'Ê': 'B',
    'á´': 'B',
    'á´': 'C',
    'á´': 'D',
    'á´': 'E',
    'ê°': 'F',
    'É¢': 'G',
    'Ê': 'G',
    'Ê': 'H',
    'Éª': 'I',
    'Ê': 'R',
    'á´': 'J',
    'á´': 'K',
    'Ê': 'L',
    'á´': 'L',
    'á´': 'M',
    'É´': 'N',
    'á´': 'O',
    'É¶': 'OE',
    'á´': 'O',
    'á´': 'OU',
    'á´': 'P',
    'Ê': 'R',
    'á´': 'N',
    'á´': 'R',
    'ê±': 'S',
    'á´': 'T',
    'â±»': 'E',
    'á´': 'R',
    'á´': 'U',
    'á´ ': 'V',
    'á´¡': 'W',
    'Ê': 'Y',
    'á´¢': 'Z',
    'Ã¡': 'a',
    'Ä': 'a',
    'áº¯': 'a',
    'áº·': 'a',
    'áº±': 'a',
    'áº³': 'a',
    'áºµ': 'a',
    'Ç': 'a',
    'Ã¢': 'a',
    'áº¥': 'a',
    'áº­': 'a',
    'áº§': 'a',
    'áº©': 'a',
    'áº«': 'a',
    'Ã¤': 'a',
    'Ç': 'a',
    'È§': 'a',
    'Ç¡': 'a',
    'áº¡': 'a',
    'È': 'a',
    'Ã ': 'a',
    'áº£': 'a',
    'È': 'a',
    'Ä': 'a',
    'Ä': 'a',
    'á¶': 'a',
    'áº': 'a',
    'Ã¥': 'a',
    'Ç»': 'a',
    'á¸': 'a',
    'â±¥': 'a',
    'Ã£': 'a',
    'ê³': 'aa',
    'Ã¦': 'ae',
    'Ç½': 'ae',
    'Ç£': 'ae',
    'êµ': 'ao',
    'ê·': 'au',
    'ê¹': 'av',
    'ê»': 'av',
    'ê½': 'ay',
    'á¸': 'b',
    'á¸': 'b',
    'É': 'b',
    'á¸': 'b',
    'áµ¬': 'b',
    'á¶': 'b',
    'Æ': 'b',
    'Æ': 'b',
    'Éµ': 'o',
    'Ä': 'c',
    'Ä': 'c',
    'Ã§': 'c',
    'á¸': 'c',
    'Ä': 'c',
    'É': 'c',
    'Ä': 'c',
    'Æ': 'c',
    'È¼': 'c',
    'Ä': 'd',
    'á¸': 'd',
    'á¸': 'd',
    'È¡': 'd',
    'á¸': 'd',
    'á¸': 'd',
    'É': 'd',
    'á¶': 'd',
    'á¸': 'd',
    'áµ­': 'd',
    'á¶': 'd',
    'Ä': 'd',
    'É': 'd',
    'Æ': 'd',
    'Ä±': 'i',
    'È·': 'j',
    'É': 'j',
    'Ê': 'j',
    'Ç³': 'dz',
    'Ç': 'dz',
    'Ã©': 'e',
    'Ä': 'e',
    'Ä': 'e',
    'È©': 'e',
    'á¸': 'e',
    'Ãª': 'e',
    'áº¿': 'e',
    'á»': 'e',
    'á»': 'e',
    'á»': 'e',
    'á»': 'e',
    'á¸': 'e',
    'Ã«': 'e',
    'Ä': 'e',
    'áº¹': 'e',
    'È': 'e',
    'Ã¨': 'e',
    'áº»': 'e',
    'È': 'e',
    'Ä': 'e',
    'á¸': 'e',
    'á¸': 'e',
    'â±¸': 'e',
    'Ä': 'e',
    'á¶': 'e',
    'É': 'e',
    'áº½': 'e',
    'á¸': 'e',
    'ê«': 'et',
    'á¸': 'f',
    'Æ': 'f',
    'áµ®': 'f',
    'á¶': 'f',
    'Çµ': 'g',
    'Ä': 'g',
    'Ç§': 'g',
    'Ä£': 'g',
    'Ä': 'g',
    'Ä¡': 'g',
    'É ': 'g',
    'á¸¡': 'g',
    'á¶': 'g',
    'Ç¥': 'g',
    'á¸«': 'h',
    'È': 'h',
    'á¸©': 'h',
    'Ä¥': 'h',
    'â±¨': 'h',
    'á¸§': 'h',
    'á¸£': 'h',
    'á¸¥': 'h',
    'É¦': 'h',
    'áº': 'h',
    'Ä§': 'h',
    'Æ': 'hv',
    'Ã­': 'i',
    'Ä­': 'i',
    'Ç': 'i',
    'Ã®': 'i',
    'Ã¯': 'i',
    'á¸¯': 'i',
    'á»': 'i',
    'È': 'i',
    'Ã¬': 'i',
    'á»': 'i',
    'È': 'i',
    'Ä«': 'i',
    'Ä¯': 'i',
    'á¶': 'i',
    'É¨': 'i',
    'Ä©': 'i',
    'á¸­': 'i',
    'êº': 'd',
    'ê¼': 'f',
    'áµ¹': 'g',
    'ê': 'r',
    'ê': 's',
    'ê': 't',
    'ê­': 'is',
    'Ç°': 'j',
    'Äµ': 'j',
    'Ê': 'j',
    'É': 'j',
    'á¸±': 'k',
    'Ç©': 'k',
    'Ä·': 'k',
    'â±ª': 'k',
    'ê': 'k',
    'á¸³': 'k',
    'Æ': 'k',
    'á¸µ': 'k',
    'á¶': 'k',
    'ê': 'k',
    'ê': 'k',
    'Äº': 'l',
    'Æ': 'l',
    'É¬': 'l',
    'Ä¾': 'l',
    'Ä¼': 'l',
    'á¸½': 'l',
    'È´': 'l',
    'á¸·': 'l',
    'á¸¹': 'l',
    'â±¡': 'l',
    'ê': 'l',
    'á¸»': 'l',
    'Å': 'l',
    'É«': 'l',
    'á¶': 'l',
    'É­': 'l',
    'Å': 'l',
    'Ç': 'lj',
    'Å¿': 's',
    'áº': 's',
    'áº': 's',
    'áº': 's',
    'á¸¿': 'm',
    'á¹': 'm',
    'á¹': 'm',
    'É±': 'm',
    'áµ¯': 'm',
    'á¶': 'm',
    'Å': 'n',
    'Å': 'n',
    'Å': 'n',
    'á¹': 'n',
    'Èµ': 'n',
    'á¹': 'n',
    'á¹': 'n',
    'Ç¹': 'n',
    'É²': 'n',
    'á¹': 'n',
    'Æ': 'n',
    'áµ°': 'n',
    'á¶': 'n',
    'É³': 'n',
    'Ã±': 'n',
    'Ç': 'nj',
    'Ã³': 'o',
    'Å': 'o',
    'Ç': 'o',
    'Ã´': 'o',
    'á»': 'o',
    'á»': 'o',
    'á»': 'o',
    'á»': 'o',
    'á»': 'o',
    'Ã¶': 'o',
    'È«': 'o',
    'È¯': 'o',
    'È±': 'o',
    'á»': 'o',
    'Å': 'o',
    'È': 'o',
    'Ã²': 'o',
    'á»': 'o',
    'Æ¡': 'o',
    'á»': 'o',
    'á»£': 'o',
    'á»': 'o',
    'á»': 'o',
    'á»¡': 'o',
    'È': 'o',
    'ê': 'o',
    'ê': 'o',
    'â±º': 'o',
    'Å': 'o',
    'á¹': 'o',
    'á¹': 'o',
    'Ç«': 'o',
    'Ç­': 'o',
    'Ã¸': 'o',
    'Ç¿': 'o',
    'Ãµ': 'o',
    'á¹': 'o',
    'á¹': 'o',
    'È­': 'o',
    'Æ£': 'oi',
    'ê': 'oo',
    'É': 'e',
    'á¶': 'e',
    'É': 'o',
    'á¶': 'o',
    'È£': 'ou',
    'á¹': 'p',
    'á¹': 'p',
    'ê': 'p',
    'Æ¥': 'p',
    'áµ±': 'p',
    'á¶': 'p',
    'ê': 'p',
    'áµ½': 'p',
    'ê': 'p',
    'ê': 'q',
    'Ê ': 'q',
    'É': 'q',
    'ê': 'q',
    'Å': 'r',
    'Å': 'r',
    'Å': 'r',
    'á¹': 'r',
    'á¹': 'r',
    'á¹': 'r',
    'È': 'r',
    'É¾': 'r',
    'áµ³': 'r',
    'È': 'r',
    'á¹': 'r',
    'É¼': 'r',
    'áµ²': 'r',
    'á¶': 'r',
    'É': 'r',
    'É½': 'r',
    'â': 'c',
    'ê¿': 'c',
    'É': 'e',
    'É¿': 'r',
    'Å': 's',
    'á¹¥': 's',
    'Å¡': 's',
    'á¹§': 's',
    'Å': 's',
    'Å': 's',
    'È': 's',
    'á¹¡': 's',
    'á¹£': 's',
    'á¹©': 's',
    'Ê': 's',
    'áµ´': 's',
    'á¶': 's',
    'È¿': 's',
    'É¡': 'g',
    'á´': 'o',
    'á´': 'o',
    'á´': 'u',
    'Å¥': 't',
    'Å£': 't',
    'á¹±': 't',
    'È': 't',
    'È¶': 't',
    'áº': 't',
    'â±¦': 't',
    'á¹«': 't',
    'á¹­': 't',
    'Æ­': 't',
    'á¹¯': 't',
    'áµµ': 't',
    'Æ«': 't',
    'Ê': 't',
    'Å§': 't',
    'áµº': 'th',
    'É': 'a',
    'á´': 'ae',
    'Ç': 'e',
    'áµ·': 'g',
    'É¥': 'h',
    'Ê®': 'h',
    'Ê¯': 'h',
    'á´': 'i',
    'Ê': 'k',
    'ê': 'l',
    'É¯': 'm',
    'É°': 'm',
    'á´': 'oe',
    'É¹': 'r',
    'É»': 'r',
    'Éº': 'r',
    'â±¹': 'r',
    'Ê': 't',
    'Ê': 'v',
    'Ê': 'w',
    'Ê': 'y',
    'ê©': 'tz',
    'Ãº': 'u',
    'Å­': 'u',
    'Ç': 'u',
    'Ã»': 'u',
    'á¹·': 'u',
    'Ã¼': 'u',
    'Ç': 'u',
    'Ç': 'u',
    'Ç': 'u',
    'Ç': 'u',
    'á¹³': 'u',
    'á»¥': 'u',
    'Å±': 'u',
    'È': 'u',
    'Ã¹': 'u',
    'á»§': 'u',
    'Æ°': 'u',
    'á»©': 'u',
    'á»±': 'u',
    'á»«': 'u',
    'á»­': 'u',
    'á»¯': 'u',
    'È': 'u',
    'Å«': 'u',
    'á¹»': 'u',
    'Å³': 'u',
    'á¶': 'u',
    'Å¯': 'u',
    'Å©': 'u',
    'á¹¹': 'u',
    'á¹µ': 'u',
    'áµ«': 'ue',
    'ê¸': 'um',
    'â±´': 'v',
    'ê': 'v',
    'á¹¿': 'v',
    'Ê': 'v',
    'á¶': 'v',
    'â±±': 'v',
    'á¹½': 'v',
    'ê¡': 'vy',
    'áº': 'w',
    'Åµ': 'w',
    'áº': 'w',
    'áº': 'w',
    'áº': 'w',
    'áº': 'w',
    'â±³': 'w',
    'áº': 'w',
    'áº': 'x',
    'áº': 'x',
    'á¶': 'x',
    'Ã½': 'y',
    'Å·': 'y',
    'Ã¿': 'y',
    'áº': 'y',
    'á»µ': 'y',
    'á»³': 'y',
    'Æ´': 'y',
    'á»·': 'y',
    'á»¿': 'y',
    'È³': 'y',
    'áº': 'y',
    'É': 'y',
    'á»¹': 'y',
    'Åº': 'z',
    'Å¾': 'z',
    'áº': 'z',
    'Ê': 'z',
    'â±¬': 'z',
    'Å¼': 'z',
    'áº': 'z',
    'È¥': 'z',
    'áº': 'z',
    'áµ¶': 'z',
    'á¶': 'z',
    'Ê': 'z',
    'Æ¶': 'z',
    'É': 'z',
    'ï¬': 'ff',
    'ï¬': 'ffi',
    'ï¬': 'ffl',
    'ï¬': 'fi',
    'ï¬': 'fl',
    'Ä³': 'ij',
    'Å': 'oe',
    'ï¬': 'st',
    'â': 'a',
    'â': 'e',
    'áµ¢': 'i',
    'â±¼': 'j',
    'â': 'o',
    'áµ£': 'r',
    'áµ¤': 'u',
    'áµ¥': 'v',
    'â': 'x'
};
