// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Viá»t Nam [vi]
//! author : Chris Gedrim : https://github.com/chrisgedrim

export const viLocale: LocaleData = {
  abbr: 'vi',
  months : 'thÃ¡ng 1_thÃ¡ng 2_thÃ¡ng 3_thÃ¡ng 4_thÃ¡ng 5_thÃ¡ng 6_thÃ¡ng 7_thÃ¡ng 8_thÃ¡ng 9_thÃ¡ng 10_thÃ¡ng 11_thÃ¡ng 12'.split('_'),
  monthsShort : 'Th01_Th02_Th03_Th04_Th05_Th06_Th07_Th08_Th09_Th10_Th11_Th12'.split('_'),
  monthsParseExact: true,
  weekdays : 'chá»§ nháº­t_thá»© hai_thá»© ba_thá»© tÆ°_thá»© nÄm_thá»© sÃ¡u_thá»© báº£y'.split('_'),
  weekdaysShort : 'CN_T2_T3_T4_T5_T6_T7'.split('_'),
  weekdaysMin : 'CN_T2_T3_T4_T5_T6_T7'.split('_'),
  weekdaysParseExact : true,
  meridiemParse: /sa|ch/i,
  isPM(input: string): boolean {
    return /^ch$/i.test(input);
  },
  meridiem(hours: number, minutes: number, isLower: boolean): string {
    if (hours < 12) {
      return isLower ? 'sa' : 'SA';
    } else {
      return isLower ? 'ch' : 'CH';
    }
  },
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss',
    L : 'DD/MM/YYYY',
    LL : 'D MMMM [nÄm] YYYY',
    LLL : 'D MMMM [nÄm] YYYY HH:mm',
    LLLL : 'dddd, D MMMM [nÄm] YYYY HH:mm',
    l : 'DD/M/YYYY',
    ll : 'D MMM YYYY',
    lll : 'D MMM YYYY HH:mm',
    llll : 'ddd, D MMM YYYY HH:mm'
  },
  calendar : {
    sameDay: '[HÃ´m nay lÃºc] LT',
    nextDay: '[NgÃ y mai lÃºc] LT',
    nextWeek: 'dddd [tuáº§n tá»i lÃºc] LT',
    lastDay: '[HÃ´m qua lÃºc] LT',
    lastWeek: 'dddd [tuáº§n trÆ°á»c lÃºc] LT',
    sameElse: 'L'
  },
  relativeTime : {
    future : '%s tá»i',
    past : '%s trÆ°á»c',
    s : 'vÃ i giÃ¢y',
    ss : '%d giÃ¢y' ,
    m : 'má»t phÃºt',
    mm : '%d phÃºt',
    h : 'má»t giá»',
    hh : '%d giá»',
    d : 'má»t ngÃ y',
    dd : '%d ngÃ y',
    M : 'má»t thÃ¡ng',
    MM : '%d thÃ¡ng',
    y : 'má»t nÄm',
    yy : '%d nÄm'
  },
  dayOfMonthOrdinalParse: /\d{1,2}/,
  ordinal(_num: number): string {
    return '' + _num;
  },
  week : {
    dow : 1, // Thá»© Hai lÃ  ngÃ y Äáº§u tuáº§n.
    doy : 4  // Tuáº§n chá»©a ngÃ y 4 thÃ¡ng 1 lÃ  tuáº§n Äáº§u tiÃªn trong nÄm.
  }
};
