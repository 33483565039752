import { Component, AfterViewInit, } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { TranspilationService } from '../../../../services/transpilation.service';
import { ProcessService } from '../../../../services/process.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-html-content',
  templateUrl: './html-content.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsHtmlContentComponent extends FVAbstractControl implements AfterViewInit {
  private _html: string | SafeHtml;

  constructor(
    private transpilation: TranspilationService,
    private sanitizer: DomSanitizer,
    process: ProcessService,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
    if (!this.control || !this.control.content) {
      this.warn('Custom HTML without content!!');
      return;
    }
    this.transpilation.transpileHtml(this.control.content).then((html: string) => {
      this._html = this.sanitizer.bypassSecurityTrustHtml(html);
    });
  }

  get html(): SafeHtml {
    return this._html;
  }
}
