import { Control } from './control';
import { Item } from './item';
import { Model } from './model';
import { Dock } from './dock';


/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
export class ComponentConfig {
  constructor(
    public controls?: Control[],
    public docketItems?: Dock[],
    public errorMessage?: string,
    public height?: string,
    public isError?: any,
    public items?: Item[],
    public model?: Model,
    public store?: Model,
    public readonly?: boolean,
    public recordData?: Object,
    public title?: string,
    public width?: string,
    public windowMask?: any,
  ) { }
}
