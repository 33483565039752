// Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDnDModule } from '@swimlane/ngx-dnd';

/// Bootstrap
import {
  TabsModule
} from 'ngx-bootstrap/tabs';
import {
  TypeaheadModule
} from 'ngx-bootstrap/typeahead';
import {
  SortableModule
} from 'ngx-bootstrap/sortable';
import {
  PaginationModule
} from 'ngx-bootstrap/pagination';
import {
  TooltipModule
} from 'ngx-bootstrap/tooltip';
import {
  BsDatepickerModule
} from 'ngx-bootstrap/datepicker';
import {
  BsDropdownModule
} from 'ngx-bootstrap/dropdown';
import {
  AccordionModule
} from 'ngx-bootstrap/accordion';

const ngxBbootstrap = [
  NgxPaginationModule,
  TypeaheadModule.forRoot(),
  AccordionModule.forRoot(),
  BsDropdownModule.forRoot(),
  BsDatepickerModule.forRoot(),
  TooltipModule.forRoot(),
  TabsModule.forRoot(),
  PaginationModule.forRoot(),
  SortableModule.forRoot(),
];

// Services
import { ToastrService } from 'ngx-toastr';

// Components
import { GridRendererComponent } from './components/renderer/renderer.component';

// Models
import { environment } from '../../environments/environment';
import { ENVIRONMENT_NAME } from '../constants';

// Utils
import { ColumnsManagerComponent } from './components/columns-manager/columns-manager.component';
import { QueryEditorComponent } from './components/query-editor/query-editor.component';

@NgModule({
  declarations: [
    GridRendererComponent,
    ColumnsManagerComponent,
    QueryEditorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxDnDModule,
    ...ngxBbootstrap,
  ],
  providers: [
    ToastrService,
  ],
  exports: [
    GridRendererComponent,
    ColumnsManagerComponent,
    QueryEditorComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DataGridModule {
  public static forRoot(_enviroment): ModuleWithProviders<DataGridModule> {
    return {
      ngModule: DataGridModule,
      providers: [
        { provide: 'enviroment', useValue: _enviroment || environment[ENVIRONMENT_NAME] },
      ],
    };
  }
}
