import { Component, Input, EventEmitter, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'datagrid-columns-manager',
  templateUrl: './columns-manager.component.html',
  styleUrls: ['./columns-manager.component.scss'],
})
export class ColumnsManagerComponent {
  @Output() update = new EventEmitter<any>();

  @Input()
  set config(config) {
    if (JSON.stringify(config) !== JSON.stringify(this._config)) {
      this._config = config;
      this.generateDisplayed(config.displayedColumns);
    }
  }

  internUpdateEmitter = new EventEmitter<any>();

  private _config: any = {};
  private _sortings = [];

  constructor() {
    this.internUpdateEmitter.pipe(debounceTime(400)).subscribe((event) => this.update.emit(this._config));
  }

  // Empty ref to displayedColumns
  get displayedColumns() {
    return this._config.displayedColumns;
  }

  set displayedColumns(displayed) {
    this._config.displayedColumns = displayed;
  }


  get config() {
    return this._config;
  }

  get sortings() {
    return this._sortings;
  }

  set sortings(sortings) {
    this._sortings = sortings;
  }

  get columns() {
    return this._config.availableColumns || [];
  }

  get freeColumns() {
    return this.columns.filter((col) => !this.displayedColumns.find((_col) => _col.name === col.name));
  }

  get unsortedColumns() {
    return this.columns.filter((col) => !col.sort);
  }

  optionUpdated(from, event) {
    if (!event || !event.target || !event.target.value) {
      return;
    }
    const fromIndex = this.displayedColumns.findIndex((col) => col.name === from.name);
    this.displayedColumns[fromIndex].name = event.target.value;
    this.generateDisplayed();
  }

  sortingUpdated(from, event) {
    if (!event || !event.target || !event.target.value) {
      return;
    }
    const targetIndex = this.columns.findIndex((col) => col.name === event.target.value);
    this.columns[targetIndex].sort = from.sort;
    this.removeSorting(from);
  }

  updateDisplayedColumns(_columns = this.displayedColumns) {
    if (!this._config || !this._config.availableColumns) {
      return;
    }
    let columns = this.sortByOrder(_columns || []);
    columns = columns.map((col, index) => {
      col.order = index;
      return col;
    });
    this._config.displayedColumns = this.sortByOrder(columns);
    return this.displayedColumns;
  }

  freeOrder(order?: number) {
    if (!this.columns.find((col) => col.order === order)) {
      return order;
    }
    const arr = this.columns.map((col) => col.order).sort();
    for (let i = 0; i < arr.length; i++) {
      if (i !== arr[i]) {
        return i;
      }
    }
  }

  canRemove(column) {
    return !(column.fixed || column.readonly || column.important);
  }

  addNew() {
    const targetCol = this.columns.find((col) => !(this.displayedColumns || []).find((_col) => _col.name === col.name));
    if (!targetCol) {
      alert('Cannot add more. All the columns are already used.');
      return;
    }
    this.updateDisplayedColumns();
    this._config.displayedColumns.push({ name: targetCol.name });
    this.generateDisplayed();
  }

  removeColumn(dColumn) {
    const colIndex = (this.displayedColumns || []).findIndex((col) => col.name === dColumn.name);
    if (colIndex === -1) {
      return;
    }
    this._config.displayedColumns.splice(colIndex, 1);
    this.generateDisplayed();
  }

  addNewSorting() {
    const targetCol = this.columns.find((col) => !(this._sortings || []).find((_col) => _col.name === col.name));
    if (!targetCol) {
      alert('Cannot add more. All the columns are already used.');
      return;
    }
    targetCol.sort = 'asc';
    this.generateDisplayed();
  }

  removeSorting(col) {
    delete col.sort;
    this._sortings = this._sortings.filter((_col) => _col.name !== col.name);
    this.generateDisplayed();
  }

  upodateSorting(col) {
    col.sort = col.sort === 'asc' ? 'desc' : 'asc';
    this.internUpdateEmitter.emit(true);
  }

  private generateDisplayed(columns = this.displayedColumns) {
    this.updateDisplayedColumns(columns);
    columns.forEach((col, index) => {
      if (!col.order && col.order !== 0) {
        col.order = this.freeOrder(index);
      }
      columns[index] = col;
    });
    columns = this.sortByOrder(columns);
    // Do the trick to not swap them when new element appear
    const displayed = (columns || [])
      .map((col) => {
        return Object.assign({}, col, this.columns.find((_col) => _col.name === col.name));
      }).filter((col) => {
        return !col.hide;
      }).filter((col) => {
        return !this._config.displayedColumns.find((_col) => _col.name === col.name);
      });
    this._config.displayedColumns = [...this._config.displayedColumns, ...displayed];
    const sortings = (this._config.availableColumns || [])
      .filter((col) => {
        return col.sort;
      }).filter((col) => {
        return !this._sortings.find((_col) => _col.name === col.name);
      });
    this._sortings = [...this._sortings, ...sortings];
    this.internUpdateEmitter.emit(true);
  }

  private sortByOrder(columns) {
    return columns.sort((a, b) => a.order - b.order);
  }
}
