import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgs-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class DGSLoadingComponent {
  @Input() set loading(loading: boolean) {
    this._loading = loading;
  }

  get loading(): boolean {
    return this._loading;
  }

  private _loading: boolean;
  constructor() { }
}
