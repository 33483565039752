import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl } from '../../../shared/abstract-control.component';
import { ProcessService } from '../../../../services/process.service';
import { Field } from '../../../../models';

/**
 * @Author [GrigoreMe](https://github.com/grigoreme)
 */
@Component({
  selector: 'fv-controls-list-field',
  templateUrl: './list-field.component.html',
  styleUrls: ['./../controls.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FVControlsListFieldComponent extends FVAbstractControl implements AfterViewInit {
  options: Field[] = [];

  constructor(
    private ref: ChangeDetectorRef,
    process: ProcessService,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    super.AfterViewInit();
    this.fetchOptions();
  }

  /**
   * Downloads list-field options.
   */
  private fetchOptions() {
    const componentConfig = this.task.componentConfig;
    const getControlsOptions = (referenceName) => {
      const ref = this.controlRef(referenceName);
      return ref && ref.options;
    };
    const refName = this.control.referenceName;
    this.options = (this.task && componentConfig)
      && (this.control.options || getControlsOptions(refName) || [])
      || [];
    this.ref.detectChanges();
  }
}
